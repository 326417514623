/* eslint-disable @mizdra/layout-shift/require-size-attributes */
import React from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import DotCarousel from '@gcc/pip-components/dist/DotCarousel';
import { mediaImageShape } from '@gcc/pip-components/dist/Visualizer/_propTypes';
import {
  Wrapper,
  SlideContent,
  Caption,
  LiquidPixelViewerMobileContainer,
} from '@gcc/pip-components/dist/Visualizer/mobileCarouselStyles';
import Hideable from '@gcc/pip-components/dist/Hideable';

import MobileCarouselSkeleton from '@gcc/pip-components/dist/Visualizer/_MobileCarouselSkeleton';
import VisualizerLiquidPixel from '@gcc/pip-components/dist/VisualizerLiquidPixel';
import { ImageNotFoundWrapper } from './ImageNotFoundWrapper';
import { StyledGalleryImage, StyledMobileCarouselContainer } from './mobile-carousel.styles';
import VideoPlayer from './_VideoPlayer';

const MobileCarousel = ({
  mediaImages,
  videoPlayerComponent,
  onChangeSlide,
  onImageClick,
  preloadedMainImage,
  visualizerImagesLoaded,
  useSkeletons,
  onImageLoaded,
  onImageRequested,
  lpActive,
  activeColor,
  activeLogoGroup,
  activeTexture,
  isMobile,
  validTexture,
  onLpError,
  onLiquidPixelVisualizerLoadedSuccessfully,
  backupLiquidPixelImage,
}) => (
  <StyledMobileCarouselContainer>
    <Hideable isHidden={!useSkeletons || visualizerImagesLoaded}>
      <MobileCarouselSkeleton preloadedMainImage={preloadedMainImage} />
    </Hideable>
    <Hideable isHidden={useSkeletons && !visualizerImagesLoaded}>
      <DotCarousel
        onChangeSlide={onChangeSlide}
        infiniteLoop
        scrollMode="peek"
        useSkeleton={false}
      >
        {mediaImages.map((image, index) => {
          const imageName = `slide ${index}`;
          if (image.mediaType === 'IMAGE') {
            onImageRequested(imageName);
          }
          return (
            <Wrapper key={image.primaryLink}>
              <SlideContent>
                {image.mediaType === 'IMAGE' ? (
                  <StyledGalleryImage
                    src={image.primaryLink}
                    alt={image.caption || imageName}
                    role="presentation"
                    onClick={() => onImageClick(false)}
                    onLoad={onImageLoaded(imageName)}
                  />
                ) : null}
                {image.mediaType === 'VIDEO' ? (
                  <div style={{ width: '256px' }}>
                    <VideoPlayer
                      brightcoveVideoId={image.videoId}
                      videoPlayerComponent={videoPlayerComponent}
                    />
                  </div>
                ) : null}
                {lpActive
                && image.mediaType === 'LPSURROUND'
                && validTexture
                  ? (
                    <LiquidPixelViewerMobileContainer>
                      <VisualizerLiquidPixel
                        activeLogoGroup={activeLogoGroup}
                        activeTexture={activeTexture}
                        activeColor={activeColor}
                        lpActive={lpActive}
                        isMobile={isMobile}
                        displayDragMessage={false}
                        onError={() => !!onLpError && onLpError()}
                        onLiquidPixelVisualizerLoadedSuccessfully={() => {
                          if (onLiquidPixelVisualizerLoadedSuccessfully) {
                            onLiquidPixelVisualizerLoadedSuccessfully();
                          }
                        }}
                        onBackupImgClick={() => onImageClick(true)}
                        backupImage={backupLiquidPixelImage || image.primaryLink}
                      />
                    </LiquidPixelViewerMobileContainer>
                  ) : null}
                {lpActive && image.mediaType === 'LPSURROUND' && !validTexture && (
                  <ImageNotFoundWrapper
                    isMobile={isMobile}
                    style={{ width: '265px' }}
                    onClick={() => onImageClick(true)}
                  >
                    <span>Image Not Available</span>
                    <img
                      src={backupLiquidPixelImage || image.primaryLink}
                      alt={image.caption || imageName}
                      role="presentation"
                      onLoad={onImageLoaded(imageName)}
                    />
                  </ImageNotFoundWrapper>
                )}
              </SlideContent>
              {image.caption && <Caption>{image.caption}</Caption>}
            </Wrapper>
          );
        })}
      </DotCarousel>
    </Hideable>
  </StyledMobileCarouselContainer>
);

MobileCarousel.propTypes = {
  mediaImages: PropTypes.arrayOf(mediaImageShape).isRequired,
  videoPlayerComponent: PropTypes.func.isRequired,
  onChangeSlide: PropTypes.func.isRequired,
  onImageClick: PropTypes.func.isRequired,
  preloadedMainImage: PropTypes.string.isRequired,
  visualizerImagesLoaded: PropTypes.bool.isRequired,
  useSkeletons: PropTypes.bool.isRequired,
  onImageLoaded: PropTypes.func.isRequired,
  onImageRequested: PropTypes.func.isRequired,
  lpActive: PropTypes.bool,
  activeLogoGroup: PropTypes.shape({}),
  activeTexture: PropTypes.shape({
    model: PropTypes.shape({})
  }),
  activeColor: PropTypes.string,
  isMobile: PropTypes.bool,
  validTexture: PropTypes.bool,
  onLpError: PropTypes.func,
  onLiquidPixelVisualizerLoadedSuccessfully: PropTypes.func,
  backupLiquidPixelImage: PropTypes.string,
};

MobileCarousel.defaultProps = {
  activeLogoGroup: undefined,
  activeTexture: undefined,
  activeColor: undefined,
  lpActive: false,
  isMobile: false,
  validTexture: false,
  onLpError: noop,
  onLiquidPixelVisualizerLoadedSuccessfully: noop,
  backupLiquidPixelImage: undefined,
};

export default MobileCarousel;
