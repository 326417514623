import { styled, ty } from '@gcc/autobahn-components';

export default {};

export const StyledProductOverview = styled.div`
  *zoom: 1;
  &:before,
  :after {
    content: ' ';
    display: table;
  }
  &:after {
    clear: both;
  }
`;

export const StyledOverviewContent = styled.div`
  ${ty`w-100 w-55-ns dib lh-copy mb3 ph1`}
  & p {
    ${ty`lh-copy`}
  }
  & ul {
    ${ty`pl3`}
    & li {
      ${ty`pb2`}
    }
  }
`;

export const StyledSureFitArea = styled.div`
  ${ty`w-100 w-40-ns dib pa4 mt3 mt0-ns bg-light-gray fr lh-copy`}
`;

export const StyledSureFitHeader = styled.h2`
  ${ty`b dib flex center pb2`}
`;
export const StyledSureFitIcon = styled.div`
  ${ty`
    pr1 dib
  `}
  width: 3rem;
`;

export const StyledSureFitList = styled.ul`
  ${ty`pl3`}
`;
