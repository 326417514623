import { breakpoints, styled, ty } from '@gcc/autobahn-components';

export const StyledMainContainer = styled.div`
  ${ty`w-100 mw9-ns center`}
`;

export const BreadcrumbRow = styled.div`
  ${ty`pa2`}
  min-height: 4.8rem;
  font-size: var(--font-size-6);
`;

export const StyledFlyout = styled.ul`
  ${ty`list pa0 ma0 fixed right-0 z-max`}
  width: 41px;
  top: 30%;
  clear: left;
  & li {
    ${ty`nowrap pr5 dib cf fl relative`}
    clear: left;
    list-style-type: none;
    left: 0;
    transform: translateX(0%);
    transition: transform 200ms linear;
    will-change: transform;
    & a {
      ${ty`flex items-center nowrap white fw7`}
      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const StyledFlyoutCallUs = styled.li`
  ${ty`bg-orange`}
  &:hover {
    transform: translateX(-125px);
  }
`;

export const StyledFlyoutIcon = styled.div`
  ${ty`lh-solid f3 pa2 flex items-center`}
`;

export const StyledFlyoutText = styled.div`
  ${ty`pa2 f5 lh-solid`}
`;

export const StyledFlyoutMailUs = styled.li`
  ${ty`bg-near-black`}
  &:hover {
    transform: translateX(-90px);
  }
`;

export const StyledGallery = styled.div`
  ${ty`w-100 pa2`}
  ${breakpoints.s} {
    & [class*='styles__Slides'] {
      align-items: center;
      margin-bottom: -10px;
      padding-bottom: 10px;
    }
    & > div {
      min-height: 327px;
    }
  }
  ${breakpoints.l} {
    width: 57.33333%;
    & > div {
      min-height: 642px;
      position: sticky;
      top: ${({ isStickyHeaderActive }) => (isStickyHeaderActive ? '9.5rem' : '1rem')};
      transition: top 0.3s ease-in-out;
    }
    & .pip-components__Visualizer--image-main {
      max-width: 660px;
      max-height: 540px;
    }
    & [class*='styles__Remaining'] {
      font-size: 1.5rem;
      font-weight: 800;
    }
  }
`;

export const StyledProductOptions = styled.section`
  ${ty`ph2 pt2 pb3`}
`;

export const StyledProductOverview = styled.section`
  ${ty`ph2 mb3`}
`;

export const StyledProductSpecifications = styled.section`
  ${ty`ph2 mb3`}
`;

export const StyledProductReviews = styled.section`
  ${ty`ph2 mb3`}
  & [class*='StyledContentBoxContent']:after {
    ${ty`dn`}
  }
`;

export const StyledQuestionsAndAnswers = styled.section`
  ${ty`ph2 mb3`}
  & [class*='StyledContentBoxContent']:after {
    ${ty`dn`}
  }
`;

export const StyledSummary = styled.div`
  ${ty`w-100 pa2`}
  ${breakpoints.l} {
    width: 42.66666%;
  }
`;

export const StyledZoneA = styled.div`
  ${ty`flex flex-wrap flex-nowrap-l`}
`;

export const StyledMandIHeader = styled.div`
  ${ty`bg-orange flex absolute w-100 top-0 pa2 left-0`}
  -webkit-justify-content: center;
  justify-content: center;
  z-index: 1060;
`;

export const StyledLink = styled.a`
  ${ty`no-underline white fw7`}
  font-size: 11px !important;
  &:hover {
    ${ty`underline`}
  }
`;
