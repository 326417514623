import { Icon } from '@gcc/autobahn-components';
import React, { useState } from 'react';

import {
  FulfillmentRoot,
  Tile,
  BoxDescription,
  BoxIcon,
  BoxDeliveryIcon,
  BoxSuffix,
  BoxTitle,
  TileList,
  FulfillmentBox,
} from './how-to-get-it.styles';

const SHIP_TO_HOME = 'ship-to-home';
const STORE_PICKUP = 'ship-to-store';
const SCHEDULE_DELIVERY = 'schedule-delivery';

const HowToGetIt = () => {
  const [howToGet, setHowToGetIt] = useState('ship-to-home');
  const updateSelection = (selection) => setHowToGetIt(selection);

  const storePickupSelected = howToGet === STORE_PICKUP;
  const shipToHomeSelected = howToGet === SHIP_TO_HOME;
  const scheduleDeliverySelected = howToGet === SCHEDULE_DELIVERY;

  return (
    <FulfillmentRoot id="zone-a-how-to-get-it">
      <h3>How to Get It</h3>
      <TileList>
        <Tile>
          <FulfillmentBox
            enabled={false}
            isSelected={storePickupSelected}
            onClick={() => updateSelection(STORE_PICKUP)}
          >
            <BoxIcon>
              <Icon name="shipping" size="auto" color={storePickupSelected ? 'var(--orange)' : undefined} />
            </BoxIcon>
            <BoxTitle>Store Pickup</BoxTitle>
            {/* <StyledHowToGetItItemDescription>Store Name</StyledHowToGetItItemDescription> */}
            <BoxDescription>Not Available for this item</BoxDescription>
          </FulfillmentBox>
        </Tile>
        <Tile>
          <FulfillmentBox isSelected={shipToHomeSelected} onClick={() => updateSelection(SHIP_TO_HOME)}>
            <BoxIcon>
              <Icon name="shipping" size="auto" color={shipToHomeSelected ? 'var(--orange)' : undefined} />
            </BoxIcon>
            <BoxTitle>Ship to Home</BoxTitle>
            {/* <StyledHowToGetItItemDescription>Estimated Arrival: Aug 28 - Sep 1</StyledHowToGetItItemDescription> */}
            <BoxSuffix>Standard Delivery</BoxSuffix>
          </FulfillmentBox>
        </Tile>
        <Tile>
          <FulfillmentBox
            enabled={false}
            isSelected={scheduleDeliverySelected}
            onClick={() => updateSelection(SCHEDULE_DELIVERY)}
          >
            <BoxDeliveryIcon>
              <Icon name="delivery" size="auto" />
            </BoxDeliveryIcon>
            <BoxTitle>Scheduled Delivery</BoxTitle>
            <BoxDescription>Not Available for this item</BoxDescription>
          </FulfillmentBox>
        </Tile>
      </TileList>
    </FulfillmentRoot>
  );
};

export default HowToGetIt;
