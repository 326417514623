import React from 'react';
import PropTypes from 'prop-types';
import Modal from '@gcc/pip-components/dist/Modal';
import { ButtonLink } from '@gcc/pip-components/dist/Link';
import { ChevronLeft } from '@gcc/pip-components/dist/Icons';
import { BackHeader } from './styles';

// TODO: see if we want to integrate this into the standard modal (on mobile)
const BackModal = (props) => {
  const { onClose } = props;

  const backHeader = (
    <BackHeader>
      <ButtonLink onClick={onClose}>
        <ChevronLeft color="#ccc" />
        Back
      </ButtonLink>
    </BackHeader>
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Modal withPadding={false} headerChildren={backHeader} {...props} />
  );
};

BackModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default BackModal;
