import React from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-stickynode';
import Visualizer from './nonsticky';

class StickyVisualizer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      loaded: false,
    };
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps !== snapshot) {
      const { getVisualizerImageUrl, configuratorReady } = this.props;
      const { loaded } = this.state;
      if (!loaded && !configuratorReady) {
        Promise.resolve(getVisualizerImageUrl()).then(() => {
          this.setState({ loaded: true });
        });
      }
    }
    return null;
  }

  showModal() {
    this.setState({ showModal: true });
  }

  closeModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { mediaImages } = this.props;
    if (!mediaImages.length) {
      return null;
    }

    const {
      // sticky
      bottomBoundary,
      isSticky,
      // discard
      configuratorReady,
      getVisualizerImageUrl,
      // visualizer
      ...rest
    } = this.props;

    const { showModal } = this.state;

    return (
      <Sticky
        bottomBoundary={bottomBoundary && bottomBoundary !== '' ? bottomBoundary : 0}
        enabled={isSticky && !showModal}
      >
        <Visualizer
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          onOpenModal={this.showModal}
          onCloseModal={this.closeModal}
        />
      </Sticky>
    );
  }
}

StickyVisualizer.propTypes = {
  getVisualizerImageUrl: PropTypes.func.isRequired,
  mediaImages: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    thumbnail: PropTypes.string,
    primaryLink: PropTypes.string,
    mediaType: PropTypes.string,
  })),
  productInfo: PropTypes.shape({
    brand: PropTypes.string,
    siteProductName: PropTypes.string,
  }),
  configuratorReady: PropTypes.bool.isRequired,
  isSticky: PropTypes.bool,
  bottomBoundary: PropTypes.string,
  videoPlayerComponent: PropTypes.func.isRequired,
};

StickyVisualizer.defaultProps = {
  productInfo: {},
  mediaImages: [],
  isSticky: false,
  bottomBoundary: '',
};

export default StickyVisualizer;
