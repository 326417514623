import { Button, useBreakpoint } from '@gcc/autobahn-components';
import { arrayOf, number, bool } from 'prop-types';
import React from 'react';
import { useHeaderObserver } from '@thd-olt-functional/utils';

import { StyledFreeSamplesAmount, StyledFreeSamplesSection } from './free-samples.styles';

const FreeSamples = ({ desktopView, sampleIdsInCart }) => {
  const { isLarge } = useBreakpoint();

  const [entry] = useHeaderObserver();

  return (

    <StyledFreeSamplesSection
      className=""
      entry={entry?.navigation?.positionBottom && entry?.visible ? entry?.navigation.clientRect?.height : 0}
      desktopView={desktopView}
    >
      <div className="free-samples-description">
        <StyledFreeSamplesAmount data-id="free-samples-count" className="free-samples-count">
          {sampleIdsInCart.length}
        </StyledFreeSamplesAmount>
        of
        <span className="free-samples-count"> 10 </span>
        <p>{isLarge ? 'free samples in cart' : 'Free Samples'}</p>
      </div>
      <div className="free-samples-button">
        <Button
          color="secondary"
          element="a"
          type="button"
          href="/custom-blinds/sample-cart"
          label={isLarge ? 'Go to Samples Cart' : 'Samples Cart'}
          onClick={() => {
            window.location = '/custom-blinds/sample-cart';
          }}
        />
      </div>
      <div className="free-samples-title">{isLarge ? 'Free Samples / Free Shipping Included' : ''}</div>
      <div className="free-samples-ship-warning">
        If more than 10 samples are in your cart, they will need to be removed to check out.
      </div>
    </StyledFreeSamplesSection>
  );
};

FreeSamples.propTypes = {
  desktopView: bool,
  sampleIdsInCart: arrayOf(number),
};

FreeSamples.defaultProps = {
  desktopView: false,
  sampleIdsInCart: [],
};

export default FreeSamples;
