import { publish } from '@gcc/autobahn-utilities';
import { action, computed, makeObservable, observable } from 'mobx';
import thdCustomer from '@thd-olt-global/thd-customer';

class ProductSummaryViewModel {
  constructor(rootVm) {
    makeObservable(this, {
      dimensionsOptions: computed,
      rootVm: false,
      getIsB2B: action,
      scrollToProductOverview: action,
      setStickyHeaderLoaded: action,
      showGuaranteedToFit: action,
      stickyHeaderLoaded: observable,
      throttledStickyHeader: observable,
    });
    this.rootVm = rootVm;
  }

  stickyHeaderLoaded = true;

  getIsB2B = () => {
    return !!(thdCustomer?.isB2BCustomer || thdCustomer?.isB2XCustomer);
  };

  scrollToProductOverview = (event) => {
    event.preventDefault();
    const element = this.rootVm.productOverviewRef.current;
    if (element) {
      const checkbox = element.querySelector('input[type="checkbox"]');
      if (checkbox) checkbox.checked = true;
      element.scrollIntoView({ behavior: 'smooth' });
      window.setTimeout(() => publish('product-overview:scrollto'), 1000);
    }
  };

  setStickyHeaderLoaded(stickyHeaderLoaded) {
    this.stickyHeaderLoaded = stickyHeaderLoaded;
  }

  showGuaranteedToFit = (event) => {
    event.preventDefault();
    const productOverviewElement = this.rootVm.productOverviewRef.current;
    const checkbox = productOverviewElement.querySelector('input[type="checkbox"]');
    if (checkbox) checkbox.checked = true;
    productOverviewElement.scrollIntoView({ behavior: 'smooth' });
    window.setTimeout(() => publish('product-overview:scrollto', { guaranteedToFit: true }), 1000);
  };

  throttledStickyHeader = ({ element }) => {
    this.element = element;
    this.loadStickyHeader();
  };

  loadStickyHeader = () => {
    const viewportOffset = this.element.getBoundingClientRect();
    if (viewportOffset.top <= 0 && !this.stickyHeaderLoaded) {
      this.setStickyHeaderLoaded(true);
    }
  };

  get dimensionsOptions() {
    const eighthHeightList = ProductSummaryViewModel.getEighthList();
    const eighthWidthList = ProductSummaryViewModel.getEighthList();
    const minWidth = +this.rootVm.product?.Dimensions?.MinWidth || 8;
    const maxWidth = +this.rootVm.product?.Dimensions?.MaxWidth || 120;
    const minHeight = +this.rootVm.product?.Dimensions?.MinHeight || 8;
    const maxHeight = +this.rootVm.product?.Dimensions?.MaxHeight || 120;
    const widthWholes = ProductSummaryViewModel.getWholeNumbers(minWidth, maxWidth);
    const heightWholes = ProductSummaryViewModel.getWholeNumbers(minHeight, maxHeight);
    const eighthsWidth = ProductSummaryViewModel.getEighthsList(minWidth, maxWidth, eighthWidthList);
    const eighthsHeight = ProductSummaryViewModel.getEighthsList(minHeight, maxHeight, eighthHeightList);
    return {
      height: {
        eighths: eighthsHeight,
        wholes: heightWholes,
      },
      width: {
        eighths: eighthsWidth,
        wholes: widthWholes,
      },
    };
  }

  static getEighthList() {
    return [
      { text: '0/0', value: '0' },
      { text: '1/8', value: '0.125' },
      { text: '1/4', value: '0.25' },
      { text: '3/8', value: '0.375' },
      { text: '1/2', value: '0.5' },
      { text: '5/8', value: '0.625' },
      { text: '3/4', value: '0.75' },
      { text: '7/8', value: '0.875' },
    ];
  }

  static getWholeNumbers(min, max) {
    const wholeNumbers = [];
    for (let i = Math.floor(min); i <= max; i += 1) {
      wholeNumbers.push({
        text: `${i}`,
        value: `${i}`,
      });
    }
    return wholeNumbers;
  }

  static getEighthsList(min, max, eighthList) {
    if (min === max) {
      const eighth = min - Math.floor(min);
      return eighthList.filter((x) => x.value === eighth.toString());
    }
    return eighthList;
  }

  rootVm;
}

export default ProductSummaryViewModel;
