/* eslint-disable @mizdra/layout-shift/require-size-attributes */
/* eslint-disable max-lines */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { useIsMobile, useTheme } from '@gcc/pip-components/dist/hooks';
import { GridItem } from '@gcc/pip-components/dist/grids';
import { Para } from '@gcc/pip-components/dist/typography';
import ModalWithHeader from '@gcc/pip-components/dist/ModalWithHeader';
import Desktop from '@gcc/pip-components/dist/Desktop';
import Thumbnail from '@gcc/pip-components/dist/Visualizer/_Thumbnail';
// import { ImageNotFoundWrapper } from '@gcc/pip-components/dist/VisualizerLiquidPixel/index.styles';
import MainImage from '@gcc/pip-components/dist/Visualizer/_MainImage';
import { mediaImageShape, productInfoShape } from '@gcc/pip-components/dist/Visualizer/_propTypes';
import VisualizerLiquidPixel from '@gcc/pip-components/dist/VisualizerLiquidPixel';
import { ImageNotFoundWrapper } from '../ImageNotFoundWrapper';
import {
  Content,
  ClassicSubheader,
  MainContent,
  ThumbnailGrid,
  ProductInfo,
  ProductBrand,
  ProductTitle,
  MainImageWrapper,
  SliderImage,
  CarouselStyle,
  Caption,
} from './styles';
import VideoPlayer from '../_VideoPlayer';
import BackModal from './_BackModal';

const VisualizerModal = ({
  onClose,
  productInfo: { brand, siteProductName },
  mediaImages,
  activeIndex: initialActiveIndex,
  videoPlayerComponent,
  responsiveImageHelper,
  lpActive,
  activeColor,
  activeLogoGroup,
  activeTexture,
  validTexture,
  onVisualizerAnalyticsTrack,
  onError,
  onLiquidPixelVisualizerLoadedSuccessfully,
  backupLiquidPixelImage,
}) => {
  const [activeIndex, setActiveIndex] = useState(initialActiveIndex);
  const isMobile = useIsMobile();
  const theme = useTheme();

  const childRef = useRef();
  const mainImage = mediaImages[activeIndex];
  const videoWidth = window.innerWidth - 25;

  useEffect(() => {
    if (isMobile)childRef.current.scrollToIndex(activeIndex);
    onVisualizerAnalyticsTrack(mediaImages, activeIndex);
  }, [activeIndex]);

  const handleActiveIndexChange = (index) => {
    setActiveIndex(index);
  };

  const getMainImageContent = () => {
    if (isMobile) {
      return (
        <GridItem md={8}>
          <MainImageWrapper>
            <CarouselStyle ref={childRef} onIndexChange={handleActiveIndexChange} oneItemPerSlide>
              {mediaImages.map((image1, index) => {
                if (lpActive && image1.mediaType === 'LPSURROUND' && validTexture) {
                  return (
                    <VisualizerLiquidPixel
                      activeLogoGroup={activeLogoGroup}
                      activeTexture={activeTexture}
                      activeColor={activeColor}
                      lpActive={lpActive}
                      isMobile={isMobile}
                      lpClassName="backModalVisualizer"
                      onError={() => !!onError && onError()}
                      onLiquidPixelVisualizerLoadedSuccessfully={() => {
                        if (onLiquidPixelVisualizerLoadedSuccessfully) {
                          onLiquidPixelVisualizerLoadedSuccessfully();
                        }
                      }}
                      backupImage={backupLiquidPixelImage || image1.primaryLink}
                    />
                  );
                }
                if (lpActive && image1.mediaType === 'LPSURROUND' && !validTexture) {
                  const { srcSet, sizes } = responsiveImageHelper
                    ? responsiveImageHelper.desktop(image1.primaryLink)
                    : {};
                  const imageName = `slide ${index}`;
                  return (
                    <ImageNotFoundWrapper
                      isMobile={isMobile}
                    >
                      <span>Image Not Available</span>
                      <img
                        src={backupLiquidPixelImage || image1.primaryLink}
                        srcSet={srcSet}
                        sizes={sizes}
                        alt={image1.caption || imageName}
                        role="presentation"
                      />
                    </ImageNotFoundWrapper>
                  );
                }
                if (image1.mediaType === 'IMAGE') {
                  const { srcSet, sizes } = responsiveImageHelper
                    ? responsiveImageHelper.desktop(image1.primaryLink)
                    : {};
                  const imageName = `slide ${index}`;
                  return (
                    <SliderImage
                      key={image1.primaryLink}
                      src={image1.primaryLink}
                      srcSet={srcSet}
                      sizes={sizes}
                      alt={image1.caption || imageName}
                    />
                  );
                }

                return (
                  <div style={{ width: '368px', paddingTop: '40px' }}>
                    <VideoPlayer
                      videoPlayerComponent={videoPlayerComponent}
                      width={videoWidth}
                      brightcoveVideoId={image1.videoId}
                    />
                  </div>
                );
              })}
            </CarouselStyle>
            {mainImage.caption && (
              <Caption>
                {mainImage.caption}
              </Caption>
            )}
          </MainImageWrapper>
        </GridItem>
      );
    }

    if (mainImage.mediaType === 'IMAGE') {
      const { srcSet, sizes } = responsiveImageHelper
        ? responsiveImageHelper.desktop(mainImage.primaryLink)
        : {};

      return (
        <GridItem md={8}>
          <MainImageWrapper>
            <MainImage
              src={mainImage.primaryLink}
              srcSet={srcSet}
              sizes={sizes}
              isMobile={isMobile}
              enlargedImageSize="75%"
              largeSize={mainImage.largeSize}
              magnifyOnHover
              isHidden={false}
            />
            {mainImage.caption && (
              <Caption>
                {mainImage.caption}
              </Caption>
            )}
          </MainImageWrapper>
        </GridItem>
      );
    }

    if (lpActive && mainImage?.mediaType === 'LPSURROUND' && validTexture) {
      return (
        <GridItem md={8}>
          <MainImageWrapper>
            <VisualizerLiquidPixel
              activeLogoGroup={activeLogoGroup}
              activeTexture={activeTexture}
              activeColor={activeColor}
              lpActive={lpActive}
              isMobile={isMobile}
              lpClassName="desktopModalVisualizer"
              onError={() => !!onError && onError()}
              onLiquidPixelVisualizerLoadedSuccessfully={() => {
                if (onLiquidPixelVisualizerLoadedSuccessfully) {
                  onLiquidPixelVisualizerLoadedSuccessfully();
                }
              }}
              backupImage={backupLiquidPixelImage || mainImage.primaryLink}
            />

          </MainImageWrapper>
        </GridItem>
      );
    }

    if (lpActive && mainImage?.mediaType === 'LPSURROUND' && !validTexture) {
      const { srcSet, sizes } = responsiveImageHelper
        ? responsiveImageHelper.desktop(mainImage.primaryLink)
        : {};
      return (
        <GridItem md={8}>
          <MainImageWrapper>
            <ImageNotFoundWrapper
              isMobile={isMobile}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <span>Image Not Available</span>
              <img
                src={backupLiquidPixelImage || mainImage.primaryLink}
                srcSet={srcSet}
                sizes={sizes}
                alt={mainImage.caption || ''}
                role="presentation"
              />
            </ImageNotFoundWrapper>
          </MainImageWrapper>
        </GridItem>
      );
    }

    return (
      <>
        <GridItem md={8}>
          <VideoPlayer
            src={mainImage.primaryLink}
            brightcoveVideoId={mainImage.videoId}
            videoPlayerComponent={videoPlayerComponent}
          />
        </GridItem>
      </>
    );
  };

  const mainImageContent = getMainImageContent();

  const productInfo = (
    <Desktop>
      <ProductInfo>
        <ProductTitle>{siteProductName}</ProductTitle>
        <ProductBrand>{brand}</ProductBrand>
      </ProductInfo>
    </Desktop>
  );

  const Modal = isMobile
    ? BackModal
    : ModalWithHeader;

  return (
    <Modal size="xl" onClose={onClose}>
      <Content>
        {
          theme?.scheme !== 'nucleus'
          && !isMobile
          && (
            <ClassicSubheader>
              {productInfo}
            </ClassicSubheader>
          )
        }
        <MainContent>
          {mainImageContent}
          <GridItem md={4} style={{ overflowY: 'auto', height: '80vh' }}>
            {theme?.scheme === 'nucleus' && productInfo}
            <Para>
              Product Images
            </Para>
            <ThumbnailGrid>
              {
                mediaImages.map((image, index) => {
                  const { srcSet, sizes } = responsiveImageHelper
                    ? responsiveImageHelper.thumbnail(image.thumbnail)
                    : {};

                  return (
                    <Thumbnail
                      key={image.thumbnail}
                      src={image.thumbnail}
                      srcSet={srcSet}
                      sizes={sizes}
                      caption={image.caption}
                      mediaType={image.mediaType}
                      selected={index === activeIndex}
                      onClick={() => { setActiveIndex(index); }}
                    />
                  );
                })
              }
            </ThumbnailGrid>
          </GridItem>
        </MainContent>
      </Content>
    </Modal>
  );
};

VisualizerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  activeIndex: PropTypes.number.isRequired,
  mediaImages: PropTypes.arrayOf(mediaImageShape).isRequired,
  productInfo: PropTypes.shape(productInfoShape).isRequired,
  videoPlayerComponent: PropTypes.func.isRequired,
  responsiveImageHelper: PropTypes.shape({
    thumbnail: PropTypes.func,
    mobile: PropTypes.func,
    desktop: PropTypes.func,
  }),
  lpActive: PropTypes.bool,
  activeLogoGroup: PropTypes.shape({}),
  activeTexture: PropTypes.shape({
    model: PropTypes.shape({})
  }),
  activeColor: PropTypes.string,
  validTexture: PropTypes.bool,
  onVisualizerAnalyticsTrack: PropTypes.func,
  onError: PropTypes.func,
  onLiquidPixelVisualizerLoadedSuccessfully: PropTypes.func,
  backupLiquidPixelImage: PropTypes.string,
};

VisualizerModal.defaultProps = {
  responsiveImageHelper: undefined,
  activeLogoGroup: undefined,
  activeTexture: undefined,
  activeColor: undefined,
  lpActive: false,
  validTexture: false,
  onVisualizerAnalyticsTrack: noop,
  onError: noop,
  onLiquidPixelVisualizerLoadedSuccessfully: noop,
  backupLiquidPixelImage: undefined,
};

export default VisualizerModal;
