import React from 'react';
import PropTypes from 'prop-types';

const VideoPlayer = ({
  videoPlayerComponent: VideoPlayerComponent,
  brightcoveVideoId
}) => {
  return (<VideoPlayerComponent videoId={brightcoveVideoId} />);
};

VideoPlayer.propTypes = {
  videoPlayerComponent: PropTypes.func.isRequired,
  brightcoveVideoId: PropTypes.string.isRequired,
};

export default VideoPlayer;
