import React, { useContext } from 'react';
import { shape } from 'prop-types';
import {
  Accordion, AccordionBody, AccordionTitle, Button, Col, Row, Placeholder
} from '@thd-olt-component-react/core-ui';
import { AskAQuestion } from '../AskAQuestion/AskAQuestion.component';
import { Pager } from '../Pager/Pager.component';
import { PagerDisplayText } from '../PagerDisplayText/PagerDisplayText.component';
import { Sort } from '../Filter';
import { Header } from './component/Header';
import { QuestionContainer } from './component/QuestionContainer';
import { AnswerContainer } from './component/AnswerContainer';
import { triggerAnalytics } from '../../Analytics';
import { useQAResult } from './useQAResult';
import { useSEO } from './useSEO';
import QAContext from '../../context/QAContext';
import './QAResult.scss';

const QUESTION_DISPLAY_MOBILE = 8;

export const QAResultMobile = ({ qaResult }) => {
  let{
    TotalResults, Results, searchText, qaLoading, dispatch, seoPageNumber
  } = useContext(QAContext);

  const {
    seoResults, seoAnswers, seoTotalResults, seoPrevPage, seoNextPage
  } = useSEO(qaResult);
  if (seoPageNumber && seoResults) {
    Results = seoResults;
  }

  const isSeoPage = !!(seoPageNumber && qaResult);

  if (qaLoading) {
    return (
      <div data-component="QAResultMobilePlaceholder">
        <Placeholder
          type="rect"
          height="125px"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
      </div>
    );
  }

  const analyticsReadQuestion = () => {
    const payload = { component: 'read question' };
    triggerAnalytics(payload);
  };

  const handleClearSearchPill = () => {
    dispatch({ action: 'SET_SEARCH_TEXT', value: '' });
  };

  return (
    <div className="qa-result" data-component="QAResultMobile">
      <Row>
        <Col nopadding>
          <Header showTitleWithTotalQns={false} seoData={seoTotalResults} />
        </Col>

        {!qaResult && (
          <Col className="qa-result__title">
            <PagerDisplayText topPager loading={qaLoading} />
            {searchText && (
              <div className="mobile-search-pill-container">
                <Button className="search-pill" onClick={handleClearSearchPill} filter>
                  {Results?.length ? searchText : 'Clear search term'}
                </Button>
              </div>
            )}
            <Sort />
          </Col>
        )}

        <Col className="qa-result__questions" nopadding>
          {Results?.length > 0
            && Results.map((result) => {
              const { Id } = result;
              return (
                <Accordion
                  icon={{ size: 'sm' }}
                  iconPosition="right"
                  key={Id}
                  onChange={analyticsReadQuestion}
                  expanded={isSeoPage}
                >
                  <AccordionTitle>
                    <QuestionContainer result={result} />
                  </AccordionTitle>
                  <AccordionBody className="accordion-body-answer">
                    <AnswerContainer result={result} seoData={seoAnswers} />
                  </AccordionBody>
                </Accordion>
              );
            })}
        </Col>
      </Row>
      {!qaResult && (
        <Row>
          <Col fallback="12" className="pager-section pager-section--mobile">
            {TotalResults > QUESTION_DISPLAY_MOBILE && (
              <div className="q-a__review-pager">
                <Pager />
              </div>
            )}
            <Row className="q-a__review-pager__summary">
              <PagerDisplayText topPager={false} loading={qaLoading} />
            </Row>
            <div className="qa-result__ask-button--mobile">
              <AskAQuestion />
            </div>
          </Col>
        </Row>
      )}
      {qaResult && (
        <Row>
          <Col>
            {seoPrevPage > -1 && (<a href={`${seoPrevPage}`}>{`page ${seoPrevPage}`}</a>)}
            {' '}
            {seoNextPage > -1 && (<a href={`${seoNextPage}`}>{`page ${seoNextPage}`}</a>)}
          </Col>
        </Row>
      )}

    </div>
  );
};

QAResultMobile.displayName = 'QAResultMobile';

QAResultMobile.propTypes = {
  qaResult: shape({})
};

QAResultMobile.defaultProps = {
  qaResult: null
};
