/* eslint-disable no-console */
import { publish, sizeJoin, sizeSplit } from '@gcc/autobahn-utilities';
import Cookies from 'js-cookie';
import {
  action, computed, makeObservable, observable, runInAction
} from 'mobx';

import ProductService from '../../api/product.service';
import SampleCartService from '../../api/sample-cart.service';
import getPostMeasureCookie from '../../utils/post-measure-options';
import { getHeightCookie, getWidthCookie } from '../../utils/size-cookie';
import ProductOptionsViewModel from '../product-options/product-options.view-model';
import ProductPreviewViewModel from '../product-preview/product-preview.view-model';
import ProductPriceViewModel from '../product-price/product-price.view-model';
import ProductReviewsViewModel from '../product-reviews/product-reviews.view-model';
import ProductSummaryViewModel from '../product-summary/product-summary.view-model';
import QuestionsAndAnswersViewModel from '../questions-and-answers/questions-and-answers.view-model';
import ThdPipModel from './thd-pip.model';
import { ddoInit, setProductETA } from '../../../../utility/analytics';
import { getMandiQueryParams, getWidthHeightParams } from '../../../../utility/paramHelper';

class ThdPipViewModel extends ThdPipModel {
  bazaarVoiceApiUrl = undefined;

  bazaarVoiceConversationsApiUrl = undefined;

  bazaarVoicePasskey = undefined;

  breakpoint = undefined;

  configuratorUrl = undefined;

  enhancedVisualization = undefined;

  isExtraLarge = undefined;

  isLarge = undefined;

  isMedium = undefined;

  isNotSmall = undefined;

  isSmall = undefined;

  height = undefined;

  mandIPhoneNumber = undefined;

  pageMode = undefined;

  postMeasureOptions = undefined;

  productPriceViewModel = undefined;

  productOptionsViewModel = undefined;

  productReviewsViewModel = undefined;

  productPreviewViewModel = undefined;

  productPreviewEventName = 'product-preview';

  questionsAndAnswersViewModel = undefined;

  productOptionsRef = undefined;

  productOverviewRef = undefined;

  productSpecificationsRef = undefined;

  sampleIdsInCart = [];

  shouldShowSamplesModal = false;

  setShouldShowSamplesModal = (state) => {
    this.shouldShowSamplesModal = state;
  };

  width = undefined;

  windowWidth = undefined;

  windowHeight = undefined;

  constructor(
    micro,
    {
      bazaarVoiceApiUrl,
      bazaarVoiceConversationsApiUrl,
      bazaarVoicePasskey,
      configuratorUrl,
      contactNumber,
      enhancedVisualization,
      isProfessionallyInstallable,
      isSamplingEnabled,
      isSamplingEnabledCecOnly,
      mandIPhoneNumber,
      pageMode,
      partnerProductId,
      productId,
      stickyHeaderId,
    }
  ) {
    super(micro);
    makeObservable(this, {
      breadcrumbs: computed,
      breakpoint: observable,
      ctaButtonLabel: computed,
      dimensions: computed,
      enhancedVisualization: observable,
      galleryImages: computed,
      getProduct: action.bound,
      height: observable,
      isExtraLarge: observable,
      isLarge: observable,
      isMedium: observable,
      isMandI: computed,
      isNotSmall: observable,
      isSmall: observable,
      mandIPhoneNumber: observable,
      product: computed,
      productOptionsViewModel: observable,
      productPriceViewModel: observable,
      productReviewsViewModel: observable,
      postMeasureOptions: observable,
      questionsAndAnswersViewModel: observable,
      sampleIdsInCart: observable,
      scrollToCustomerReviews: action,
      scrollToQuestionsAndAnswers: action,
      scrollToProductOptions: action,
      shouldShowSamplesModal: observable,
      setBreakpoint: action,
      setShouldShowSamplesModal: action,
      updateDimensions: action.bound,
      width: observable,
      windowWidth: observable,
      windowHeight: observable,
    });

    this.contactNumber = contactNumber || '800-921-2119';
    this.product = {
      PartnerProductId: partnerProductId,
      ProductId: productId,
    };
    this.isProfessionallyInstallable = isProfessionallyInstallable;
    this.isSamplingEnabled = isSamplingEnabled;
    this.isSamplingEnabledCecOnly = isSamplingEnabledCecOnly;
    this.mandIPhoneNumber = mandIPhoneNumber;
    this.sampleIdsInCart = [];
    this.stickyHeaderId = stickyHeaderId;
    this.bazaarVoiceApiUrl = bazaarVoiceApiUrl;
    this.bazaarVoiceConversationsApiUrl = bazaarVoiceConversationsApiUrl;
    this.bazaarVoicePasskey = bazaarVoicePasskey;
    this.configuratorUrl = configuratorUrl + getMandiQueryParams() + getWidthHeightParams();
    this.pageMode = pageMode;
    this.enhancedVisualization = enhancedVisualization;

    this.productOptionsViewModel = new ProductOptionsViewModel(this);
    this.productPreviewViewModel = new ProductPreviewViewModel(this);
    this.productPriceViewModel = new ProductPriceViewModel(this);
    this.productReviewsViewModel = new ProductReviewsViewModel(this);
    this.productSummaryViewModel = new ProductSummaryViewModel(this);
    this.questionsAndAnswersViewModel = new QuestionsAndAnswersViewModel(this);

    this.width = getWidthCookie();
    this.height = getHeightCookie();
    this.getPostMeasureDataCookie();
    this.getWidthHeightCookie();
    this.getProduct();
    this.getSampleCart();
  }

  async getPostMeasureDataCookie() {
    const res = await getPostMeasureCookie();
    this.postMeasureOptions = await res;
    if (this.isMandI) {
      this.width = sizeSplit(this.postMeasureOptions.Width);
      this.height = sizeSplit(this.postMeasureOptions.Height);
    }
  }

  async getWidthHeightCookie() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('width') && urlParams.get('height')) {
      this.width = urlParams.get('width');
      this.height = urlParams.get('height');
    }
  }

  setDimensionsDropdownFromQueryParam() {
    if (typeof window !== 'undefined') {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;

      const urlParams = new URLSearchParams(window.location.search);
      const width = urlParams.get('width');
      const height = urlParams.get('height');
      if (width) {
        let { wholes, eighths } = sizeSplit(width);
        if (wholes > this.product.Dimensions.MaxWidth) {
          wholes = this.product.Dimensions.MaxWidth;
          eighths = 0;
        } else if (wholes < this.product.Dimensions.MinWidth) {
          wholes = this.product.Dimensions.MinWidth;
          eighths = 0;
        }
        this.width = {
          eighths,
          wholes,
        };
      }
      if (height) {
        let { wholes, eighths } = sizeSplit(height);
        if (wholes > this.product.Dimensions.MaxHeight) {
          wholes = this.product.Dimensions.MaxHeight;
          eighths = 0;
        } else if (wholes < this.product.Dimensions.MinHeight) {
          wholes = this.product.Dimensions.MinHeight;
          eighths = 0;
        }
        this.height = {
          eighths,
          wholes,
        };
      }
    }
  }

  get breadcrumbs() {
    const breadcrumbsList = [];
    if (this.product.breadcrumbs) {
      this.product.breadcrumbs.forEach((breadcrumb) => breadcrumbsList.push({
        name: breadcrumb.Text,
        href: breadcrumb.URL,
      })
      );
    }
    return breadcrumbsList;
  }

  updateDimensions(event) {
    const { target } = event;
    let heightEighths = this.height.eighths;
    let heightWholes = this.height.wholes;
    let widthEighths = this.width.eighths;
    let widthWholes = this.width.wholes;
    switch (target.id) {
    case 'heightEighths':
      heightEighths = +target.value;
      break;
    case 'heightWholes':
      heightWholes = +target.value;
      break;
    case 'widthEighths':
      widthEighths = +target.value;
      break;
    case 'widthWholes':
      widthWholes = +target.value;
      break;
    default:
      break;
    }
    this.width = {
      eighths: widthEighths,
      wholes: widthWholes,
    };
    this.height = {
      eighths: heightEighths,
      wholes: heightWholes,
    };
    const formattedHeight = sizeJoin(this.height);
    const formattedWidth = sizeJoin(this.width);
    Cookies.set('widthz', `${formattedWidth}`);
    Cookies.set('hghtz', `${formattedHeight}`);
    const params = new URLSearchParams(window.location.search);
    if (params.has('width') && params.has('height')) {
      params.delete('width');
      params.delete('height');
      params.append('width', formattedWidth);
      params.append('height', formattedHeight);
      window.history.replaceState(null, null, '?' + params.toString());
    }
    const tempConfiguratorUrl = new URL(this.configuratorUrl);
    const searchParams = new URLSearchParams(tempConfiguratorUrl.search);
    if (searchParams.has('width') && searchParams.has('height')) {
      const newSearchParams = new URLSearchParams([...searchParams].map(([key, value]) => {
        if (key === 'width') {
          return ['width', formattedWidth];
        }
        if (key === 'height') {
          return ['height', formattedHeight];
        }
        return [key, value];
      }));
      this.configuratorUrl = tempConfiguratorUrl.origin + tempConfiguratorUrl.pathname
        + '?' + newSearchParams.toString();
    }
    this.publishOptionsToVisualizer();
  }

  get ctaButtonLabel() {
    return this.isMandI ? 'Continue' : 'Customize and Buy';
  }

  get dimensions() {
    return {
      float: {
        height: parseFloat(`${this.height.wholes}.${this.height.eighths}`),
        width: parseFloat(`${this.width.wholes}.${this.width.eighths}`),
      },
      obj: {
        height: this.height,
        width: this.width,
      },
    };
  }

  get isMandI() {
    return this.pageMode === 'MandI' && !!this.postMeasureOptions;
  }

  get galleryImages() {
    const mediaAssets = [];
    const defaultImageParams = '?quality=90&mode=scale&anchor=middlecenter';
    const primaryParams = this.isSmall ? '&maxwidth=265&maxheight=265' : '&maxheight=1000';
    const thumbnailParams = '&height=100';
    if (this.product.GalleryImages) {
      this.product.GalleryImages.forEach((image) => mediaAssets.push({
        mediaType: 'IMAGE',
        primaryLink: `${this.baseUrl}${image.ImageUrl}${defaultImageParams}${primaryParams}`,
        thumbnail: `${this.baseUrl}${image.ImageUrl}${defaultImageParams}${thumbnailParams}`,
      })
      );
    }
    return mediaAssets;
  }

  get product() {
    return this.micro.rootStore.product;
  }

  set product(val) {
    this.micro.rootStore.product = val;
  }

  getProduct() {
    ProductService.getProduct()
      .then((res) => {
        runInAction(() => {
          this.product = res.data;
          window.document.title = `${this.product.Brand} | ${this.product.ProductName}`;
          this.setDimensionsDropdownFromQueryParam();
          this.productPriceViewModel.getProductPrice();
          const {
            pageModel: { siteId = 11 },
          } = window.pageContextJavascript;
          const localVisModel = JSON.parse(
            window.localStorage.getItem(this.productPreviewEventName)
          );
          const data = {
            ...localVisModel,
            configCTALabel: this.ctaButtonLabel,
            siteId,
            isMandi: this.isMandI,
            productId: this.product.ProductId,
            productName: this.product.ProductName,
            samplesInCart: this.sampleIdsInCart.length,
            configuratorUrl: this.configuratorUrl,
          };
          if (this.product.ETA) {
            setProductETA(this.product.ETA.StartDate, this.product.ETA.EndDate);
          }
          ddoInit();

          window.localStorage.setItem(this.productPreviewEventName, JSON.stringify(data));
          publish(this.productPreviewEventName, data);
          this.publishOptionsToVisualizer();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getSampleCart({ showSamplesModal = false } = {}) {
    SampleCartService.getSampleCart()
      .then((response) => {
        runInAction(() => {
          const sampleChoiceIds = response.data.Data.map((sample) => sample.ChoiceId);
          this.sampleIdsInCart = sampleChoiceIds;
          this.shouldShowSamplesModal = showSamplesModal;
          this.productOptionsViewModel.setColorSwatchesSections([]);
          publish(this.productPreviewEventName, { samplesInCart: this.sampleIdsInCart.length });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  setBreakpoint(newBreakpoint) {
    this.breakpoint = newBreakpoint;
    this.isExtraLarge = newBreakpoint === 'extra-large';
    this.isLarge = ['large', 'extra-large'].includes(newBreakpoint);
    this.isMedium = ['medium'].includes(newBreakpoint);
    this.isNotSmall = ['not-small', 'medium', 'large', 'extra-large'].includes(newBreakpoint);
    this.isSmall = newBreakpoint === 'small';
  }

  scrollToQuestionsAndAnswers = (event) => {
    event.preventDefault();
    const element = this.questionsAndAnswersRef.current;
    let yOffset = 0;
    if (this.isNotSmall) {
      this.questionsAndAnswersViewModel.expandQuestionsAndAnswers();
      yOffset = -150;
    }
    const top = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.setTimeout(() => window.scrollTo({ top, behavior: 'smooth' }), 100);
  };

  scrollToCustomerReviews = (event) => {
    event.preventDefault();
    const element = this.customerReviewsRef.current;
    let yOffset = 0;
    if (this.isNotSmall) {
      this.productReviewsViewModel.expandReviews();
      yOffset = -150;
    }
    const top = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.setTimeout(() => window.scrollTo({ top, behavior: 'smooth' }), 100);
  };

  scrollToProductOptions = (event) => {
    event.preventDefault();
    const element = this.productOptionsRef.current;
    let yOffset = 0;
    if (this.isNotSmall) {
      yOffset = -150;
    }
    const top = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.setTimeout(() => window.scrollTo({ top, behavior: 'smooth' }), 100);
  };

  publishOptionsToVisualizer = () => {
    const { product } = this.micro.rootStore;
    const localVisModel = JSON.parse(window.localStorage.getItem(this.productPreviewEventName));
    const swatchesToPush = this.productOptionsViewModel.swatchSelections.map((swatch) => {
      // For swatches
      const isPrimary = product.PrimaryColors[swatch.choiceId];
      const isSecondary = product.SecondaryColors[swatch.choiceId];
      let type = '';
      if (isPrimary) type = 'Primary Color';
      if (isSecondary) type = 'Secondary Color';
      // Reformat for how product-preview micro-app expects the data
      return {
        choiceId: swatch.choiceId,
        choiceValue: null,
        optionId: swatch.optionId,
        mediaUrl: swatch.imageUrl,
        type,
        internalName: type,
      };
    });
    // Add height
    swatchesToPush.push({
      choiceId: 1,
      choiceValue: sizeJoin(this.height),
      optionId: 1,
      type: 'Height',
      internalName: 'Height',
    });
    // Add width
    swatchesToPush.push({
      choiceId: 2,
      choiceValue: sizeJoin(this.width),
      optionId: 2,
      type: 'Width',
      internalName: 'Width',
    });

    const data = {
      ...localVisModel,
      selectedOptions: swatchesToPush,
    };
    window.localStorage.setItem(this.productPreviewEventName, JSON.stringify(data));
    publish(this.productPreviewEventName, { selectedOptions: swatchesToPush });
  };
}

export default ThdPipViewModel;
