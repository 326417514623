import { Accordion, AccordionItem, ContentBox, useBreakpoint } from '@gcc/autobahn-components';
import { node, string } from 'prop-types';
import React from 'react';

import StyledContentBox from './reactive-accordion.styles';

const ReactiveAccordion = ({ children, title, expandText }) => {
  const { isLarge } = useBreakpoint();
  return (
    <StyledContentBox>
      {isLarge ? (
        <Accordion behavior="checkbox" type="heading">
          <AccordionItem title={title}>{children}</AccordionItem>
        </Accordion>
      ) : (
        <ContentBox expandFromTitle expandText={expandText} title={title}>
          {children}
        </ContentBox>
      )}
    </StyledContentBox>
  );
};

ReactiveAccordion.propTypes = {
  children: node.isRequired,
  expandText: string,
  title: string,
};

ReactiveAccordion.defaultProps = {
  expandText: undefined,
  title: undefined,
};

export default ReactiveAccordion;
