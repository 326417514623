const routeToLocation = (url) => {
  if (window.location.href.includes('render-nucleus')) {
    window
      .location.href = `/render-nucleus${url}&debug=true&experience=custom-measure-and-install`;
  } else {
    window.location.href = url;
  }
};

export default routeToLocation;
