/* eslint-disable react/jsx-props-no-spreading, react/prop-types */
import React from 'react';
import { shape, element, arrayOf, string } from 'prop-types';
import { Hydrator, declareContexts } from './Hydrator';

const hydratorOpts = {};
const Noop = ({ children }) => children;
export const withHydrator = (params, Component) => {
  let opts;
  let WrappedComponent;
  let tag;
  if (Component) {
    ({ tag, ...opts } = params || {});
    WrappedComponent = Component;
  } else {
    WrappedComponent = params;
  }
  const Ret = ({ children, hydrator, ...props }) => {
    if (!hydrator) {
      return <WrappedComponent {...props}>{children}</WrappedComponent>;
    }

    const hydratorProps = {
      ...opts,
      ...(typeof hydrator === 'object' ? hydrator : {})
    };

    if (hydrator.tag) {
      tag = hydrator.tag;
      // eslint-disable-next-line
      hydrator.tag = null;
    }

    const Tag = tag || Noop;
    return (
      <Hydrator {...hydratorProps}>
        <Tag>
          <WrappedComponent {...props}>
            {children}
          </WrappedComponent>
        </Tag>
      </Hydrator>
    );
  };
  Ret.displayName = WrappedComponent.displayName;
  Ret.dataModel = WrappedComponent?.dataModel || {};
  Ret.wraps = {
    ...(WrappedComponent.wraps || {}),
    hydrator: true,
  };
  return Ret;
};

withHydrator.propTypes = {
  opts: shape({
    contexts: arrayOf(string),
    ...Hydrator.propTypes
  }),
  WrappedComponent: element.isRequired
};
withHydrator.defaultProps = {
  opts: {
    ...Hydrator.defaultProps
  }
};
