import React, { useContext } from 'react';
import { number, arrayOf, shape } from 'prop-types';
import {
  Accordion, AccordionBody, AccordionTitle, Button, Col, Placeholder
} from '@thd-olt-component-react/core-ui';
import { Header } from '../QAResult/component/Header';
import { Search, Sort } from '../Filter';
import { QuestionContainer } from '../QAResult/component/QuestionContainer';
import { AnswerContainer } from '../QAResult/component/AnswerContainer';
import { AskAQuestion } from '../AskAQuestion/AskAQuestion.component';
import { PagerDisplayText } from '../PagerDisplayText/PagerDisplayText.component';
import QAContext from '../../context/QAContext';
import { useQAResult } from '../QAResult/useQAResult';
import { triggerAnalytics } from '../../Analytics';
import { dataModel } from '../dataModel';
import '../QAResult/QAResult.scss';

const QAResultContent = (props) => {

  const { seoAnswers, seoResults, seoTotalResults } = props;
  let {
    channel, qaLoading, dispatch, TotalResults, seoPageNumber, searchText, Results
  } = useContext(QAContext);
  const isMobile = channel === 'mobile';
  const qaResult = useQAResult();
  const isSeoPage = !!(seoPageNumber && qaResult);

  if (seoResults) {
    Results = seoResults;
    TotalResults = seoTotalResults;
  }

  if (qaLoading) {
    return (
      <>
        <Placeholder
          type="rect"
          height="125px"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
        <Placeholder
          type="text"
          showLoadingAnimation
        />
      </>
    );
  }

  const analyticsReadQuestion = () => {
    const payload = { component: 'read question' };
    triggerAnalytics(payload);
  };

  const handleClearSearchPill = () => {
    dispatch({ action: 'SET_SEARCH_TEXT', value: '' });
  };

  return (
    <>
      {isMobile
        ? (
          <>
            <div className="qa-result__row">
              <h3 className="qa-result__accordion-header">{TotalResults}</h3>
              <span>&nbsp;{TotalResults === 1 ? 'question' : 'questions'}</span>
            </div>
            <div className="qa-result__row">
              <Search />
              {searchText && (
                <div className="mobile-search-pill-container">
                  <Button className="search-pill" onClick={handleClearSearchPill} filter>
                    {Results?.length ? searchText : 'Clear search term'}
                  </Button>
                </div>
              )}
            </div>
            <div className="qa-result__row">
              <AskAQuestion />
            </div>
            <div className="grid qa-result__row qa-result__sort">
              <PagerDisplayText topPager loading={qaLoading} />
              <Sort />
            </div>
          </>
        )
        : (
          <>
            <Col>
              <Header showTitleWithTotalQns seoData={seoTotalResults} />
            </Col>
            {!qaResult && (
              <div className="qa-result__title">
                <PagerDisplayText topPager loading={qaLoading} />
                {searchText && (
                  <Button className="search-pill" onClick={handleClearSearchPill} filter>
                    {Results?.length ? searchText : 'Clear search term'}
                  </Button>
                )}
                <Sort />
              </div>
            )}
          </>
        )}
      <Col
        className={isMobile ? 'qa-result__questions qa-result__accordion' : 'qa-result__questions'}
        aria-label="questions"
        nopadding={!isMobile}
      >
        {Results?.length > 0
          && Results.map((result) => {
            const { Id } = result;
            return (
              <Accordion
                icon={{ size: 'sm', iconPosition: isMobile ? 'left' : 'right' }}
                onChange={analyticsReadQuestion}
                key={Id}
                expanded={isSeoPage}
              >
                <AccordionTitle>
                  <QuestionContainer result={result} />
                </AccordionTitle>
                <AccordionBody>
                  <AnswerContainer result={result} seoData={seoAnswers} />
                </AccordionBody>
              </Accordion>
            );
          })}
      </Col>
    </>
  );
};

QAResultContent.displayName = 'QAResultContent';

QAResultContent.propTypes = {
  seoAnswers: arrayOf(shape({})),
  seoResults: arrayOf(shape({})),
  seoTotalResults: number,
};

QAResultContent.defaultProps = {
  seoAnswers: null,
  seoResults: null,
  seoTotalResults: null
};

QAResultContent.dataModel = dataModel;

export { QAResultContent };