import React from 'react';
import { useParams } from '@thd-olt-component-react/router';
import { getPipViewModel } from '../../api/index';
import '../../styles/app.scss';
import { useGccPipApp } from '../../hooks/useGccPipApp';

export const Pip = () => {
  const { itemId } = useParams();
  const { component } = useGccPipApp(itemId, getPipViewModel);

  return <>{component}</>;
};

Pip.propTypes = {
};

Pip.displayName = 'PipPage';

Pip.defaultProps = {
  name: '',
};
