import { breakpoints, Button, styled, ty } from '@gcc/autobahn-components';
import Visualizer from './Visualizer/Visualizer';

export const StyledVisualizerContainer = styled.div` 
 ${breakpoints.ns} {
  min-height: 450px;
 }
`;

export const StyledVisualizer = styled(Visualizer)`
  & [class*='Visualizer--image-main'] {
    ${ty`w-100 mw-100`}
  }
  min-height: 300px;
`;

export const StyledVisualizerText = styled.div`
  ${ty`w-100 w-50-l ml2 pv2 pl3`}
  font-size: 12px;
`;

export const StyledSamplesCta = styled.div`
  ${ty`ph3 pv2 mt2 mr1 bg-mid-gray br2 flex justify-between items-center relative`}
  margin-left: 1.25rem;
  & i {
    ${ty`pt1`}
  }
  & h3 {
    ${ty`b blue f6`}
    ${breakpoints.ns} {
      font-size: 18px;
    }
  }
  & p {
    ${ty`black f5`}
    ${breakpoints.s} {
      font-size: 13px;
    }
  }
  ${breakpoints.s} {
    ${ty`mt2 mh0`}
    padding-left: 12px;
    padding-right: 12px;
  }
`;

export const StyledSamplesCtaButton = styled(Button)`
  ${breakpoints.s} {
    ${ty`ph2 ml1`}
  }
`;

export const StyledSamplesCtaSub = styled.div`
  ${ty`flex relative`}
  & > div {
    ${ty`pl0 pl2-ns`}
  }
`;
