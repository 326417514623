import React, { useContext, useEffect, useState, } from 'react';
import { bool, shape } from 'prop-types';
import classNames from 'classnames';
import {
  number as numberType,
  params,
  shape as shapeType,
  string as stringType,
  bool as boolType,
  useDataModel
} from '@thd-nucleus/data-sources';
import { ExperienceContext, useStore, useConfigService } from '@thd-nucleus/experience-context';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import { IntroOffer } from './intro-offer/intro-offer.component';
import { PaymentEstimator } from './payment-estimator/payment-estimator.component';
import { OffersForYou } from './offers-for-you/offers-for-you.component';
import { loadEstimatorScript } from '../utils/payment-estimator-utils';
import './consumer-card-messaging.style.scss';

const isValidItemId = (itemId) => /^\d{9}$/.test(itemId);
const isValidBundlePrice = (bundlePrice) => {
  return Boolean(
    bundlePrice?.length
  );
};
const isValidProps = (props) => {
  const { itemId, bundlePrice } = props;
  return isValidItemId(itemId) || isValidBundlePrice(bundlePrice);
};

const isValidData = (data) => {
  return typeof data?.product?.pricing?.value === 'number';
};

export const ConsumerCardMessaging = (props) => {
  const { channel } = useContext(ExperienceContext);
  const { isLocalized } = useStore();
  const paymentEstimatorVersion = useConfigService('PaymentEstimatorVersion') || 'v1.2.0';
  const [paymentEstimatorLoaded, setPaymentEstimatorLoaded] = useState(false);

  const {
    itemId,
    bundlePrice,
    isAppliance,
    isFinancePromoEligible,
    clsRemediation,
    isB2B
  } = props;
  const { configuredPrice } = useLifeCycleEventBus('configurator.configurator_pricing_event');
  const hasValidBundlePrice = isValidBundlePrice(bundlePrice);
  const hasValidProps = isValidProps(props);

  const { data, error, loading } = useDataModel('product', {
    variables: { itemId },
    skip: !hasValidProps || hasValidBundlePrice
  });

  useEffect(() => {
    loadEstimatorScript(paymentEstimatorVersion, () => setPaymentEstimatorLoaded(true), channel === 'mobile');
  }, []);

  const hasValidData = isValidData(data) || hasValidBundlePrice;
  const hasDataFullyLoaded = paymentEstimatorLoaded && isLocalized && !loading;

  if (!hasValidProps) {
    return null;
  }

  if (!hasValidData) {
    const { placeholders, preservePlaceholders } = clsRemediation;
    if (!placeholders) {
      return null;
    }
    if (hasDataFullyLoaded && !preservePlaceholders) {
      return null;
    }
  }

  const consumerCardClasses = classNames({
    'consumer-card-messaging--hidden': !hasValidData || !hasDataFullyLoaded
  });

  const isProductAppliance = data?.product?.identifiers?.productType === 'MAJOR_APPLIANCE';
  const value = configuredPrice?.pricing?.value || data?.product?.pricing?.value;
  const unitOfMeasure = configuredPrice?.pricing?.unitOfMeasure || data?.product?.pricing?.unitOfMeasure;
  const alternatePriceDisplay = configuredPrice?.pricing?.alternatePriceDisplay
    || data?.product?.pricing?.alternatePriceDisplay;
  const showPaymentEstimator = !isB2B && !hasValidBundlePrice && value >= 850.00;
  const showIntroOfferB2C = !hasValidBundlePrice && !showPaymentEstimator && value >= 25.0 && value < 850.00 && !isB2B;
  const showIntroOfferB2B = !hasValidBundlePrice && !showPaymentEstimator && value >= 25.0 && isB2B;

  return (
    <div className={consumerCardClasses}>
      {hasValidBundlePrice && (
        <OffersForYou
          price={`${bundlePrice}`}
          isMobile={channel === 'mobile'}
          isAppliance={isAppliance}
          paymentEstimatorLoaded={paymentEstimatorLoaded}
        />
      )}
      {showPaymentEstimator && (
        <PaymentEstimator
          price={`${value}`}
          isMobile={channel === 'mobile'}
          isAppliance={isProductAppliance}
          isHDHome={isFinancePromoEligible}
          paymentEstimatorLoaded={paymentEstimatorLoaded}
        />
      )}
      {(showIntroOfferB2C || showIntroOfferB2B) && (
        <IntroOffer
          price={value}
          isB2B={isB2B}
          mobile={channel === 'mobile'}
          unitOfMeasure={unitOfMeasure}
          alternatePriceDisplay={alternatePriceDisplay}
          paymentEstimatorLoaded={paymentEstimatorLoaded}
        />
      )}
    </div>
  );
};

ConsumerCardMessaging.displayName = 'ConsumerCardMessaging';

ConsumerCardMessaging.dataModel = {
  product: params({
    itemId: stringType().isRequired()
  }).shape({
    dataSources: stringType(),
    pricing: params({ storeId: stringType() }).shape({
      value: numberType(),
      unitOfMeasure: stringType(),
      alternatePriceDisplay: boolType()
    }),
    identifiers: shapeType({
      productType: stringType()
    })
  })
};

const checkRequiredProps = (props) => {
  if (!props.itemId && !props.bundlePrice) {
    return new Error('The \'itemId\' or \'bundlePrice\' is required by the consumer-card-messaging component.');
  }
  return null;
};

ConsumerCardMessaging.propTypes = {
  itemId: checkRequiredProps,
  bundlePrice: checkRequiredProps,
  isAppliance: bool,
  isFinancePromoEligible: bool,
  clsRemediation: shape({
    placeholders: bool,
    preservePlaceholders: bool
  }),
  isB2B: bool,
};

ConsumerCardMessaging.defaultProps = {
  itemId: null,
  bundlePrice: null,
  isAppliance: false,
  isFinancePromoEligible: false,
  clsRemediation: {
    placeholders: false,
    preservePlaceholders: false
  },
  isB2B: false,
};
