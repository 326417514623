import axios from 'axios';
import { appendAutobahnUrl } from '../../../utility/customMeasureAndInstallExpConfiguration';

class SampleCart {
  static getSampleCart() {
    const {
      pageModel: { getSampleCartUrl },
    } = window.pageContextJavascript;

    return axios({
      url: appendAutobahnUrl(getSampleCartUrl),
      header: { 'Cache-Control': 'no-store' },
      withCredentials: true,
      crossDomain: true,
      method: 'GET'
    });
  }
}

export default SampleCart;
