import { action, makeObservable } from 'mobx';

class ProductReviewsViewModel {
  constructor(rootVm) {
    makeObservable(this, {
      expandReviews: action,
      rootVm: false,
    });
    this.rootVm = rootVm;
  }

  expandReviews = () => {
    const element = document.querySelector('#product-reviews .accordion-toggle');
    if (element) {
      element.click();
      element.checked = true;
    }
  };
}

export default ProductReviewsViewModel;
