import { action, makeObservable } from 'mobx';

class QuestionsAndAnswersViewModel {
  constructor(rootVm) {
    makeObservable(this, {
      expandQuestionsAndAnswers: action,
      rootVm: false,
    });
    this.rootVm = rootVm;
  }

  expandQuestionsAndAnswers = () => {
    const element = document.querySelector('#questions-and-answers .accordion-toggle');
    if (element) {
      element.click();
      element.checked = true;
    }
  };
}

export default QuestionsAndAnswersViewModel;
