import { breakpoints, styled, ty, css } from '@gcc/autobahn-components';

export const StyledFreeSamplesSection = styled.div`
  ${ty`tc flex justify-between items-center`}
  position: sticky;

  ${breakpoints.ns} {
    ${({ desktopView }) => !desktopView && 'display: none;'}
    ${ty`top-1`}
  }

  ${breakpoints.s} {
    ${({ desktopView }) => desktopView && 'display: none;'}
    ${ty`bottom-0 pv2`}
    background-color: var(--white);
    bottom: ${({ entry }) => `${entry}px`}
    ${({ className }) => className && css`
      & {
        className: sui-fixed sui-transition-[bottom] sui-duration-500 sui-p-8 sui-w-full;
      }
    `}
  }

  ${breakpoints.l} {
    ${ty`flex-column ph3 pb3`}
  }

  & .free-samples-title {
    ${ty`f5 pv3`}
    color: #333333;
  }

  & h2 {
    ${ty`f3`}
  }

  & .free-samples-description {
    color: black;
    ${ty`f5 w-45 tl pl3 pv2 pr2`}
    ${breakpoints.l} {
      ${ty`mb3 mr0 w-100 tc pa0`}
    }
  }

  & .free-samples-count {
    ${ty`fw6`}
    font-size: 18px;
  }

  & .free-samples-button {
    ${ty`w-55 w-100-l pr3 pr0-l`}
  }

  & button, a {
    ${ty`w-100`}
    ${breakpoints.s} {
      ${ty`ph1`}
    }
  }

  & .free-samples-ship-warning {
    ${ty`f6 dn db-l`}
    color: black;
  }

  & p {
    ${ty`f5 db di-l`}
  }
`;

export const StyledFreeSamplesAmount = styled.span`
  ${ty`b ph1`}
`;
