import { ProductReviews as ProductReviewsGCCComponents } from '@gcc/autobahn-components';
import { shape } from 'prop-types';
import React from 'react';

import ReactiveAccordion from '../reactive-accordion/reactive-accordion.view';

const expandText = 'Write a Review';
const checkSubmitReview = (arg) => {
  const { target } = arg;
  if (target.innerText === expandText && target.closest('[class*="StyledExpandContentBox"]')) {
    const { $BV } = window;
    $BV.ui('rr', 'submit_review');
  }
};

const ProductReviews = ({ vm }) => (
  <div onClick={checkSubmitReview} role="presentation">
    <ReactiveAccordion vm={vm} title="Customer Reviews" expandText={expandText}>
      <ProductReviewsGCCComponents />
    </ReactiveAccordion>
  </div>
);

ProductReviews.propTypes = {
  vm: shape({}),
};

ProductReviews.defaultProps = {
  vm: {},
};

export default ProductReviews;
