import { observable } from 'mobx';
import { observer } from 'mobx-react-lite';
import { shape } from 'prop-types';
import React, { useState } from 'react';

import ThdPipView from './components/thd-pip/thd-pip.view';
import ThdPipViewModel from './components/thd-pip/thd-pip.view-model';

const App = observer((props) => {
  const stickyHeaderId = 'sticky-header';
  const {
    bazaarVoiceApiUrl,
    bazaarVoiceConversationsApiUrl,
    bazaarVoicePasskey,
    configuratorUrl,
    enhancedVisualization,
    isProfessionallyInstallable,
    isSamplingEnabled,
    isSamplingEnabledCecOnly,
    mandIPhoneNumber,
    micro,
    pageMode,
    partnerProductId,
    productId,
  } = props;

  const contactNumber = window?.globalConfig?.contactNumber;

  // Define the data store that will be shared amongst the micro apps.
  micro.rootStore = observable({ product: {} });

  // Setup the data context.
  const [state] = useState({
    dataContext: new ThdPipViewModel(micro, {
      bazaarVoiceApiUrl,
      bazaarVoiceConversationsApiUrl,
      bazaarVoicePasskey,
      configuratorUrl,
      contactNumber,
      enhancedVisualization,
      isProfessionallyInstallable,
      isSamplingEnabled,
      isSamplingEnabledCecOnly,
      mandIPhoneNumber,
      pageMode,
      partnerProductId,
      productId,
      stickyHeaderId,
    }),
  });

  const { dataContext, rootStore } = state;

  return (
    <>
      <section id={stickyHeaderId} />
      <ThdPipView vm={dataContext} rootStore={rootStore} />
    </>
  );
});

App.propTypes = {
  micro: shape({}).isRequired,
};

export default App;
