import React, { useState } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { Image } from '@thd-olt-component-react/core-ui';
import styles from '../../styles/preferred-pricing.module.scss';

const cx = classNames.bind(styles);

const getPreferredPricingDetails = () => {
  return (
      <div>
        <span>
            As a Pro Xtra member, you are qualified for&nbsp;
            <a className="u__default-link" href="https://www.homedepot.com/c/pro_preferred_pricing">Preferred Pricing</a>
            .
        </span>
      </div>
  );
}

export const PreferredPricingBadge = (props) => {

  const [disableHoverTooltip, setDisableHoverTooltip] = useState(false);

  const { channel, large, showPreferredPricingBadgeToolTip } = props;

  const isMobile = channel === 'mobile';

  const preferredPricingDetails = getPreferredPricingDetails();

  const preferredPricingWrapperClasses = cx('preferred-pricing__wrapper', {
    'preferred-pricing__wrapper--side-padding': large
  });

  return (
    <div className={preferredPricingWrapperClasses}
    >
      <div>
        <Image
          className={cx("preferred-pricing__badge")}
          src="https://assets.thdstatic.com/images/v1/PreferredPricing-badge.svg"
          alt="Preferred Pricing Badge"
          title="Preferred Pricing" />
      </div>
      {showPreferredPricingBadgeToolTip && (
        <div className={cx("preferred-pricing__details")}>
        {isMobile && (
          <Tooltip
            channel={channel}
            content={preferredPricingDetails}
            interactive
          >
            <Image
              className={cx("preferred-pricing__info-icon")}
              src="https://assets.thdstatic.com/images/v1/info.svg"
              alt="preferred pricing info icon"
            />
          </Tooltip>
        )}
        {!isMobile && (
          <Tooltip
            channel={channel}
            content={preferredPricingDetails}
            interactive
            maxWidth={300}
            disableTooltip={disableHoverTooltip}
            placement="top"
          >
            <div>
              <Tooltip
                channel={channel}
                closeButton
                content={preferredPricingDetails}
                interactive
                maxWidth={320}
                visible={disableHoverTooltip}
                placement="top"
              >
                <div>
                  <Image
                    className={cx("preferred-pricing__info-icon")}
                    src="https://assets.thdstatic.com/images/v1/info.svg"
                    alt="preferred pricing info icon"
                    onClick={() => setDisableHoverTooltip(!disableHoverTooltip)}
                    onClose={() => setDisableHoverTooltip(false)}
                  />
                </div>
              </Tooltip>
            </div>
          </Tooltip>
        )}
      </div>
      )}
    </div>
  );
};

PreferredPricingBadge.propTypes = {
   /**
   * The type of channel ie; Desktop/Mobile
   */
  channel: PropTypes.string.isRequired,
};

PreferredPricingBadge.defaultProps = {};
