import axios from 'axios';
import { appendAutobahnUrl } from '../../../utility/customMeasureAndInstallExpConfiguration';

class ProductService {
  static getProduct() {
    const {
      pageModel: { productDetailApiUrl },
    } = window.pageContextJavascript;
    return axios({
      url: appendAutobahnUrl(productDetailApiUrl),
      crossDomain: true,
      method: 'GET'
    });
  }

  static async getProductPrice({ calculateColorChoicePrices = true, selectedPricingContext, height, width }) {
    const {
      pageModel: { productId, productPricingWithColorsUrl, siteId },
    } = window.pageContextJavascript;
    return axios({
      url: appendAutobahnUrl(productPricingWithColorsUrl),
      crossDomain: true,
      params: {
        calculateColorChoicePrices,
        height,
        productId,
        selectedPricingContext,
        siteId,
        width,
      },
      method: 'GET'
    });
  }
}
export default ProductService;