import React, { Component } from 'react';
import PropTypes from 'prop-types';
import smoothscroll from 'smoothscroll-polyfill';
import helpers from '../helpers';
import * as analytics from '../analytics';

class SmoothScroll extends Component {

  componentDidMount() {
    smoothscroll.polyfill();
  }

  smoothScroll = (event) => {
    event.preventDefault();
    const { target } = this.props;
    window.scroll({
      top: helpers.findPos(target),
      behavior: 'smooth'
    });
    const hash = event.target.hash?.substring(1, event.target.hash.length) || target.substring(1, target.length);
    analytics.track({
      target: helpers
        .getAnalyticsTarget(hash),
      hash
    });
  };

  render() {
    const { target, children, className } = this.props;
    return (
      <a href={target} onClick={this.smoothScroll} className={className}>
        {children}
      </a>
    );
  }
}

SmoothScroll.propTypes = {
  children: PropTypes.node,
  target: PropTypes.string,
  /** class modifiers. */
  className: PropTypes.string
};
SmoothScroll.defaultProps = {
  children: null,
  target: null,
  className: null
};

export default SmoothScroll;
