/* eslint-disable id-length */
import { backslashToForwardslash } from '@gcc/autobahn-utilities';
import { forOwn, groupBy } from 'lodash';
import { action, computed, makeAutoObservable, observable } from 'mobx';

import ProductSwatchViewModel from '../product-swatch/product-swatch.view-model';

class ProductOptionsViewModel {
  constructor(rootVm) {
    makeAutoObservable(this, {
      colorSwatchesSections: observable,
      isMandI: computed,
      rootVm: false,
      setColorSwatchesSections: action.bound,
      setShowAllChoices: action,
      setSwatchSelections: action,
      showAllChoices: observable,
      swatchSelections: observable,
      zoomPreviewPosition: observable,
    });
    this.rootVm = rootVm;
    this.productSwatchViewModel = new ProductSwatchViewModel(this);
  }

  colorSwatchesSections = [];

  productSwatchViewModel = undefined;

  setSwatchSelections = (newSwatchSelections) => {
    this.swatchSelections = newSwatchSelections;
    this.rootVm.publishOptionsToVisualizer();
  };

  setColorSwatchesSections = (swatchesPrices, percentOff) => {
    const colorSwatchesSections = [];
    const grouped = groupBy(this.rootVm.product.ColorGroups, 'OptionName');
    forOwn(grouped, (value, key) => {
      const keyPhrase = key.substring(key.length - 1) === 's' ? `${key} and Samples` : `${key}s and Samples`;
      const optionGroups = value;
      optionGroups.forEach(({ Swatches }) => {
        Swatches.forEach((swatch) => {
          const updateSwatch = swatch;
          const swatchPrice = swatchesPrices.find((x) => x.ChoiceId === swatch.ChoiceId);
          updateSwatch.IsInCart = this.rootVm.sampleIdsInCart.includes(swatch.ChoiceId);

          if (swatchPrice) {
            updateSwatch.Price = swatchPrice.Price;
            updateSwatch.StrikeThroughPrice = swatchPrice.StrikeThroughPrice;
            updateSwatch.PercentOff = percentOff;
          }
        });
      });
      colorSwatchesSections.push({
        sectionName:
          !this.isMandI && optionGroups.some((x) => x.Swatches.some((y) => y.IsSampleable && y.IsSampleAvailable))
            ? keyPhrase
            : key,
        optionGroups,
      });
    });
    this.colorSwatchesSections = colorSwatchesSections;
  };

  imageUrl = (url) => `${this.rootVm.baseUrl}${backslashToForwardslash(url)}`;

  swatchSelections = [];

  setShowAllChoices = () => {
    this.showAllChoices = true;
  };

  previewSize = 300;

  showAllChoices = false;

  zoomPreviewPosition = 0;

  get isMandI() {
    return this.rootVm.isMandI;
  }
}

export default ProductOptionsViewModel;
