import {
  Button,
  RatioBox,
  styled,
  ty,
  breakpoints
} from '@gcc/autobahn-components';

export const StyledCustomizeAndBuyButton = styled(Button)`
  ${ty`w-100`}
`;

export const StyledProductPreviewImageWrapper = styled.div`
  ${ty`db center`}
  & > div {
    ${ty`db center z-max`}
  }
`;

export const StyledProductPreviewWrapper = styled.section`
  ${ty`w-100 tc pv4 ph3 bb b--mid-gray`}
  min-height: 601px;
`;

export const StyledProductPreviewTitle = styled.h2`
  ${ty`f3`}
  color: #333333;
  margin-bottom: 10px;
`;

export const StyledRatioBox = styled(RatioBox)`
  ${ty`bg-gray br3 overflow-hidden`}
  min-width: 45px;
  min-height: 45px;
`;

export const StyledSwatchName = styled.div`
  ${ty`f6 lh-solid pt2`}
  overflow-wrap: break-word;
  hyphens: auto;
`;

export const StyledThumbnailPrice = styled.div`
  ${ty`flex pt4 ph4 mb4 ph0-m ph1-l`}
`;

export const StyledPrice = styled.div`
  ${ty`w-70 pl2 pl2-m`}
  & [class*="Strikethrough"] {
    text-align: left;
  }
`;

export const StyledThumbnail = styled.div`
  ${ty`w-30 pr3 pr2-m`}
`;

export const StyledHoverText = styled.div`
  font-size: var(--font-size-6);
`;

export const StyledNewTag = styled.div`
  ${ty`dib bg-yellow br2 ph2 pv1 mr1 fw7`}
`;

export const StyledNewTagWrapper = styled.div`
  font-size: 1rem;
`;

export const StyledVisualizerContainer = styled.div`
  ${ty`db`}
  ${breakpoints.s} {
    & [class*='StyledVisualizer-sc'],
    & [class*='StyledLiquidPixelVisualizer-sc'] {
      top: 9rem;
      ${({ hasPrimarySwatch }) => !hasPrimarySwatch && ty`dn`}
      ${({ isSwatchSelected }) => !isSwatchSelected && ty`dn`}
    }
  }
  ${breakpoints.ns} {
    & [class*='StyledVisualizer-sc'],
    & [class*='StyledLiquidPixelVisualizer-sc'] {
      ${ty`w-100 static`}
    }
    & [class*='StyledVisualizerRatioBox-sc'] {
      box-shadow: none;
    }
  }
`;

export const StyledMainContainer = styled.div`
  ${ty`w-100 center br2 b--gray overflow-hidden bg-white mt2`}
  ${breakpoints.ns} {
    ${({ theme }) => theme?.pip?.preview?.container?.maxWidth}
  }
`;

export const StyledInfo = styled.div`
  ${ty`pb3 pb4-l pt3 pt2-l ph0 dn db-ns`}
`;
