class Sku {
  constructor(product, configuredPrice) {
    this._product = product;
    this._configuredPrice = configuredPrice;
  }

  get itemId() {
    const {
      itemId
    } = this._product?.identifiers || {};

    return itemId;
  }

  get unitOfMeasureCoverage() {
    const unitOfMeasureCoverage = (
      this._product?.pricing?.unitOfMeasureCoverage || this._product?.info?.unitOfMeasureCoverage
    );

    return unitOfMeasureCoverage;
  }

  get hidePrice() {
    const { hidePrice } = this._product?.info || {};
    return hidePrice;
  }

  get productSubType() {
    const { productSubType } = this._product?.info || {};
    return productSubType;
  }

  get category() {
    const { categoryHierarchy } = this._product?.info || {};
    return categoryHierarchy?.find((category) => category === 'Custom Kitchen Cabinets');
  }

  get rebates() {
    const ecoRebate = (this._product?.pricing?.ecoRebate || this._product?.info?.ecoRebate);

    if (ecoRebate) return { hasEcoRebates: true };

    return null;
  }

  get storePromotion() {
    const { description } = this._product?.pricing?.promotion || {};

    const { shortDesc, longDesc } = description || {};

    if (shortDesc !== 'INSTANT_REBATES') return null;

    return {
      longDescription: longDesc,
      shortDescription: shortDesc
    };
  }

  get promotionDetails() {
    const {
      dates,
      description,
      promoCode,
      type,
      url
    } = this._configuredPrice?.pricing?.promotion || this._product?.pricing?.promotion || {};

    if (type !== 'DISCOUNT') return null;

    return {
      discountEndDate: dates?.end,
      discountStartDate: dates?.start,
      promoCode,
      promoDescription: description?.shortDesc,
      promoLongDescription: description?.longDesc,
      promoType: type,
      promoUrl: url,
      startAndEndDatesSpecified: (!!dates?.start && !!dates?.end)
    };
  }

  get maxPriceRange() {
    const sskMax = (this._product?.pricing?.sskMax || this._product?.info?.sskMax);
    return sskMax;
  }

  get minPriceRange() {
    const sskMin = (this._product?.pricing?.sskMin || this._product?.info?.sskMin);
    return sskMin;
  }

  get wasMinPriceRange() {
    const wasMinPriceRange = (this._product?.pricing?.wasMinPriceRange || this._product?.info?.wasMinPriceRange);
    return wasMinPriceRange;
  }

  get wasMaxPriceRange() {
    const wasMaxPriceRange = (this._product?.pricing?.wasMaxPriceRange || this._product?.info?.wasMaxPriceRange);
    return wasMaxPriceRange;
  }

  get quantityLimit() {
    const quantityLimit = (this._product?.pricing?.quantityLimit || this._product?.info?.quantityLimit);
    return quantityLimit;
  }

  get preferredPriceFlag() {
    const {
      preferredPriceFlag
    } = this._product?.pricing || false;
    return preferredPriceFlag;
  }

  get pricing() {
    const {
      alternatePriceDisplay,
      alternate,
      original: originalPrice,
      mapAboveOriginalPrice,
      message: pricingMessage,
      promotion,
      specialBuy: specialBuyPrice,
      unitOfMeasure: uom,
      value: specialPrice
    } = this._configuredPrice?.pricing || this._product?.pricing || {};

    const {
      dollarOff,
      percentageOff,
      savingsCenter,
      savingsCenterPromos,
      specialBuySavings,
      specialBuyDollarOff,
      specialBuyPercentageOff
    } = promotion || {};

    const { bulk, unit } = alternate || {};

    const {
      value: bulkPrice,
      thresholdQuantity: bulkPriceThresholdQty
    } = bulk || {};

    const {
      unitsPerCase,
      caseUnitOfMeasure: caseUnitUom,
      unitsOriginalPrice: unitOriginalPrice,
      value: unitSpecialPrice
    } = unit || {};

    const basicPrice = {
      uom,
      unitsPerCase,
      caseUnitUom,
      originalPrice,
      specialPrice,
      alternatePriceDisplay,
      unitOriginalPrice,
      unitSpecialPrice,
      dollarOff,
      percentageOff,
      bulkPrice,
      bulkPriceThresholdQty,
      specialBuyPrice,
      specialBuySavings,
      specialBuyDollarOff,
      specialBuyPercentageOff,
      pricingMessage,
      mapAboveOriginalPrice
    };
    const priceDetails = {
      itemId: this.itemId,
      savingsCenter,
      savingsCenterPromos,
      quantityLimit: this.quantityLimit,
      minPriceRange: this.minPriceRange,
      maxPriceRange: this.maxPriceRange,
      wasMinPriceRange: this.wasMinPriceRange,
      wasMaxPriceRange: this.wasMaxPriceRange,
      productSubType: this.productSubType,
      category: this.category,
      hidePrice: this.hidePrice,
      storeAvailability: null,
      storePromotion: this.storePromotion,
      rebates: this.rebates,
      installationServiceType: null,
      promotionDetails: this.promotionDetails,
      unitOfMeasureCoverage: this.unitOfMeasureCoverage,
    };

    return { ...basicPrice, ...priceDetails };
  }

  get secondaryImageUrl() {

    if (!this._product.media) {
      throw new Error('secondaryImageUrl requires media domain on product');
    }
    const secondImg = this._product.media?.images?.[1];

    return secondImg;
  }
}

export default Sku;
