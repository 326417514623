import { breakpoints, styled } from '@gcc/autobahn-components';
import { SlidesWrapper, Dots } from '@gcc/pip-components/dist/DotCarousel';

export const StyledGalleryImage = styled.img`
  ${breakpoints.s} {
    height: 265px;
    width: 265px;
  }
`;

export const StyledMobileCarouselContainer = styled.div`
  ${Dots} {
    display: none;
  }

  ${SlidesWrapper} {
    overflow: visible;
  }
`;
