import { styled, ty, Button, breakpoints } from '@gcc/autobahn-components';

export const StyledBrandandVendor = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 25rem;
`;

export const StyledBrand = styled.h2`
  ${ty`fw7 f6 lh-solid mh0 mt0 mb3`}
  &:before {
    ${ty`fw4`}
    content: 'By ';
  }
`;

export const StyledVendor = styled.h2`
  ${ty`fw7 f6 lh-solid mr0-s`}
  &:before {
    ${ty`fw4`}
    content: 'Vendor Name: ';
  }
`;

export const StyledCTANoShipping = styled.div`
  ${ty`flex items-center pv3`}
  & > p {
    ${ty`pl1 ma0 lh-solid`}
  }
`;

export const StyledDescription = styled.section`
  ${ty`pb3 pt3-ns mt3-ns bt-ns b--gray`}
  min-height: 6rem;
  & > ul {
    ${ty`pl3`}
  }
`;

export const StyledEighths = styled.div`
  ${ty`pl2`}
  & label {
    opacity: 0;
    overflow: hidden;
  }
`;

export const StyledHeight = styled.div`
  ${ty`flex flex-wrap`}
  box-sizing: border-box;
  & > div {
    ${ty`w-50`}
    box-sizing: border-box;
    &:last-child {
      ${ty`pl3`}
    }
  }
`;

export const StyledHighlightIcon = styled.div`
  ${ty`
    pr1
    ${({ phone }) => phone && 'f3 tc'}
  `}
  width: 2.4rem;
`;

export const StyledHighlights = styled.section`
  min-height: 7rem;
  & > ul {
    ${ty`list ma0 pa0 flex flex-wrap lh-solid items-center`}
    & > li {
      ${ty`flex w-100 w-50-ns relative items-center mb3`}
    }
  }
`;

export const StyledLink = styled.a`
  ${ty`no-underline blue`}
  &:hover {
    ${ty`underline`}
  }
`;

export const StyledMeasurements = styled.section`
  ${ty`pb4 mb3 bb b--gray`}
`;

export const StyledPrice = styled.section`
  ${ty`pb3`}
`;

export const StyledProductName = styled.h1`
  ${ty`fw mb2`}
  font-size: 18px;
`;

export const StyledQASummary = styled.section`
  ${ty`no-wrap pv2 pv0-ns ml3`}
  flex-shrink: 0;
`;

export const StyledReviewsQASummary = styled.section`
  ${ty`h2-ns pb3 pb0-ns flex flex-wrap flex-nowrap-ns items-center`}
`;

export const StyledWidth = styled.div`
  ${ty`flex flex-wrap mb3`}
  box-sizing: border-box;
  & > div {
    ${ty`w-50`}
    box-sizing: border-box;
    &:last-child {
      ${ty`pl3`}
    }
  }
`;

export const StyledReviewSummaryBox = styled.div`
  ${ty`pointer`}
`;

export const StyledCustomizeAndBuyButton = styled(Button)`
${breakpoints.s} {
  ${ty`w-100`}
}
`;

export const StyledConsumerCardContainer = styled.div`
  ${ty`mb3`}
  .sui-inline-flex {
    display: inline-flex;
  }
  .sui-cursor-pointer {
    cursor: pointer;
  }
    img:first-child {
      width: 50px;
      height: fit-content;
      padding-right: 1rem;
      padding-top: 5px;
    }
  }
`;