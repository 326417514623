import { action, computed, makeObservable } from 'mobx';

import ProductOverviewModel from './product-overview.model';

class ProductOverviewViewModel extends ProductOverviewModel {
  constructor(micro) {
    super(micro);
    makeObservable(this, {
      description: computed,
      expandOverview: action,
      scrollToProductOverview: action,
    });
  }

  scrollToProductOverview = ({ element, isNotSmall }) => {
    let yOffset = 0;
    if (isNotSmall) {
      this.expandOverview();
      yOffset = -150;
    }
    const top = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.setTimeout(() => window.scrollTo({ top, behavior: 'smooth' }), 100);
  };

  showGuaranteedToFit = ({ breakpoint, element, isNotSmall }) => {
    const isSmallOrMedium = ['small', 'medium'].includes(breakpoint);
    if (isSmallOrMedium) {
      const el = document.querySelector('#product-overview div[class*="ExpandContentBox"] div[role="button"]');
      el.click();
      const contentElement = document.querySelector('#product-overview div[class*="ContentBoxWrapper"]');
      // eslint-disable-next-line no-return-assign
      window.setTimeout(() => (contentElement.scrollTop = contentElement.scrollHeight), 100);
    } else {
      this.scrollToProductOverview({ element, isNotSmall });
    }
  };

  expandOverview = () => {
    const element = document.querySelector('#product-overview .accordion-toggle');
    if (element) {
      element.checked = true;
    }
  };

  get description() {
    const attributes = this.micro?.rootStore?.product?.Attributes;
    return attributes ? attributes['Product Description'] : '';
  }
}

export default ProductOverviewViewModel;
