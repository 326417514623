import axios from 'axios';
import { CustomMeasureAndInstallExpConfiguration } from '../utility/customMeasureAndInstallExpConfiguration';

export default (productId) => axios
// eslint-disable-next-line max-len
  .get(`${CustomMeasureAndInstallExpConfiguration.AUTOBAHN_BASE_URL}/api/brightcove/videos?limit=100&q=product_id:${productId}`)
  .then((result) => {
    const {
      data: { count, videos },
    } = result;
    return videos;
  })
  .catch((e) => {
    const msg = `Brightcove: "${e}"`;
    // eslint-disable-next-line no-console
    console.error(msg);
  });
