const validateParams = (params) => {
  let validationResult = true;
  if (params.length === 0) return false;

  params.forEach((arg) => {
    if (!arg.paramValue) {
      validationResult = false;
    }
    if (!arg.paramOrder) {
      validationResult = false;
    }
  });

  return validationResult;
};

export const createParamInfo = (paramName, paramValue, paramOrder) => (
  {
    paramName,
    paramValue,
    paramOrder,
  }
);

export const pullParamsValues = (params) => {
  if (!validateParams(params)) {
    const error = new Error('Error: params are missing or invalid');
    // eslint-disable-next-line no-console
    console.error('Error: params are missing or invalid, params =  ', params);
    throw error;
  }

  // Sort the data
  const paramsSorted = params.sort((paramA, paramB) => (paramA.paramOrder - paramB.paramOrder));
  const paramValues = paramsSorted.map((x) => (x.paramValue));
  return paramValues;
};

export const getMandiQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const designId = urlParams.get('designId');
  const itemId = urlParams.get('itemId');
  if (designId && itemId) { return `&designId=${designId}&itemId=${itemId}`; }
  return '';
};

export const getWidthHeightParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const width = urlParams.get('width');
  const height = urlParams.get('height');
  if (width && height) { return `&width=${width}&height=${height}`; }
  return '';
};