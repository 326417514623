import { decimalToFraction } from '@gcc/autobahn-utilities';
import { observer } from 'mobx-react-lite';
import React from 'react';
import StyledPrice from '../../../product-price/product-price.styles';

const ProductPrice = observer(({ vm, showSuffix = false }) => {
  const {
    price: { badge, originalPrice = 0, promo },
  } = vm.productPriceViewModel;
  const showSuffixText = showSuffix
    ? `for
      ${vm.width.wholes}
      ${decimalToFraction(vm.width.eighths)}
      in by
      ${vm.height.wholes}
      ${decimalToFraction(vm.height.eighths)}
      in`
    : undefined;
  return (
    <StyledPrice
      className="description"
      badge={badge}
      price={originalPrice}
      promo={promo}
      suffix={showSuffixText}
    />
  );
});

export default ProductPrice;
