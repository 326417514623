import { DictionaryTermProvider, DictionaryTermsWrapper, Icon, useBreakpoint } from '@gcc/autobahn-components';
import { subscribe, unsubscribe } from '@gcc/autobahn-utilities';
import { observer } from 'mobx-react-lite';
import { shape } from 'prop-types';
import React, { useEffect, useRef } from 'react';
// eslint-disable-next-line max-len
import { CustomMeasureAndInstallExpConfiguration } from '../../../../../../utility/customMeasureAndInstallExpConfiguration';

import {
  StyledOverviewContent,
  StyledProductOverview,
  StyledSureFitArea,
  StyledSureFitHeader,
  StyledSureFitIcon,
  StyledSureFitList,
} from './product-overview.styles';

const ProductOverviewView = observer((props) => {
  const {
    vm: { description, scrollToProductOverview, showGuaranteedToFit },
  } = props;
  const productOverviewRef = useRef(null);
  const { breakpoint, isNotSmall } = useBreakpoint();

  useEffect(() => {
    const scrollTo = subscribe('product-overview:scrollto', (msg, data) => {
      if (data?.guaranteedToFit) {
        showGuaranteedToFit({ element: productOverviewRef.current, breakpoint, isNotSmall });
      } else {
        scrollToProductOverview({ element: productOverviewRef.current, isNotSmall });
      }
    });
    return () => unsubscribe(scrollTo);
  });

  return (
    <StyledProductOverview ref={productOverviewRef}>
      <StyledOverviewContent>
        {/* eslint-disable-next-line max-len */}
        <DictionaryTermProvider productLineId={1} baseApiUrl={CustomMeasureAndInstallExpConfiguration.AUTOBAHN_BASE_URL}>
          <DictionaryTermsWrapper markdown>{description}</DictionaryTermsWrapper>
        </DictionaryTermProvider>
      </StyledOverviewContent>
      <StyledSureFitArea>
        <StyledSureFitHeader>
          <StyledSureFitIcon>
            <Icon name="guaranteed-to-fit" size="auto" />
          </StyledSureFitIcon>
          Guaranteed To Fit
        </StyledSureFitHeader>
        <p>
          Worried about measuring? Get complete peace of mind with Home Depot&apos;s exclusive Guaranteed To Fit
          promise. If your window treatment doesn&apos;t fit, we&apos;ll send you a new one at no cost. We&apos;ll cover
          shipping too!
        </p>
        <StyledSureFitList>
          <li>Limited to 4 blinds per household</li>
          <li>No cash refunds</li>
          <li>Exchange must be the same product and color</li>
          <li>Additional charges may apply if there&apos;s a price difference between old and new blinds</li>
          <li>Exchange must occur within 15 days of delivery to your home</li>
        </StyledSureFitList>
      </StyledSureFitArea>
    </StyledProductOverview>
  );
});

ProductOverviewView.propTypes = {
  vm: shape({}),
};

ProductOverviewView.defaultProps = {
  vm: {},
};

export default ProductOverviewView;
