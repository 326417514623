import { observer } from 'mobx-react-lite';
import { shape } from 'prop-types';
import React, { useState } from 'react';

import ProductOverviewView from './components/product-overview/product-overview.view';
import ProductOverviewViewModel from './components/product-overview/product-overview.view-model';

const App = observer((props) => {
  const { micro } = props;
  const [state] = useState({
    dataContext: new ProductOverviewViewModel(micro),
  });

  const { dataContext } = state;

  return <ProductOverviewView vm={dataContext} />;
});

App.propTypes = {
  micro: shape({}).isRequired,
};

export default App;
