/* eslint-disable no-console */
import { BazaarVoiceProvider, Button, Dropdown, Icon } from '@gcc/autobahn-components';
import { ConsumerCardMessaging } from '@thd-olt-component-react/consumer-card-messaging';
import { extend } from '@thd-nucleus/data-sources';
import { MdPhoneIphone } from '@react-icons/all-files/md/MdPhoneIphone';
import { isInternalUser } from '@gcc/autobahn-utilities';
import { observer } from 'mobx-react-lite';
import React, { useContext, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import HowToGetIt from '../how-to-get-it/how-to-get-it.view';
import ProductInstallationCta from '../product-installation-cta/product-installation-cta.view';
import ProductPrice from '../product-price/product-price.view';
import {
  StyledBrand,
  StyledBrandandVendor,
  StyledCTANoShipping,
  StyledCustomizeAndBuyButton,
  StyledDescription,
  StyledEighths,
  StyledHeight,
  StyledHighlightIcon,
  StyledHighlights,
  StyledLink,
  StyledMeasurements,
  StyledPrice,
  StyledProductName,
  StyledQASummary,
  StyledReviewsQASummary,
  StyledReviewSummaryBox,
  StyledVendor,
  StyledWidth,
  StyledConsumerCardContainer
} from './product-summary.styles';
import routeToLocation from '../../utils/route-to-location';

const ProductSummary = observer(({ vm }) => {
  const {
    contactNumber,
    configuratorUrl,
    ctaButtonLabel,
    dimensions,
    isMandI,
    isProfessionallyInstallable,
    mandIPhoneNumber,
    productPriceViewModel: { getProductPrice },
    productSummaryViewModel: { dimensionsOptions, getIsB2B, scrollToProductOverview, showGuaranteedToFit },
    product: {
      Attributes: attributes = [],
      Brand: brand,
      EstimatedDeliveryDate: estimatedDeliveryDate,
      ProductName: productName,
      PartnerProductId,
      VendorName: vendor,
    },
    scrollToCustomerReviews,
    scrollToQuestionsAndAnswers,
    updateDimensions,
  } = vm;
  const priceRef = useRef(null);
  const {
    obj: {
      height: { wholes: heightWholes, eighths: heightEighths },
      width: { wholes: widthWholes, eighths: widthEighths },
    },
  } = dimensions;

  const context = useContext(BazaarVoiceProvider.Context);
  const { ratingsData } = context;

  return (
    <>
      <ErrorBoundary name="product-name-and-brand">
        <StyledProductName itemProp="name">
          {productName}
          &nbsp;
        </StyledProductName>
        <StyledBrandandVendor>
          <StyledBrand itemScope itemProp="brand" itemType="http://schema.org/Brand">
            {brand}
          </StyledBrand>
          {isInternalUser() && <StyledVendor>{vendor}</StyledVendor>}
        </StyledBrandandVendor>
      </ErrorBoundary>
      <StyledReviewsQASummary>
        <ErrorBoundary name="reviews-qa-summary">
          <StyledReviewSummaryBox
            data-bv-show="inline_rating"
            data-bv-product-id={PartnerProductId}
            onClick={scrollToCustomerReviews}
            role="link"
            tabIndex={0}
          />
          {ratingsData && (
            <StyledQASummary>
              <StyledLink href="#questions-and-answers" onClick={scrollToQuestionsAndAnswers}>
                {`Questions & Answers (${ratingsData.totalQuestionCount || 0})`}
              </StyledLink>
            </StyledQASummary>
          )}
        </ErrorBoundary>
      </StyledReviewsQASummary>
      <section id="zone-a-promo" />
      <StyledDescription id="zone-a-description">
        <ErrorBoundary name="zone-a-description">
          <ReactMarkdown>{attributes['Short Description']}</ReactMarkdown>
          <StyledLink href="#product-overview" onClick={scrollToProductOverview}>
            Read More.
          </StyledLink>
        </ErrorBoundary>
      </StyledDescription>
      <StyledHighlights id="zone-a-highlights">
        <ErrorBoundary name="zone-a-highlights">
          <ul>
            <li>
              <StyledHighlightIcon>
                <Icon name="shipping" size="original" />
              </StyledHighlightIcon>
              Free Shipping
            </li>
            <li>
              <StyledHighlightIcon>
                <Icon name="guaranteed-to-fit" size="auto" />
              </StyledHighlightIcon>
              <StyledLink href="#product-overview" onClick={showGuaranteedToFit}>
                Guaranteed to Fit!
              </StyledLink>
            </li>
            {estimatedDeliveryDate && (
              <li>
                <StyledHighlightIcon>
                  <Icon name="tools" size="original" />
                </StyledHighlightIcon>
                <div>
                  <span>Estimated Delivery Date: </span>
                  <strong>
                    <span data-id="estimatedDeliveryDate">
                      {estimatedDeliveryDate}
                    &nbsp;
                    </span>
                  </strong>
                </div>
              </li>
            )}
            <li>
              <StyledHighlightIcon phone>
                <MdPhoneIphone />
              </StyledHighlightIcon>
              <div>
                Call&nbsp;
                <StyledLink href={`tel:${contactNumber}`}>{contactNumber}</StyledLink>
              &nbsp;for Free Expert Help
              </div>
            </li>
          </ul>
        </ErrorBoundary>
      </StyledHighlights>
      <StyledPrice id="zone-a-price" ref={priceRef}>
        <ErrorBoundary name="zone-a-price">
          <ProductPrice vm={vm} showSuffix />
        </ErrorBoundary>
      </StyledPrice>
      <StyledConsumerCardContainer>
        <ErrorBoundary name="zone-a-ccm">
          <ConsumerCardMessaging
            itemId={PartnerProductId}
            clsRemediation={{
              placeholders: true,
              preservePlaceholders: true
            }}
            isB2B={getIsB2B()}
          />
        </ErrorBoundary>
      </StyledConsumerCardContainer>
      <StyledMeasurements id="zone-a-measurements">
        <ErrorBoundary name="zone-a-measurements">
          <StyledWidth>
            <div>
              <Dropdown
                floatingLabel={false}
                id="widthWholes"
                label="Window Width (in.)"
                onChange={(event) => {
                  updateDimensions(event);
                  getProductPrice();
                }}
                options={dimensionsOptions.width.wholes}
                value={widthWholes}
                disabled={isMandI}
              />
            </div>
            <StyledEighths>
              <Dropdown
                floatingLabel={false}
                id="widthEighths"
                label="Window Width (eighths)"
                onChange={(event) => {
                  updateDimensions(event);
                  getProductPrice();
                }}
                options={dimensionsOptions.width.eighths}
                value={widthEighths}
                disabled={isMandI}
              />
            </StyledEighths>
          </StyledWidth>
          <StyledHeight>
            <div>
              <Dropdown
                floatingLabel={false}
                id="heightWholes"
                label="Window Height (in.)"
                onChange={(event) => {
                  updateDimensions(event);
                  getProductPrice();
                }}
                options={dimensionsOptions.height.wholes}
                value={heightWholes}
                disabled={isMandI}
              />
            </div>
            <StyledEighths>
              <Dropdown
                floatingLabel={false}
                id="heightEighths"
                label="Window Height (eighths)"
                onChange={(event) => {
                  updateDimensions(event);
                  getProductPrice();
                }}
                options={dimensionsOptions.height.eighths}
                value={heightEighths}
                disabled={isMandI}
              />
            </StyledEighths>
          </StyledHeight>
        </ErrorBoundary>
      </StyledMeasurements>
      {!isMandI && isProfessionallyInstallable && (
        <>
          <ErrorBoundary name="how-to-get-it">
            <HowToGetIt />
          </ErrorBoundary>
          <ErrorBoundary name="product-installation-cta">
            <ProductInstallationCta mandIPhoneNumber={mandIPhoneNumber} />
          </ErrorBoundary>
        </>
      )}
      <section id="zone-a-cta">
        <ErrorBoundary name="zone-a-cta">
          <StyledCustomizeAndBuyButton
            label={ctaButtonLabel}
            color="primary"
            onClick={() => routeToLocation(configuratorUrl)}
          />
          <StyledCTANoShipping>
            <Icon name="no-ship-to-store" />
            <Icon name="no-ship-to-home" />
            <p>We&apos;re unable to ship this item to homes and stores in: AK,GU,HI,PR,VI</p>
          </StyledCTANoShipping>
        </ErrorBoundary>
      </section>
    </>
  );
});

ProductSummary.dataModel = extend(ConsumerCardMessaging);
export default ProductSummary;
