import React, { useRef, useEffect, useCallback } from 'react';
import './customer-image-overlay.scss';
import {
  string, func, bool, instanceOf, shape
} from 'prop-types';
import { Rating as RatingComponent } from '@thd-olt-component-react/rating';
import { Row, Col, Image } from '@thd-olt-component-react/core-ui';
import { MainImage } from './MainImage';
import ArrowButtons from './ArrowButtons';
import { useImageOverlay } from '../hooks/useImageOverlay';

const interactionTypes = {
  CLICK_NEXT: 'main-img-click-next',
  CLICK_PREVIOUS: 'main-img-click-previous',
  SWIPE_NEXT: 'main-img-swipe-next',
  SWIPE_PREVIOUS: 'main-img-swipe-previous',
  THUMBNAIL_CLICK: 'thumbnail-img-click',
};

const CustomerImageOverlay = (props) => {
  const {
    photos, selectedPhotoID, loopPhotos, channel, onClose, onInteraction, isQuestionsAndAnswerPhotos, question
  } = props;

  const {
    hasNextImage,
    hasPreviousImage,
    selected,
    selectImageByID,
    selectNextImage,
    selectPreviousImage,
    totalImages,
  } = useImageOverlay(photos, selectedPhotoID, loopPhotos, isQuestionsAndAnswerPhotos, question);

  const { review, photo, imageNumber } = selected;
  const {
    Title, UserNickname, SubmissionTime, ReviewText, Rating, Photos, Question
  } = review;
  const { Sizes: { normal: { Url } }, Id } = photo;
  const parsedDate = new Date(Date.parse(SubmissionTime));
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const displayDate = parsedDate.toLocaleDateString('en-US', options);
  const reviewPhotos = Photos.map((picture) => {
    return {
      photoId: picture.Id,
      url: picture.Sizes.thumbnail.Url
    };
  });

  const thumbnailsRef = useRef();

  const scrollIntoView = () => {
    thumbnailsRef?.current?.querySelector?.('.highlighted-image')?.scrollIntoView?.();
  };
  const interactionHandler = (interactionType) => {
    try {
      onInteraction(interactionType);
      // eslint-disable-next-line no-empty
    } catch (error) {
      // TODO: what should happen if we get an error here?
    }
  };
  const previousButtonClickHandler = () => {
    selectPreviousImage();
    interactionHandler(interactionTypes.CLICK_PREVIOUS);
  };

  const nextButtonClickHandler = () => {
    selectNextImage();
    interactionHandler(interactionTypes.CLICK_NEXT);
  };

  const navigateWithKeyboard = useCallback((event) => {
    const { keyCode } = event;
    if (keyCode === 37) {
      previousButtonClickHandler();
    } else if (keyCode === 39) {
      nextButtonClickHandler();
    }
  });

  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle?.trigger('customer-image-overlay.ready');
  }, []);

  useEffect(() => {
    if (channel === 'desktop') {
      scrollIntoView();
    }
  });

  useEffect(() => {
    document.addEventListener('keydown', navigateWithKeyboard);
    return () => {
      document.removeEventListener('keydown', navigateWithKeyboard);
    };
  }, [navigateWithKeyboard]);

  return (
    <div
      className="customer-image-overlay"
      ref={thumbnailsRef}
      data-testid="main-container"
      data-component="CustomerImageOverlay"
    >
      <Row>
        <Col>
          <span className="customer-image-overlay__header">
            Customer Images
          </span>
          <span className="customer-image-overlay__ratio">
            ({imageNumber} of {totalImages})
          </span>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              onClose(event);
              window.history.back();
            }}
            className="close-button"
          >
            Close
            <button type="button">
              <Image
                className="close-button__icon"
                src="https://assets.homedepot-static.com/images/v1/close-symbol-orange.svg"
                alt="Close"
                height="1"
                width="1"
              />
            </button>
          </a>
        </Col>
      </Row>
      <Row>
        <Col xs={6} nopadding className="main-image">
          {hasPreviousImage && (
            <ArrowButtons
              className="arrow"
              direction="left"
              onClick={previousButtonClickHandler}
            />
          )}
          {hasNextImage && (
            <ArrowButtons
              className="arrow"
              direction="right"
              onClick={nextButtonClickHandler}
            />
          )}
          <MainImage
            photoUrl={Url}
            getNextImage={selectNextImage}
            getPreviousImage={selectPreviousImage}
            interactionTypes={interactionTypes}
            interactionHandler={interactionHandler}
          />
        </Col>
        <Col xs={6}>
          <Col flatten className="customer-image-overlay__review-specifics">
            <Col className="customer-image-overlay__review-specifics-container">
              <Col flatten className="review-title">
                {Title && Title}
                {Question && <><b>Q:</b> {Question}</>}
              </Col>
              <Col style={{ display: 'flex', flexFlow: 'column' }}>
                {Rating && (<RatingComponent value={Rating} />)}
                {UserNickname && displayDate
                  && (
                    <Col nopadding className="user-and-date">
                      by
                      <span style={{ fontWeight: 'bold' }}> {UserNickname} </span>
                      on
                      <span style={{ fontWeight: 'bold' }}> {displayDate} </span>
                    </Col>
                  )}
              </Col>
              <Col className="review-text">
                {ReviewText}
              </Col>
            </Col>
            <Col className="images-collection-container">
              <br />
              <Col className="images-title ">
                Images in This Review
              </Col>
              <br />
              <Row className="images-thumbnail-collection">
                {
                  reviewPhotos.map(({ photoId, url }) => {
                    return (
                      <div
                        key={photoId}
                        data-testid={photoId}
                        className={Id === photoId ? 'highlighted-image' : 'image-thumbnail'}
                      >
                        <button
                          type="button"
                          data-testid={`btn-${photoId}`}
                          onClick={
                            () => {
                              selectImageByID(photoId);
                              interactionHandler(interactionTypes.THUMBNAIL_CLICK);
                            }
                          }
                        >
                          <Image
                            className="image-thumbnail-img stretchy"
                            src={url}
                            alt="thumbnail"
                            height="1"
                            width="1"
                          />
                        </button>
                      </div>
                    );
                  })
                }
              </Row>
            </Col>
          </Col>
        </Col>
      </Row>
    </div>
  );
};

CustomerImageOverlay.propTypes = {
  channel: string,
  onClose: func.isRequired,
  photos: instanceOf(Object),
  selectedPhotoID: string,
  loopPhotos: bool,
  onInteraction: func,
  isQuestionsAndAnswerPhotos: bool,
  question: string
};

CustomerImageOverlay.defaultProps = {
  channel: 'desktop',
  loopPhotos: false,
  photos: {},
  selectedPhotoID: null,
  onInteraction: () => {},
  isQuestionsAndAnswerPhotos: false,
  question: ''
};

export { CustomerImageOverlay, interactionTypes };
