// import React from 'react';
// import { SvgIcon } from '../SvgIcon';

// function createSvgIcon(path, displayName) {
//   const Component = (props, ref) => (
//     <SvgIcon data-testid={`${displayName}Icon`} ref={ref} {...props}>
//       {path}
//     </SvgIcon>
//   );

//   Component.displayName = displayName;
//   return React.forwardRef(Component);
// }

// export default createSvgIcon;
import createSvgIcon from '@one-thd/sui-atomic-components/dist/utils/createSvgIcon';

export default createSvgIcon;