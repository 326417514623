import React from 'react';
import PropTypes from 'prop-types';
import SmoothScroll from './SmoothScroll.component';

const Link = (props) => {
  const { href, children, className } = props;
  return (
    <li className={`list__item--type-inline ${className}`}>
      <SmoothScroll target={href} className="u__default-link">
        {children}
      </SmoothScroll>
    </li>
  );
};

Link.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  /** class modifiers. */
  className: PropTypes.string,
};
Link.defaultProps = {
  className: null,
  href: null,
  children: null
};

export default Link;
