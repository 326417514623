import { Button, RatioBox, styled, ty } from '@gcc/autobahn-components';
import { MdZoomIn } from '@react-icons/all-files/md/MdZoomIn';

export const StyledRatioBox = styled(RatioBox)`
  ${ty`mb2`}
`;

export const StyledOptionsSectionsChoiceName = styled.div`
  ${ty`flex items-center justify-center flex-auto lh-solid f7 mb2 ph2`}
`;

export const StyledOptionsSectionsChoice = styled.li`
  ${ty`flex flex-column relative`}
`;

export const StyledOptionsSectionsChoicePrice = styled.div`
  ${ty`b lh-solid pv1 near-black`}
`;

export const StyledOptionsSectionsChoiceNotAvailable = styled.div`
  ${ty`bg-white red f7 lh-solid pa1`}
`;

export const StyledOptionsSectionsChoiceBox = styled.div`
  ${ty`
    flex-auto ba br2 overflow-hidden justify-between flex flex-column relative
    ${({ selected }) => (selected ? 'b--orange' : 'b--gray')}
  `}
`;

export const StyledOptionsSectionsChoiceBoxSelected = styled.div`
  ${ty`
    w3 h3 bg-orange white flex items-center justify-center absolute z-1
    ${({ selected }) => (selected ? 'o-100' : 'o-0')}
  `}
  transform: rotate(45deg);
  font-size: 0.8rem;
  top: -2.5rem;
  right: -2.5rem;
  align-items: flex-end;
  justify-content: center;
  & svg {
    padding-bottom: 0.2rem;
    transform: rotate(-45deg);
    & path {
      stroke: #fff;
      stroke-width: 3px;
    }
  }
`;

export const StyledSampleButton = styled(Button)`
  ${ty`justify-center f7 f5-ns nowrap mt2`}
`;

export const StyledZoomInPreview = styled.div`
  ${ty`z-3 ba b--mid-gray absolute top--2`}
  ${({ imageUrl, previewSize }) => `
    background-image: url('${imageUrl}');
    width: ${previewSize}px;
    height: ${previewSize}px;
  `}
  &.left {
    left: -18.5rem;
  }
  &.right {
    left: 9.5rem;
  }
`;

export const StyledZoomInSwatch = styled(MdZoomIn)`
  ${ty`absolute right-0 bottom-0 f4 pa2`}
`;

export const StyledOriginalPrice = styled.span`
${ty`strike`}
`;

export const StyledSavingsInline = styled.span`
${ty`b`}
color: #00873C
`;

export const StyledSavingsDiv = styled.div`
${ty`b`}
color: #00873C
`;

export const StyledPrice = styled.div`
${ty`b black`}
`;