export const formatNumberWithCommas = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatDate = (date) => {
  if (!date) {
    return date;
  }
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const dateObj = new Date(Date.parse(date));
  return `${months[dateObj.getMonth()]} ${dateObj.getDate()}, ${dateObj.getFullYear()}`;
};

export const getNextPage = (TotalResults, Offset, Limit) => {
  if (!TotalResults || TotalResults === 0) return -1;

  return Offset + Limit > TotalResults
    ? -1
    : Math.ceil((Offset + Limit) / Limit) + 1;
};

export const getPrevPage = (TotalResults, Offset, Limit) => {
  if (!TotalResults || TotalResults === 0) return -1;

  return Offset === 0 || Offset > TotalResults
    ? -1
    : Math.ceil((Offset + Limit) / Limit) - 1;
};