import React, { useContext, useState } from 'react';
import { shape } from 'prop-types';
import { Overlay } from '@thd-olt-component-react/core-ui';
import { formatDate } from '../../../helpers/QAHelper';
import { UserProfile } from '../../UserProfile/UserProfile';
// import { triggerAnalytics } from '../../../Analytics';
import QAContext from '../../../context/QAContext';
import { getHighlightedText } from '../helpers/getHighlightedText';

export const QuestionContainer = ({ result }) => {
  const {
    UserNickname,
    SubmissionId,
    SubmissionTime,
    QuestionSummary,
    AnswerIds,
    Id,
    AuthorId
  } = result;
  const { searchText } = useContext(QAContext);
  const totalAnswerCount = AnswerIds?.length;
  const totalAnswersText = totalAnswerCount + (totalAnswerCount > 1 ? ' Answers' : ' Answer');

  const question = QuestionSummary || '';

  const [showUserOverlay, setShowUserOverlay] = useState(false);

  const handleUserOverlay = (evt) => {
    evt.stopPropagation();
    setShowUserOverlay(!showUserOverlay);
  };

  // const handleOnClick = () => {
  //   launchUserProfile(SubmissionId);
  //   const payload = { component: 'profile' };
  //   triggerAnalytics(payload);
  // };

  const handleOverlayEvent = (evt) => {
    evt.stopPropagation();
  };

  return (
    <>
      <div className="qa-title_wrapper">
        <div className="qa-title">
          <div className="summary-wrapper">
            <span className="qa-title--bold">Q:</span>
            <span className="qa-title_summary">{getHighlightedText(Id, question, searchText)}</span>
          </div>
          <div className="qa-title_info">
            <span>by</span>
            <button type="button" onClick={(evt) => handleUserOverlay(evt)} className="qa-result__answer_user">
              {UserNickname}
            </button>
            {/* <span className="qa-result__answer_user">{UserNickname}</span> */}
            <span className="date-divider">|</span>
            <span>{formatDate(SubmissionTime)}</span>
          </div>
        </div>
        <div className="qa-title_right">
          <div className="qa-title_answer-count">{totalAnswersText}</div>
        </div>
      </div>
      <div onClick={(evt) => handleOverlayEvent(evt)} role="button" tabIndex={0}>
        <Overlay
          onClose={() => setShowUserOverlay(null)}
          open={showUserOverlay}
          positionedCloseButton
          closeButton
          medium
        >
          <UserProfile
            showUserOverlay={showUserOverlay}
            userNickname={UserNickname}
            authorId={AuthorId}
          />
        </Overlay>
      </div>
    </>
  );
};

QuestionContainer.displayName = 'QuestionContainer';

QuestionContainer.propTypes = {
  result: shape({}),
};

QuestionContainer.defaultProps = {
  result: null,
};
