import React from 'react';

const Top = () => (
  /* eslint-disable-next-line */
  <svg width="45px" height="45px" viewBox="0 0 45 45" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Mobile" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="sticky-header" transform="translate(-330.000000, -1.000000)">
        <g id="Group-32" transform="translate(0.000000, 1.000000)">
          <g id="Group" transform="translate(330.000000, 0.000000)">
            <rect id="Rectangle" fill="#F96302" x="0" y="0" width="45" height="45" />
            <g id="Arrow/Up" transform="translate(12.000000, 9.000000)" fill="#FFFFFF">
              {/* eslint-disable-next-line */}
              <polygon id="Path-2" transform="translate(10.000000, 5.000000) scale(1, -1) translate(-10.000000, -5.000000) " points="2 1.96225371 3.85418686 0 9.98302583 6.23234166 16.1928341 0 18 1.96225371 9.98302583 10" />
              <text id="TOP" fontFamily="HelveticaNeue-Bold, Helvetica Neue" fontSize="11" fontWeight="bold">
                <tspan x="0" y="24">TOP</tspan>
              </text>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Top;
