class View {
  constructor(config) {
    if (config) {
      this.Scope = config.Scope;
      this.Name = config.Name;
      this.Version = config.Version;
      this.Path = config.Path;
      this.Component = config.Component;
    }
  }

  /**
   * @configurable true
   * @category View
   * @description The version of the micro app.
   * @type {string}
   * @member
   */
  Scope;

  /**
   * @configurable true
   * @category View
   * @description The name of the micro app.
   * @type {string}
   * @member
   */
  Name;

  /**
   * @configurable true
   * @category View
   * @description The version of the micro app.
   * @type {string}
   * @member
   */
  Version;

  /**
   * @configurable true
   * @category View
   * @description Path to define an override to the micro apps location.
   * @type {string}
   * @member
   */
  Path;

  Component;
}

export default View;
