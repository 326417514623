import { observer } from 'mobx-react-lite';
import { shape } from 'prop-types';
import React from 'react';

import ProductOptionsView from './components/product-options/product-options.view';

const App = observer((props) => {
  const { micro, vm } = props;
  return (
    <ProductOptionsView micro={micro} vm={vm} />
  );
});

App.propTypes = {
  micro: shape({}).isRequired,
  theme: shape({}).isRequired,
};

export default App;
