import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Badge } from '@thd-olt-component-react/core-ui';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { Promotion } from '@thd-olt-component-react/promotion';
import PriceFormat from './PriceFormat';
import Rebates from './Rebates';
import InstantRebates from './InstantRebates';
import helpers, { PRICE_TYPES } from '../../helpers/price-helper';
import '../../styles/price-detailed.style.scss';

class PriceDetailed extends Component {
  // BRIO Skus
  handleBRIOSku = () => {
    return (
      <div className="brio__wrapper">
        <div className="brio__quote-message-wrapper">
          <span className="brio__quote-message">Get a quote during a FREE in-home consultation</span>
        </div>
        <div className="brio__how-to-buy">How to Buy</div>
        <div className="brio__how-to-buy-details">
          {`During your free, in-home consultation one of our professional contractors will assess your home, discuss
          product and extended service options that best fit your needs and budget. Our expert will review financing
          options and provide a total project cost estimate. Your quote will include product, labor, and any
          additional parts or extended service options. Product availability may be limited.`}
        </div>
        <span className="brio__icon schedule_furnish_install_logo">Schedule Furnish Install Logo</span>
      </div>
    );
  }

  // HIDE_PRICE/LIVE_GOODS
  handleHidePrice = () => {
    return (<div className="price-detailed__contact-message">Contact your local store for price</div>);
  }

  // Limit per order with strike through
  handleLimitPerOrder({
    price, wasPrice, priceBadge, uom, save, promotionDetails, hasEcoRebates
  }) {
    const {
      product,
      displayEachUom,
      zipCode,
      storeId,
      channel,
      hideBadge,
      hidePromotions,
      hideConditionalPromoDescription,
      hideLimitPerOrder,
      hideSavingsText,
      hideRebates
    } = this.props;
    const { pricing } = product;
    const priceToDisplay = wasPrice ? wasPrice.special : price;
    if (!!pricing.quantityLimit) {
      return (
        <div>
          <div className="price-detailed__qty-limit-wrapper">
            <PriceFormat
              price={priceToDisplay}
              priceBadge={priceBadge}
              isSmallBadge={false}
              isSmallSize={false}
              hideBadge={hideBadge}
            />
            <div>
              {!hideLimitPerOrder && (
                <div className="price-detailed__qty-limit"> Limit {pricing.quantityLimit} per order</div>
              )}
              <div className="price-detailed__was-price">
                <div>
                  {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
                  {wasPrice && <span className="u__strike">{helpers.handlePriceParsing(wasPrice.original, true)}</span>}
                </div>
                {(!hideSavingsText && save) && (
                  <div className="u__text--success">Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)</div>
                )}
              </div>
            </div>
          </div>
          {(promotionDetails && !hidePromotions) &&
            <div className="price-detailed__promotion">
              <Promotion
                channel={channel}
                storeId={storeId}
                itemId={pricing.itemId}
                hideConditionalPromoDescription={hideConditionalPromoDescription}
              />
            </div>
          }
          {(!hideRebates && hasEcoRebates) && (
            <Rebates
              itemId={pricing.itemId}
              zipCode={zipCode}
              optionalText="Get up to {{ totalAmount ? totalAmount + ' in ' : offerCount }} Rebate{{ totalAmount || offerCount > 1 ? 's' : ''}} for {{ zipCode }}" // eslint-disable-line
              rebateClasses="price__ecorebates"
              multi
            />
          )}
        </div>
      );
    }
    return '';
  }

  // Unit and case price: regular,
  // with strike through, with bulk price, with bulk price and strike through
  handleUnitAndCasePrice = ({
    price, alternatePrice, alternateUom, uom, wasPrice,
    save, bulkPrice, bulkPriceThresholdQty, priceBadge,
    unitWasPrice, promotionDetails, hasEcoRebates
  }) => {
    const {
      product,
      displayEachUom,
      zipCode,
      storeId,
      channel,
      hideBadge,
      hidePromotions,
      hideBulkPrice,
      hideConditionalPromoDescription,
      hideLimitPerOrder,
      hideSavingsText
    } = this.props;
    const { pricing } = product;
    const priceTemplate = helpers.handlePriceParsing(price, false);
    const coveragePriceTemplate = helpers.handlePriceParsing(price, true);
    const altPriceTemplate = helpers.handlePriceParsing(alternatePrice, false);
    const altPrice = alternatePrice.toString();
    const altUom = (alternateUom && alternateUom !== 'each') ? alternateUom : uom;
    const { unitOriginalPrice, unitSpecialPrice } = pricing;
    const pricePerUnit = unitOriginalPrice ? unitOriginalPrice : unitSpecialPrice;
    const rightPriceClass = classNames({
      'price-detailed__right-price': bulkPrice && !hideBadge
    });
    const rightPriceRowClass = classNames({
      'price-detailed__right-price-row': bulkPrice && !hideBulkPrice
    });
    const leftPriceRowClass = classNames({
      'price-detailed__left-price-row': !bulkPrice
    });
    const rightPriceClassForIncludes = classNames({
      'price-detailed__right-price': priceBadge
    });
    const savePriceClass = classNames({
      'price-detailed__save': bulkPrice && !hideBulkPrice
    });
    if (pricePerUnit) {
      if (uom !== 'sq. ft.') {
        return (
          <div className="unit-case-price-includes">
            {pricing.unitsPerCase > 1 && <div className="price-detailed__unit-cover">{pricing.unitOfMeasureCoverage} ({coveragePriceTemplate} {uom && helpers.parseUom(uom, displayEachUom)})</div>}
            {!bulkPrice &&
              <div className="price-detailed__right-price-wrapper">
                {priceBadge && <div className={`value-price-badge__${priceBadge} price-detailed__unit-badge`} />}
                <div className={rightPriceClassForIncludes}>
                  <span className="price-detailed__unit-price">{altPriceTemplate}</span><span> {alternateUom && helpers.parseUom(alternateUom, displayEachUom)} </span>
                  {wasPrice && <span className="u__strike">{helpers.handlePriceParsing(wasPrice.original, true)}</span>}
                  {!hideSavingsText && save && <div className="u__text--success">Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)</div>}
                </div>
              </div>
            }
            {(bulkPrice && !hideBulkPrice) &&
              <div className="price-detailed__right-price-wrapper">
                {priceBadge && <div className={`value-price-badge__${priceBadge} price-detailed__unit-badge`} />}
                <div className="price-detailed__right-price">
                  <div>
                    <div className="price-detailed__unit-price price-detailed__alt-price">{altPriceTemplate}</div>
                    {!wasPrice &&
                      <span> {alternateUom &&
                        helpers.parseUom(alternateUom, displayEachUom)}
                      </span>
                    }
                    {wasPrice &&
                      <div className="price-detailed__unit-bulk-price">
                        <span>{alternateUom &&
                          helpers.parseUom(alternateUom, displayEachUom)}
                        </span>
                        <span className="u__strike">{helpers.handlePriceParsing(wasPrice.original, true)}</span>
                        {(!hideSavingsText && save) && <div className="u__text--success">Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)</div>}
                      </div>
                    }
                  </div>
                  <div className="u__text--success">
                    Buy <span className="u__bold">{bulkPriceThresholdQty}</span> or more <span className="u__bold">{helpers.handlePriceParsing(bulkPrice, true)}</span><span> {alternateUom && helpers.parseUom(alternateUom, displayEachUom)}</span>
                  </div>
                </div>
              </div>
            }
            {promotionDetails && !hidePromotions &&
              <div>
                <Promotion
                  channel={channel}
                  storeId={storeId}
                  itemId={pricing.itemId}
                  hideConditionalPromoDescription={hideConditionalPromoDescription}
                />
              </div>
            }
            {hasEcoRebates && (
              <Rebates
                itemId={pricing.itemId}
                zipCode={zipCode}
                optionalText="Get up to {{ totalAmount ? totalAmount + ' in ' : offerCount }} Rebate{{ totalAmount || offerCount > 1 ? 's' : ''}} for {{ zipCode }}" // eslint-disable-line
                multi={false}
              />
            )}
          </div>
        );
      } else if (uom === 'sq. ft.') {
        return (
          <>
            <div className="price-detailed__unit-case-price">
              <div className={`price-detailed__left-price-wrapper ${leftPriceRowClass}`}>
                {(!bulkPrice && priceBadge && !hideBadge) && <div className={`value-price-badge__${priceBadge} price-detailed__unit-badge`} />}
                <div>
                  {price && <span className="price-detailed__unit-price">{priceTemplate}</span>}
                  <span> {uom && helpers.parseUom(uom, displayEachUom)} </span>
                  {unitWasPrice && <span className="u__strike">{helpers.handlePriceParsing(unitWasPrice.original, true)}</span>}
                  {(!hideSavingsText && save) && <div className={`u__text--success ${savePriceClass}`}>Save {save.percentageOff}%</div>}
                </div>
              </div>
              <div className={`price-detailed__right-price-wrapper ${rightPriceRowClass}`}>
                <div className="price-detailed__unit-cover">{pricing.unitOfMeasureCoverage}</div>
                <>
                  {(bulkPrice && priceBadge && !hideBadge) && <div className={`value-price-badge__${priceBadge} price-detailed__unit-badge-bulk`} />}
                  <div className={rightPriceClass}>
                    <span className="price-detailed__unit-price">{altPriceTemplate}</span><span> {alternateUom && helpers.parseUom(alternateUom, displayEachUom)}</span>
                    {(bulkPrice && !hideBulkPrice) && (
                      <div className="u__text--success">
                        Buy <span className="u__bold">{bulkPriceThresholdQty}</span> or more <span className="u__bold">{helpers.handlePriceParsing(bulkPrice, true)}</span><span> {alternateUom && helpers.parseUom(alternateUom, displayEachUom)}</span>
                      </div>
                    )}
                  </div>
                </>
              </div>
            </div>
            {promotionDetails && !hidePromotions &&
              <div>
                <Promotion
                  channel={channel}
                  storeId={storeId}
                  itemId={pricing.itemId}
                  hideConditionalPromoDescription={hideConditionalPromoDescription}
                />
              </div>
            }
            {hasEcoRebates && (
              <Rebates
                itemId={pricing.itemId}
                zipCode={zipCode}
                optionalText="Get up to {{ totalAmount ? totalAmount + ' in ' : offerCount }} Rebate{{ totalAmount || offerCount > 1 ? 's' : ''}} for {{ zipCode }}" // eslint-disable-line
                multi={false}
              />
            )}
          </>
        );
      }
    }
    return (
      <div id="unit-price">
        <PriceFormat
          price={altPrice}
          priceBadge={priceBadge}
          isSmallBadge={false}
          isSmallSize={false}
          hideBadge={hideBadge}
        />
        {!wasPrice &&
          <div className="price-detailed__was-price">
            <span className="priceFormat__unit-alignment">{altUom && helpers.parseUom(altUom, displayEachUom)} </span>
            {(bulkPrice && !hideBulkPrice) && (
              <div className="u__text--success">
                Buy <span className="u__bold">{bulkPriceThresholdQty}</span> or more <span className="u__bold">{helpers.handlePriceParsing(bulkPrice, true)}</span><span> {alternateUom && helpers.parseUom(alternateUom, displayEachUom)}</span>
              </div>
            )}
          </div>
        }
        {wasPrice &&
          <div className="price-detailed__was-price">
            <span className="priceFormat__unit-alignment">{altUom && helpers.parseUom(altUom, displayEachUom)} </span>
            <span className="u__strike">{helpers.handlePriceParsing(wasPrice.original, true)}</span>
            {(!hideSavingsText && save) && <span className="u__text--success"> Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)</span>}
            {(bulkPrice && !hideBulkPrice) && (
              <div className="u__text--success">
                Buy <span className="u__bold">{bulkPriceThresholdQty}</span> or more <span className="u__bold">{helpers.handlePriceParsing(bulkPrice, true)}</span><span> {alternateUom && helpers.parseUom(alternateUom, displayEachUom)}</span>
              </div>
            )}
          </div>
        }
        {(!!pricing.quantityLimit && !hideLimitPerOrder) && (
          <div className="price-detailed__qty-limit"> Limit {pricing.quantityLimit} per order</div>
        )}
        {(promotionDetails && !hidePromotions) &&
          <div className="price-detailed__promotion">
            <Promotion
              channel={channel}
              storeId={storeId}
              itemId={pricing.itemId}
              hideConditionalPromoDescription={hideConditionalPromoDescription}
            />
          </div>
        }
        {hasEcoRebates && (
          <Rebates
            itemId={pricing.itemId}
            zipCode={zipCode}
            optionalText="Get up to {{ totalAmount ? totalAmount + ' in ' : offerCount }} Rebate{{ totalAmount || offerCount > 1 ? 's' : ''}} for {{ zipCode }}" // eslint-disable-line
            multi={false}
          />
        )}
      </div>
    );
  }

  // Was price
  handleWasPrice = ({
    wasPrice, save, priceBadge, uom, promotionDetails
  }) => {
    const {
      displayEachUom,
      channel,
      storeId,
      product,
      hideBadge,
      hidePromotions,
      hideConditionalPromoDescription,
      hideSavingsText
    } = this.props;
    const { pricing } = product;
    return (
      <div id="was-price">
        <PriceFormat
          price={wasPrice.special}
          priceBadge={priceBadge}
          isSmallBadge={false}
          isSmallSize={false}
          hideBadge={hideBadge}
        />
        <div className="price-detailed__was-price">
          {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
          {wasPrice && <span className="u__strike">{helpers.handlePriceParsing(wasPrice.original, true)}</span>}
          {(!hideSavingsText && save) && <div className="u__text--success">Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)</div>}
        </div>
        {(promotionDetails && !hidePromotions) &&
          <div>
            <Promotion
              channel={channel}
              storeId={storeId}
              itemId={pricing.itemId}
              hideConditionalPromoDescription={hideConditionalPromoDescription}
            />
          </div>
        }
      </div>
    );
  }

  // Map price
  handleMapPrice = ({
    mapPrice, wasPrice, uom, priceBadge, promotionDetails, pricingMessage
  }) => {
    const tooltipContent = 'We\'ve set this item\'s price lower than the manufacturer will let us advertise, so you\'ll have to add it to your cart to see our price. If you decide you don\'t want to purchase the item, you can remove it from your cart.';
    const hasLowerPrice = /See Low Price in Cart|See Lower Price in Cart/.test(pricingMessage);

    let priceToDisplay = '';
    if (mapPrice) {
      priceToDisplay = mapPrice.original;
    } else if (wasPrice) {
      priceToDisplay = wasPrice.original;
    }
    const {
      channel,
      displayEachUom,
      hideBadge,
      storeId,
      product,
      hidePromotions,
      hideConditionalPromoDescription
    } = this.props;
    const { pricing } = product;
    const tooltipId = `price-tooltip-${pricing.itemId}`;
    const priceMsgClass = classNames('u__default-link', {
      'price-detailed__bttn-text-align': !mapPrice && !wasPrice
    });
    const lowPriceType = channel.toLowerCase() === 'mobile' ? 'lower-price-small' : 'lower-price';
    return (
      <div className="price-detailed__map-price">
        {(mapPrice || wasPrice) && (
          <div>
            <span className="price-detailed__message">
              <PriceFormat
                price={priceToDisplay}
                priceBadge={priceBadge}
                isSmallBadge={false}
                isSmallSize={false}
                hideBadge={hideBadge}
                hasStrikeThrough
              />
            </span>
            {uom && <span className="price-detailed__uom">{helpers.parseUom(uom, displayEachUom)} </span>}
          </div>
        )}
        <div className="price-detailed__lower-price-wrapper">
          {(!hasLowerPrice && !hideBadge) && <Badge type={lowPriceType} className="price-detailed__lower-price" />}
          <Tooltip
            channel={channel}
            closeButton
            content={tooltipContent}
            maxWidth={300}
            placement="bottom"
          >
            {/* eslint-disable-next-line */}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                helpers.triggerAnalytics('map-link');
              }}
              id={tooltipId}
              className={priceMsgClass}
            >
              {pricingMessage}
            </a>
          </Tooltip>
        </div>
        {(promotionDetails && !hidePromotions) &&
          <div>
            <Promotion
              channel={channel}
              storeId={storeId}
              itemId={pricing.itemId}
              hideConditionalPromoDescription={hideConditionalPromoDescription}
            />
          </div>
        }
      </div>
    );
  }

  // Bulk price
  handleBulkPrice = ({
    price, wasPrice, bulkPrice, save, uom,
    bulkPriceThresholdQty, priceBadge, promotionDetails
  }) => {
    const {
      displayEachUom,
      channel,
      storeId,
      product,
      hideBadge,
      hidePromotions,
      hideBulkPrice,
      hideConditionalPromoDescription,
      hideSavingsText
    } = this.props;

    const { pricing } = product;
    const priceToDisplay = wasPrice ? wasPrice.special : price;
    return (
      <div id="bulk-price">
        <PriceFormat
          price={priceToDisplay}
          priceBadge={priceBadge}
          isSmallBadge={false}
          isSmallSize={false}
          hideBadge={hideBadge}
        />
        <div className="price-detailed__was-price">
          {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
          {wasPrice && <span className="u__strike">{helpers.handlePriceParsing(wasPrice.original, true)}</span>}
          {(!hideSavingsText && save) && (
            <span className="u__text--success"> Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)</span>
          )}
          {!hideBulkPrice && (
            <div className="u__text--success">
              Buy <span className="u__bold">{bulkPriceThresholdQty}</span> or more <span className="u__bold">{helpers.handlePriceParsing(bulkPrice, true)}</span><span> {uom && helpers.parseUom(uom, displayEachUom)}</span>
            </div>
          )}
        </div>
        {(promotionDetails && !hidePromotions) &&
          <div>
            <Promotion
              channel={channel}
              storeId={storeId}
              itemId={pricing.itemId}
              hideConditionalPromoDescription={hideConditionalPromoDescription}
            />
          </div>
        }
      </div>
    );
  }

  // Instant Rebate
  handleInstantRebate = ({
    price, wasPrice, priceBadge, uom, promotionDetails
  }) => {
    const {
      product,
      displayEachUom,
      channel,
      storeId,
      hideBadge,
      hidePromotions,
      hideConditionalPromoDescription,
      hideRebates
    } = this.props;
    const { pricing } = product;
    const priceToDisplay = wasPrice ? wasPrice.special : price;
    return (
      <div id="instant-rebate" className="price-detailed__instant-rebates">
        <PriceFormat
          price={priceToDisplay}
          priceBadge={priceBadge}
          isSmallBadge={false}
          isSmallSize={false}
          hideBadge={hideBadge}
        />
        <div className="price-detailed__was-price price-detailed__ir-was">
          <div>
            {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
            {wasPrice && <span className="u__strike">{helpers.handlePriceParsing(wasPrice.original, true)}</span>}
          </div>
          {!hideRebates && (
            <InstantRebates
              storePromotion={pricing.storePromotion}
              price={pricing}
              channel={channel}
              hasDetails
            />
          )}
        </div>
        {(promotionDetails && !hidePromotions) &&
          <div>
            <Promotion
              channel={channel}
              storeId={storeId}
              itemId={pricing.itemId}
              hideConditionalPromoDescription={hideConditionalPromoDescription}
            />
          </div>
        }
      </div>
    );
  }

  // Eco Rebate
  handleEcoRebates = ({
    price, priceBadge, uom, wasPrice, save,
    mapPrice, pricingMessage, bulkPrice, bulkPriceThresholdQty,
    promotionDetails
  }) => {
    const {
      product,
      zipCode,
      displayEachUom,
      storeId,
      channel,
      hideBadge,
      hidePromotions,
      hideBulkPrice,
      hideConditionalPromoDescription,
      hideSavingsText,
      hideRebates
    } = this.props;
    const { pricing } = product;
    const priceToDisplay = wasPrice ? wasPrice.special : price;
    return (
      <div id="eco-rebate-price">
        {(bulkPrice && !hideBulkPrice) && this.handleBulkPrice({
          price, priceBadge, uom, wasPrice, save, bulkPrice, bulkPriceThresholdQty
        })}
        {(mapPrice || pricingMessage) && this.handleMapPrice({
          mapPrice, price, priceBadge, uom, pricingMessage
        })}
        {(!mapPrice && !pricingMessage && !bulkPrice) && (
          <>
            <PriceFormat
              price={priceToDisplay}
              priceBadge={priceBadge}
              isSmallBadge={false}
              isSmallSize={false}
              hideBadge={hideBadge}
            />
            <div className="price-detailed__was-price">
              {uom &&
                <span>{uom && helpers.parseUom(uom, displayEachUom)} </span>
              }
              {wasPrice && <span className="u__strike">{helpers.handlePriceParsing(wasPrice.original, true)}</span>}
              {(!hideSavingsText && save) && (
                <div className="u__text--success"> Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)</div>
              )}
            </div>
          </>
        )}
        {(promotionDetails && !hidePromotions) &&
          <div>
            <Promotion
              channel={channel}
              storeId={storeId}
              itemId={pricing.itemId}
              hideConditionalPromoDescription={hideConditionalPromoDescription}
            />
          </div>
        }
        {!hideRebates && (
          <Rebates
            itemId={pricing.itemId}
            zipCode={zipCode}
            optionalText="Get up to {{ totalAmount ? totalAmount + ' in ' : offerCount }} Rebate{{ totalAmount || offerCount > 1 ? 's' : ''}} for {{ zipCode }}" // eslint-disable-line
            multi={false}
          />
        )}
      </div>
    );
  }

  // No details
  handleNoDetailsPrice = ({
    price, uom, wasPrice, save, priceBadge
  }) => {
    const { displayEachUom, hideBadge, hideSavingsText } = this.props;
    return (
      <div id="no-details-price">
        {price && (
          <>
            <PriceFormat
              price={price}
              priceBadge={priceBadge}
              isSmallBadge={false}
              isSmallSize={false}
              hideBadge={hideBadge}
            />
            <div className="price-detailed__was-price">
              {uom && <span>{helpers.parseUom(uom, displayEachUom)} </span>}
              {wasPrice && <span className="u__strike">{helpers.handlePriceParsing(wasPrice.original, true)}</span>}
              {(!hideSavingsText && save) && (
                <div className="u__text--success">
                  Save {helpers.handlePriceParsing(save.dollarOff, true)} ({save.percentageOff}%)
                </div>
              )}
            </div>
          </>
        )}
      </div>
    );
  }

  // Standard price
  handleStandardPrice = ({
    price, priceBadge, uom, promotionDetails
  }) => {
    const {
      displayEachUom,
      channel,
      storeId,
      product,
      hideBadge,
      hidePromotions,
      hideConditionalPromoDescription
    } = this.props;
    const { pricing } = product;
    return (
      <div id="standard-price">
        <PriceFormat
          price={price}
          priceBadge={priceBadge}
          isSmallBadge={false}
          isSmallSize={false}
          hideBadge={hideBadge}
        />
        {uom && <span> {helpers.parseUom(uom, displayEachUom)}</span>}
        {(promotionDetails && !hidePromotions) &&
          <div>
            <Promotion
              channel={channel}
              storeId={storeId}
              itemId={pricing.itemId}
              hideConditionalPromoDescription={hideConditionalPromoDescription}
            />
          </div>
        }
      </div>
    );
  };

  // Range Price
  handleRangePrice = ({
    minPrice, maxPrice, wasMinPrice, wasMaxPrice, percentageOff
  }) => {
    const { hideBadge, hideSavingsText } = this.props;
    return (
      <div id="range-price">
        <div>
          <PriceFormat
            price={minPrice}
            isSmallSize={false}
            isSmallBadge={false}
            hideBadge={hideBadge}
          />
          <span className="u__bold price__range"> - </span>
          <PriceFormat
            price={maxPrice}
            isSmallSize={false}
            isSmallBadge={false}
            hideBadge={hideBadge}
          />
        </div>
        <div>
          <span className="u__strike">
            {wasMinPrice && helpers.handlePriceParsing(wasMinPrice, true)}
            {wasMaxPrice &&
              <span> - {helpers.handlePriceParsing(wasMaxPrice, true)}</span>
            }
          </span>
          {(!hideSavingsText && percentageOff) && (
            <span className="u__text--success"> Save up to {percentageOff}%</span>
          )}
        </div>
      </div>
    );
  };

  renderByType = ({ _price, type }) => {
    switch (type) {
      case PRICE_TYPES.STANDARD:
        return this.handleStandardPrice(_price);
      case PRICE_TYPES.STRIKE_THROUGH:
        return this.handleWasPrice(_price);
      case PRICE_TYPES.MAP_PRICE:
        return this.handleMapPrice(_price);
      case PRICE_TYPES.ECO_REBATE:
        return this.handleEcoRebates(_price);
      case PRICE_TYPES.INSTANT_REBATE:
        return this.handleInstantRebate(_price);
      case PRICE_TYPES.BULK_PRICE:
        return this.handleBulkPrice(_price);
      case PRICE_TYPES.UNIT_AND_CASE:
        return this.handleUnitAndCasePrice(_price);
      case PRICE_TYPES.LIMIT_PER_ORDER:
        return this.handleLimitPerOrder(_price);
      case PRICE_TYPES.BRIO:
        return this.handleBRIOSku();
      case PRICE_TYPES.LIVE_GOODS:
        return this.handleHidePrice();
      case PRICE_TYPES.NO_DETAILS:
        return this.handleNoDetailsPrice(_price);
      case PRICE_TYPES.RANGE_PRICE:
        return this.handleRangePrice(_price);
      default: return null;
    }
  }

  render() {
    return (
      <div className="price-detailed__wrapper">
        {this.renderByType(helpers.getPricingObject(this.props))}
      </div>
    );
  }
}

PriceDetailed.propTypes = {
   /**
   * The type of channel ie; Desktop/Mobile
   */
  channel: PropTypes.string.isRequired,
   /**
   * Disables the range of price
   */
  disableRangePricing: PropTypes.bool,
   /**
   * To show the detail about the product
   */
  product: PropTypes.shape({
    pricing: PropTypes.shape({
      uom: PropTypes.string,
      unitsPerCase: PropTypes.number,
      originalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      specialPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      specialBuyPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      dollarOff: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      percentageOff: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      specialBuySavings: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      specialBuyDollarOff: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      alternatePriceDisplay: PropTypes.bool,
      quantityLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      unitOriginalPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      unitSpecialPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      storePromotion: PropTypes.shape(),
      unitOfMeasureCoverage: PropTypes.string
    })
  }),
   /**
   * To display each OUM
   */
  displayEachUom: PropTypes.bool,
   /**
   * To display the Zipcode
   */
  zipCode: PropTypes.string,
   /**
   * To omit the details of the product
   */
  omitDetails: PropTypes.bool,
   /**
   * To hide the badge
   */
  hideBadge: PropTypes.bool,
   /**
   * To display the store id
   */
  storeId: PropTypes.string,
   /**
   * To hide the promotion details
   */
  hidePromotions: PropTypes.bool,
   /**
   * To hide the bulk price of the products
   */
  hideBulkPrice: PropTypes.bool,
   /**
   * To hide the limit per order
   */
  hideLimitPerOrder: PropTypes.bool,
   /**
   * To hide the saving text
   */
  hideSavingsText: PropTypes.bool,
   /**
   * To hide the rebates
   */
  hideRebates: PropTypes.bool
};

PriceDetailed.defaultProps = {
  disableRangePricing: false,
  displayEachUom: false,
  zipCode: null,
  omitDetails: false,
  hideBadge: false,
  hidePromotions: false,
  hideBulkPrice: false,
  hideLimitPerOrder: false,
  hideSavingsText: false,
  hideRebates: false
};

export default PriceDetailed;
