import { backslashToForwardslash, subscribe } from '@gcc/autobahn-utilities';
import {
  action, computed, makeAutoObservable, observable, runInAction
} from 'mobx';

class ProductPreviewViewModel {
  constructor(rootVm) {
    {
      const data = JSON.parse(window.localStorage.getItem('product-preview'));
      this.parseData(data);
    }
    subscribe('product-preview', (data) => this.parseData(data));

    makeAutoObservable(this, {
      configCTALabel: observable,
      dimensionOptions: computed,
      isEnhancedVisualization: observable,
      isMandi: observable,
      isSwatchSelected: observable,
      parseData: action.bound,
      price: observable,
      primarySwatch: observable,
      productId: observable,
      productName: observable,
      promo: observable,
      rootVm: false,
      samplesInCart: observable,
      samplesInCartText: computed,
      savingsText: observable,
      secondarySwatch: observable,
      setIsEnhancedVisualization: action.bound,
      siteId: observable,
      swatchName: computed,
      swatchUrl: computed,
      swatchesChoiceIds: computed,
      updateForVisualization: action.bound,
      visualizedOptions: observable,
      zoomEnabled: observable,
    });
    this.rootVm = rootVm;
  }

  get swatchesChoiceIds() {
    const swatchSelections = this.rootVm?.productOptionsViewModel?.swatchSelections;
    if (Array.isArray(swatchSelections) && !!swatchSelections.length) {
      return swatchSelections.map((x) => x.choiceId);
    }
    return [];
  }

  configCTALabel = 'Customize and Buy';

  configuratorUrl;

  isEnhancedVisualization = false;

  isSwatchSelected;

  price;

  primarySwatch;

  primarySwatch;

  productId;

  productName;

  promo;

  samplesInCart;

  savingsText;

  secondarySwatch;

  siteId;

  visualizedOptions;

  zoomEnabled = true;

  setPromo = (promo) => {
    this.promo = promo;
  };

  setVisualizedOptions = (visualizedOptions) => {
    this.visualizedOptions = visualizedOptions;
  };

  setIsEnhancedVisualization = (isEnhancedVisualization) => {
    this.isEnhancedVisualization = isEnhancedVisualization;
  };

  setPrimarySwatch = (primarySwatch) => {
    this.primarySwatch = primarySwatch;
    if (this.primarySwatch) this.primarySwatch.mediaUrl = backslashToForwardslash(this.primarySwatch.mediaUrl);
  };

  setSecondarySwatch = (secondarySwatch) => {
    this.secondarySwatch = secondarySwatch;
    if (this.secondarySwatch) this.secondarySwatch.mediaUrl = backslashToForwardslash(this.secondarySwatch.mediaUrl);
  };

  setIsSwatchSelected = (isSwatchSelected) => {
    this.isSwatchSelected = isSwatchSelected;
  };

  setPrice = (price) => {
    this.price = price;
  };

  setSamplesInCart = (samplesInCart) => {
    this.samplesInCart = samplesInCart;
  };

  setProductId = (productId) => {
    this.productId = productId;
  };

  setProductName = (productName) => {
    this.productName = productName;
  };

  setSiteId = (siteId) => {
    this.siteId = siteId;
  };

  setConfigCTALabel = (label) => {
    this.configCTALabel = label;
  };

  setConfiguratorUrl = (url) => {
    this.configuratorUrl = url;
  };

  setIsMandi = (isMandi) => {
    this.isMandi = isMandi;
  };

  get samplesInCartText() {
    return `**${this.samplesInCart}** of **10**`;
  }

  get showSamples() {
    return this.samplesInCart > 0 && !this.isMandi;
  }

  get swatchName() {
    const swatchSelections = this.rootVm?.productOptionsViewModel?.swatchSelections;
    if (Array.isArray(swatchSelections) && !!swatchSelections.length) {
      return swatchSelections[0].choiceName;
    }
    return undefined;
  }

  get swatchUrl() {
    const swatchSelections = this.rootVm?.productOptionsViewModel?.swatchSelections;
    let url;
    if (Array.isArray(swatchSelections) && !!swatchSelections.length) {
      url = this.rootVm?.productOptionsViewModel?.swatchSelections?.[0]?.imageUrl;
    }
    if (!url) return undefined;
    return `${this.rootVm?.baseUrl}${backslashToForwardslash(url)}?width=130&height=130&mode=crop`;
  }

  updateForVisualization(apiData) {
    const { Chain } = apiData?.data;
    if (Chain) this.setIsEnhancedVisualization(true);
    else this.setIsEnhancedVisualization(false);
  }

  parseData(data) {
    if (!data) return;
    const {
      configCTALabel,
      configuratorUrl: pConfiguratorUrl,
      isMandi: pIsMandi,
      price: { originalPrice, percentOff, promoDescription, stackablePercentage } = {},
      productId: pId,
      siteId: sId,
      productName: pName,
      samplesInCart: samplesCount,
      selectedOptions,
    } = data;

    if (pIsMandi) {
      this.setIsMandi(pIsMandi);
    }

    if (Number.isInteger(samplesCount)) {
      this.setSamplesInCart(samplesCount);
    }
    if (pId) {
      this.setProductId(pId);
    }
    if (pName) {
      this.setProductName(pName);
    }
    if (sId) {
      this.setSiteId(sId);
    }
    if (originalPrice) {
      this.setPrice(originalPrice);
    }
    if (percentOff) {
      this.setPromo({ percentOff, stackablePercentage });
    }
    if (promoDescription) {
      this.setSavingsText(promoDescription);
    }

    if (configCTALabel) {
      this.setConfigCTALabel(configCTALabel);
    }

    if (pConfiguratorUrl) {
      this.setConfiguratorUrl(pConfiguratorUrl);
    }

    if (selectedOptions) {
      const primary = selectedOptions.find((swatch) => swatch.type === 'Primary Color');
      const secondary = selectedOptions.find((swatch) => swatch.type === 'Secondary Color');
      const vizOptions = selectedOptions.map((option) => ({
        choiceId: option.choiceId,
        optionId: option.optionId,
        choiceValue: option.choiceValue,
        internalName: option.type,
      }));

      if (selectedOptions.length > 0) {
        this.setPrimarySwatch(primary);
        this.setSecondarySwatch(secondary);
      }
      if (primary) {
        this.setIsSwatchSelected(!!primary);
      } else {
        this.setIsSwatchSelected(false);
      }
      if (secondary) {
        this.setIsSwatchSelected(!!primary);
      }
      this.setVisualizedOptions(vizOptions);
    }
  }

}

export default ProductPreviewViewModel;
