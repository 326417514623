import PluginModel from './plugin.model';
import { CustomMeasureAndInstallExpConfiguration } from '../../../../utility/customMeasureAndInstallExpConfiguration';

class ThdPipModel extends PluginModel {
  constructor(micro) {
    super(micro);

    if (micro.model) {
      const { model } = micro;
      this.ProductOptionsTemplate = model.ProductOptionsTemplate;
      this.ProductOverviewTemplate = model.ProductOverviewTemplate;
      this.ProductSpecificationsTemplate = model.ProductSpecificationsTemplate;
    }
    this.baseUrl = CustomMeasureAndInstallExpConfiguration.AUTOBAHN_BASE_URL;
  }

  ProductOptionsTemplate;

  ProductOverviewTemplate;

  ProductSpecificationsTemplate;
}

export default ThdPipModel;
