import styled, { css } from 'styled-components';
import Carousel from '@gcc/pip-components/dist/Carousel';
import { Grid } from '@gcc/pip-components/dist/grids';
// import { MainImageWrapperBase } from '../styles';
import { Para } from '@gcc/pip-components/dist/typography';
import isNucleus from '@gcc/pip-components/dist//utils/isNucleus';

// main image sizes
const MAX_MD = '475px'; // for 720p and smaller
const MAX_LG = '750px'; // for 1080p and larger

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

export const ClassicSubheader = styled.div`
  padding: 0 10px;
`;

export const MainContent = styled(Grid)`
  flex-grow: 1;
  align-items: stretch;

  @media (max-width: 576px) {
    padding-top: 0;
  }
`;

export const ProductTitle = styled(Para)`
  font-size: 1.8rem;
`;

export const ProductBrand = styled(Para).attrs(() => ({ bold: true }))`
  font-size: 1.4rem;
`;

export const ProductInfo = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;

  & > p {
    margin-bottom: 10px;
  }

  ${(props) => !isNucleus(props) && css`
    flex-direction: column-reverse;
  `}
`;

export const ThumbnailGrid = styled(Grid)`
  margin-top: 0.5em;

  & > div {
    margin: 0 20px 20px 0;
    vertical-align: unset;
    flex-shrink: 0;
  }

  @media screen and (max-width: 767px) {
    flex-wrap: nowrap;
    overflow-x: auto;
  }
`;

export const MainImageWrapperBase = styled.div.attrs(() => ({
  role: 'presentation',
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: #666;

  /* "hover to zoom" */
  p {
    margin-top: 0.5em;
  }

  .pip-components__Visualizer--image-zoomed-container {
    position: fixed;
    border: 1px solid rgb(148, 148, 148);
  }

  .video-js {
    margin: 0 auto;
    max-width: 100%;
  }
`;

// way more css than what should be necessary to get
// react-image-magnify and video.js to play nicely together in all browsers.
// even IE.
// fml.
export const MainImageWrapper = styled(MainImageWrapperBase)`
  justify-content: center;
  height: 100%;
  width: 100%;

  /* layer magnifier over thumbnails (and their overlays) */
  position: relative;
  z-index: 5;

  & > div {
    margin: 0 auto;
    /* when in doubt, uncomment this: */
    /* border: 1px solid red; */
  }

  .pip-components__Visualizer--image-main {
    max-height: ${MAX_LG};
    max-width: ${MAX_LG};

    @media screen and (max-width: 1919px) {
      max-height: ${MAX_MD};
      max-width: ${MAX_MD};
    }
    @media screen and (max-width: 576px) {
      max-height: ${MAX_MD};
      max-width: 100%;
    }
  }

  .video-js {
    @media screen and (max-width: 1919px) {
      max-height: ${MAX_MD};
    }
  }
`;

export const BackHeader = styled.div`
  position: sticky;
  z-index: 2;
  padding: 0.5em;
  box-shadow: 0 0 3px #ccc;

  svg {
    vertical-align: middle;
    margin-right: 0.5em;
  }
`;

export const SliderImage = styled.img`
margin: 1px;
width:100%;
`;

export const CarouselStyle = styled(Carousel)`
width:100%;
`;
export const Caption = styled.div`
  padding-top: 1.2rem;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  width: 100%;

  @media (min-width: 768px) {
    padding-top: 1.8rem;
    font-size: 1.8rem;
  }
`;
