import React, {
  useEffect, useRef, useCallback, useState, useContext
} from 'react';
import { Image } from '@thd-olt-component-react/core-ui';
import { Col, Row } from '@thd-olt-component-react/grid';
import { Rating } from '@thd-olt-component-react/rating';
import { Favorite } from '@thd-olt-component-react/save-to-favorites';
import { ExperienceContext, useStoreId } from '@thd-nucleus/experience-context';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  alias,
  arrayOf as arrayType,
  number as numberType,
  params,
  shape as shapeType,
  string as stringType,
  extend,
  useDataModel
} from '@thd-nucleus/data-sources';

import {
  useReviewsDeprecated
} from '@thd-nucleus/data-sources/react/dataModel/migration';
import { Price } from '@thd-olt-component-react/price';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import helpers from './helpers';
import TopSvg from './components/TopSvg.component';
import './sticky-nav.style.scss';
import { Link, Links, SmoothScroll } from './components';

const StickyNav = (props) => {

  const {
    channel: channelProp,
    children,
    offset,
    itemId
  } = props;

  const ctx = useContext(ExperienceContext);
  const stickNavRef = useRef(null);
  const [hideStickyNav, setHideStickyNav] = useState(true);

  const channel = channelProp || ctx?.channel || 'desktop';

  const goToTop = useCallback(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return null;
    }
    const displayStickyNav = () => {
      const shouldHide = window.pageYOffset < offset;
      if (hideStickyNav !== shouldHide) {
        setHideStickyNav(shouldHide);
        if (window.LIFE_CYCLE_EVENT_BUS) {
          window.LIFE_CYCLE_EVENT_BUS
            .trigger('sticky-nav.toggle', {
              hidden: shouldHide,
            });
        }
      }

    };
    window.addEventListener('scroll', displayStickyNav, { passive: true });
    return () => {
      window.removeEventListener('scroll', displayStickyNav);
    };
  }, [hideStickyNav]);

  useEffect(() => {
    const { onGetElement } = props;
    if (!helpers.ref && stickNavRef.current) {
      helpers.setStickyNavRef(stickNavRef);
      if (onGetElement) {
        onGetElement(stickNavRef);
      }
    }

  }, [stickNavRef?.current]);

  // const reviewService = useDataModel('reviews', { variables: { itemId } });
  const reviewService = useReviewsDeprecated({ itemId });
  const reviewData = reviewService.data?.reviews || {};
  const totalResults = reviewData?.TotalResults;
  const reviewProducts = reviewData?.Includes?.Products;
  const matchingReviewCount = reviewProducts?.items?.find((i) => {
    return i?.FilteredReviewStatistics?.TotalReviewCount === totalResults;
  });
  const averageOverallRating = matchingReviewCount?.FilteredReviewStatistics?.AverageOverallRating
    || reviewProducts?.store?.FilteredReviewStatistics?.AverageOverallRating;
  const storeId = useStoreId();
  const { data, error, loading } = useDataModel('clientOnlyProduct',
    {
      ssr: false,
      variables: { itemId, storeId },
      skip: storeId === '8119'
    });

  const {
    configuredAssetUrl,
    configuredProductLabel
  } = useLifeCycleEventBus('configurator.configurator_sticky_nav_event');
  if ((error && !data) || !data || loading) {
    return null;
  }

  if (!data?.product) {
    return null;
  }

  const { product = {} } = data || {};
  const {
    identifiers: {
      brandName,
      productLabel
    },
    pricing = {},
    reviews = {}
  } = data?.product || {};

  const { ratingsReviews = {} } = reviews || {};
  const { averageRating, totalReviews } = ratingsReviews || {};
  const { value } = pricing || {};
  const image = product?.media?.image;
  const reviewCount = typeof totalResults === 'number' ? totalResults : totalReviews || 0;
  const avgRating = reviewCount === 0 ? 0
    : averageOverallRating || averageRating || 0;
  const hasAverageRating = (avgRating === null) ? 0 : parseFloat(avgRating);
  const productImage = (
    <Image
      src={configuredAssetUrl || image?.url.replace(/_1000./, '_100.')}
      alt="Product Image"
      height="1"
      width="1"
    />
  );

  if (channel === 'mobile') {
    return (
      <div
        id="sticky-nav"
        className={classNames('sticky-nav', { 'sticky-nav--display': hideStickyNav })}
        ref={stickNavRef}
        data-section="sticky-nav"
        onClick={goToTop}
        role="presentation"
      >
        <div className="sticky-nav__mobile">
          <div className="sticky-nav__image--mobile sticky-nav__thumbnail">
            {productImage}
          </div>
          <div className="sticky-nav__middle-content-wrapper--mobile">
            <div className="sticky-nav__product--mobile">{configuredProductLabel || productLabel}</div>
            {brandName
            && <div className="sticky-nav__brand--mobile"><span>by</span> {brandName}</div>}
            <div className="sticky-nav__ratings-price--mobile">
              <div className="sticky-nav__ratings-price--mobile__ratings">
                <Rating value={hasAverageRating} />
                ({reviewCount})
              </div>
              <div className="sticky-nav__favorite-count--mobile">
                <Favorite itemId={itemId} showCircle showCount />
              </div>
              {value && (
                <div className="sticky-nav__ratings-price--mobile__price">
                  <Price
                    itemId={itemId}
                    channel="mobile"
                    large={false}
                    basic
                    displayEachUom={false}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="sticky-nav__button--mobile">
            <button type="button" onClick={goToTop}>
              <TopSvg />
            </button>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div
      id="sticky-nav"
      className={classNames('sticky-nav', { 'sticky-nav--display': hideStickyNav })}
      ref={stickNavRef}
      data-section="sticky-nav"
    >
      <div className="sticky-nav__inner-wrapper">
        <Row>
          <Col className="sticky-nav--no-top-bottom-padding">
            <div className="sticky-nav__row">
              <div className="sticky-nav__thumbnail">
                {productImage}
              </div>
              <div className="sticky-nav__product_details_wrapper">
                <div className="sticky-nav__product_details sticky-nav__row">
                  <Col fallback="11">
                    <div className="title">{configuredProductLabel || productLabel}</div>
                  </Col>
                </div>
                <div className="grid flush sticky-nav__brand_and_price sticky-nav__row">
                  <Col fallback="7" xs="7" sm="8" className="sticky-nav__brand_and_review">
                    {brandName
                    && (
                      <div className="sticky-nav__brand--container">
                        <span>by </span>
                        <span className="brand">{brandName}</span>
                      </div>
                    )}
                    <div className="sticky-nav__ratings-reviews">
                      <div className="ratings dynamic_ratings">
                        <SmoothScroll target="#ratings-and-reviews">
                          <Rating value={hasAverageRating} />
                        </SmoothScroll>
                        <SmoothScroll target="#ratings-and-reviews">({reviewCount})</SmoothScroll>
                      </div>
                    </div>
                    <div className="sticky-nav__favorite-count">
                      <Favorite itemId={itemId} showCircle showCount />
                    </div>
                  </Col>
                  {value && (
                    <Col fallback="5" xs="5" sm="4" className="sticky-nav__price">
                      <Price
                        itemId={itemId}
                        channel="desktop"
                        omitDetails
                        large={false}
                        displayEachUom={false}
                      />
                    </Col>
                  )}
                </div>
              </div>
              <button
                type="button"
                className="sticky-nav__to-top-button"
                onClick={goToTop}
              >
                <TopSvg />
              </button>
            </div>
            <div className="sticky-nav__jump-links">
              {children}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

StickyNav.displayName = 'StickyNav';

StickyNav.dataModel = extend({
  clientOnlyProduct: alias('product').params({ itemId: stringType().isRequired() }).shape({
    dataSources: stringType(),
    itemId: stringType(),
    identifiers: shapeType({
      brandName: stringType(),
      productLabel: stringType()
    }),
    media: shapeType({
      image: shapeType({
        url: stringType()
      }).client(),
      images: arrayType(shapeType({
        url: stringType(),
        sizes: arrayType(stringType()),
        type: stringType(),
        subType: stringType()
      }))
    }),
    pricing: params({ storeId: stringType() }).shape({
      value: numberType()
    }),
    reviews: shapeType({
      ratingsReviews: shapeType({
        averageRating: numberType(),
        totalReviews: numberType()
      })
    })
  }),
  reviews: params({ itemId: stringType().isRequired() }).shape({
    Includes: shapeType({
      Products: shapeType({
        items: arrayType(shapeType({
          FilteredReviewStatistics: shapeType({
            AverageOverallRating: stringType(),
            TotalReviewCount: numberType()
          })
        })),
        store: shapeType({
          FilteredReviewStatistics: shapeType({
            AverageOverallRating: stringType()
          })
        })
      })
    }),
    TotalResults: numberType()
  })
}, Favorite, Price);

StickyNav.propTypes = {
  channel: PropTypes.oneOf(['desktop', 'mobile']),
  children: PropTypes.node,
  itemId: PropTypes.string.isRequired,
  offset: PropTypes.number,
  /** onComplete Function. */
  onGetElement: PropTypes.func,
};
StickyNav.defaultProps = {
  channel: null,
  children: null,
  offset: 850,
  onGetElement: null,
};

StickyNav.Links = Links;
StickyNav.Link = Link;

export { StickyNav, Links, Link };
