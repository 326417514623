import { shape } from 'prop-types';
import React from 'react';
import { QuestionsAndAnswers } from '@thd-olt-component-react/questions-and-answers';

import ReactiveAccordion from '../reactive-accordion/reactive-accordion.view';

const expandText = 'Ask a Question';

const QuestionsAndAnswersComponent = ({ vm }) => {
  const {
    product: { PartnerProductId: productId },
  } = vm;
  const checkSubmitQuestion = (ee) => {
    const { target } = ee;
    if (target.innerText === expandText && target.closest('[class*="StyledExpandContentBox"]')) {
      const { $BV } = window;
      $BV.ui('qa', 'submit_question', { subjectType: 'product', productId });
    }
  };
  return (
    <div onClick={checkSubmitQuestion} role="presentation">
      <ReactiveAccordion vm={vm} title="Questions & Answers" expandText={expandText}>
        <QuestionsAndAnswers itemId={productId} />
      </ReactiveAccordion>
    </div>
  );
};

QuestionsAndAnswersComponent.propTypes = {
  vm: shape({}),
};

QuestionsAndAnswersComponent.defaultProps = {
  vm: {},
};

export default QuestionsAndAnswersComponent;
