/* eslint-disable no-console */
import { split } from 'lodash';
import { action, computed, makeObservable } from 'mobx';
import { nanoid } from 'nanoid';

import ProductSpecificationsModel from './product-specifications.model';

class ProductSpecificationsViewModel extends ProductSpecificationsModel {
  constructor(micro) {
    super(micro);
    makeObservable(this, {
      expandSpecifications: action,
      keyValueContent: computed,
      keyValueSections: computed,
      listContent: computed,
      listSections: computed,
      quickLinks: computed,
      scrollToProductSpecifications: action,
    });
  }

  scrollToProductSpecifications = ({ element, isNotSmall }) => {
    let yOffset = 0;
    if (isNotSmall) {
      this.expandSpecifications();
      yOffset = -150;
    }
    const top = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.setTimeout(() => window.scrollTo({ top, behavior: 'smooth' }), 100);
  };

  expandSpecifications = () => {
    const element = document.querySelector('#product-specifications .accordion-toggle');
    if (element) {
      element.click();
      element.checked = true;
    }
  };

  get keyValueContent() {
    const attributes = this.micro?.rootStore?.product?.Attributes;
    if (!attributes) return '';
    const specifications = attributes['Standard Specifications'];
    const mountingRequirements = attributes['Mounting Requirements'];
    return [specifications, mountingRequirements].join('\n');
  }

  get listContent() {
    const attributes = this.micro?.rootStore?.product?.Attributes;
    if (!attributes) return '';
    const { Upgrades: upgrades } = attributes;
    const madeIn = `**Made In**\n\n- ${attributes['Made In']}`;
    return [upgrades, madeIn].join('\n');
  }

  get quickLinks() {
    const attributes = this.micro?.rootStore?.product?.Attributes;
    if (!attributes) return '';
    const quickLinks = attributes['Quick Links'];
    if (!quickLinks) return '';
    const contentRegex = /(?:(\*\*\n))((?![\s\S]*\1))([\s\S]*?)$/g;

    const matches = quickLinks.match(contentRegex);
    if (!matches) return '';
    const items = matches[0].replace(/[ \t]+$/gm, '').replace(/\*\*/gm, '');
    return items.trim();
  }

  get listSections() {
    const sectionsRegex = /(\*\*([\s\S]*?)(?=\n\*\*))|(\*\*)(?![\s\S]*0)([\s\S]*?)$/g;
    const titleRegex = /((?:\*\*)(([\s\S]*?))(?=\*\*))/;
    const contentRegex = /(?:(\*\*\n))((?![\s\S]*\1))([\s\S]*?)$/g;
    const sections = this.listContent.match(sectionsRegex) || [];
    const groups = [];
    sections.forEach((section) => {
      const sanitizedSection = section.replace(/[ \t]+$/gm, '');
      const title = sanitizedSection.match(titleRegex)[2];
      const items = sanitizedSection.match(contentRegex)[0].replace(/\*\*/gm, '');
      groups.push({ id: nanoid(), items, title });
    });
    return groups;
  }

  get keyValueSections() {
    const sectionsRegex = /(\*\*([\s\S]*?)(?=\n\*\*))|(\*\*)(?![\s\S]*0)([\s\S]*?)$/g;
    const titleRegex = /((?:\*\*)(([\s\S]*?))(?=\*\*))/;
    const contentRegex = /(?:(\*\*\n))((?![\s\S]*\1))([\s\S]*?)$/g;

    const sections = this.keyValueContent.match(sectionsRegex) || [];
    const groups = [];
    const $this = this;

    sections.forEach((section) => {
      const sanitizedSection = section.replace(/[ \t]+$/gm, '');
      const title = sanitizedSection.match(titleRegex)[2];
      const content = sanitizedSection.match(contentRegex)[0].replace(/\*\*/gm, '');
      groups.push({ id: nanoid(), title, items: $this.getSectionItems(content) });
    });
    return groups;
  }

  getSectionItems = (content) => {
    if (!content) return [];
    const specsContent = split(content, /\n/)
      .filter(Boolean)
      // eslint-disable-next-line id-length
      .map((x) => {
        const row = x.split(':');
        return {
          id: nanoid(),
          label: row[0].replace(/- /g, ''),
          value: row[1] ? row[1].replace(/^ /g, '') : '',
        };
      });
    if (specsContent.length % 2 !== 0) {
      specsContent.push({
        id: nanoid(),
        label: '',
        value: '',
      });
    }
    return specsContent;
  };
}

export default ProductSpecificationsViewModel;
