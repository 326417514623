export const notEmptyString = (text) => {
  const regex = /^\s*$/;
  return !regex.test(String(text).toLowerCase());
};

export const validQA = (text) => {
  const regex = /^(.|\s){4,255}$/;
  return regex.test(String(text).toLowerCase());
};

export const validateQA = (text) => {
  return notEmptyString(text) && validQA(text);
};

export const validDisplayName = (text) => {
  const regex = /^(\d|\w){4,25}$/;
  return regex.test(String(text).toLowerCase());
};

export const validateDisplayName = (text) => {
  return notEmptyString(text) && validDisplayName(text);
};

/* eslint-disable */
export const validEmail = (text) => {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(text).toLowerCase());
};

export const validateEmail = (text) => {
  return notEmptyString(text) && validEmail(text);
};
