import { breakpoints, SelectionBox, styled, ty } from '@gcc/autobahn-components';

export const FulfillmentRoot = styled.section`
  ${ty`w-100 pb3 mb4 bb b--gray`}
  & h3 {
    ${ty`b`}
    font-size: 18px;
  }
`;

export const TileList = styled.ul`
  ${ty`list ma0 flex nl2 nr2`}
`;

export const Tile = styled.li`
  ${ty`pa2 w-third`}
  ${breakpoints.ns} {
    ${ty`w-auto`}
  }
`;

export const BoxTitle = styled.div`
  ${ty`fw7 black mb2 lh-solid`}
`;

export const BoxDescription = styled.div`
  ${ty`fw4 lh-solid near-black`}
`;

export const BoxSuffix = styled.div`
  ${ty`pt1 fw4 near-black lh-solid`}
`;

export const FulfillmentBox = styled(SelectionBox)`
  ${ty`pa2 tc`}
  ${breakpoints.ns} {
    width: 140px;
  }
`;

export const BoxIcon = styled.div`
  ${ty`center flex items-center lh-solid`}
  width: 26px;
  height: 26px;
`;

export const BoxDeliveryIcon = styled.div`
  ${ty`center`}
  width: 35px;
  height: 26px;
`;
