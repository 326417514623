import React, { useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import classNames from 'classnames';
import { Col, Row } from '@thd-olt-component-react/grid';
import { Input } from '@thd-olt-component-react/input';
import { notEmptyString, validEmail, validateEmail } from '../helper/QAModalFormHelper';

export const EmailAddress = ({
  formInputClasses,
  emailAddress,
  validationError,
  setEmailAddress
}) => {
  const inputClass = classNames({ 'form-input-field--error': validationError });
  const [errorText, setErrorText] = useState(null);

  useEffect(() => {
    if (!notEmptyString(emailAddress)) {
      setErrorText('Please provide your email.');
    } else if (!validEmail(emailAddress)) {
      setErrorText('Please enter a valid email.');
    } else {
      setErrorText(null);
    }
  }, [emailAddress]);

  return (
    <Row>
      <Col nopadding>
        <span className={`${formInputClasses}__title`}>Email</span>
        <Input
          value={emailAddress}
          className={inputClass}
          errorText={errorText}
          validation={(fieldText) => validateEmail(fieldText)}
          maxLength="255"
          onChange={(text) => setEmailAddress(text)}
        />
        {validationError && (
          <span className="form-input-field--error-message"> {validationError}</span>
        )}
      </Col>
    </Row>
  );
};

EmailAddress.displayName = 'EmailAddress';

EmailAddress.propTypes = {
  formInputClasses: string,
  emailAddress: string,
  validationError: string,
  setEmailAddress: func
};

EmailAddress.defaultProps = {
  formInputClasses: null,
  validationError: '',
  emailAddress: '',
  setEmailAddress: null
};
