/* eslint-disable no-console */
/* eslint-disable max-len */
import { Icon, useBreakpoint } from '@gcc/autobahn-components';
import {
  arrayOf, bool, func, number, shape, string
} from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import createResponsiveImageHelper from '@gcc/pip-components/dist/Visualizer/responsive/scene7';

import BrightcovePlayer from '@gcc/autobahn-components/dist/components/BrightcovePlayer/BrightcovePlayer.player';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import {
  StyledSamplesCta,
  StyledSamplesCtaButton,
  StyledSamplesCtaSub,
  StyledVisualizer,
  StyledVisualizerContainer,
  StyledVisualizerText,
} from './product-gallery.styles';

import { sendGalleryExpandEvent, sendGalleryThumbnailEvent } from '../../../../utility/analytics';
import getVideos from '../../../../api/getVideos';

const ProductGallery = ({
  brand,
  galleryImages,
  isMandI,
  productId,
  productName,
  partnerProductId,
  scrollToProductOptions,
}) => {
  const [galleryLoaded, setGalleryLoaded] = useState(false);
  const [galleryMedia, setGalleryMedia] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isSmall } = useBreakpoint();
  const responsiveImageHelper = createResponsiveImageHelper();

  useMemo(() => {
    async function fetchData() {
      getVideos(productId)
        .then((bcVideos) => bcVideos.map(({ id, thumbnail }) => ({
          key: parseInt(id, 10),
          mediaType: 'VIDEO',
          primaryLink: `http://ecdn.liveclicker.net/8079A8/origin/videos/1230/${id}_1_liveclicker.mp4`,
          videoId: id,
          thumbnail,
        }))
        )
        .then((videos) => {
          setGalleryMedia([...galleryImages, ...videos]);
        })
        .catch(() => {
          setGalleryMedia([...galleryImages]);
        });
    }

    if (galleryImages.length !== 0) {
      if (galleryLoaded) {
        fetchData();
      } else {
        setGalleryMedia([...galleryImages]);
      }
    }
  }, [galleryImages, galleryLoaded]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const gallery = document.querySelector('#gallery-visualizer');
      const mainImageLoaded = gallery && gallery.querySelector('.pip-components__Visualizer--image-main');
      const mainMobileImageLoaded = gallery && gallery.querySelector('[class*="mobileCarouselStyles__SlideContent"]');
      const isGalleryLoaded = (isSmall && mainMobileImageLoaded) || (!isSmall && mainImageLoaded);
      if (isGalleryLoaded) {
        observer.disconnect();
        setGalleryLoaded(true);
      }
    });
    observer.observe(document, {
      attributes: true,
      childList: true,
      characterData: false,
      subtree: true,
    });
  }, [isSmall]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      const modalExist = document.querySelector('[class*="ModalWithHeader"]');
      if (modalExist) {
        setIsModalOpen(true);
      } else {
        setIsModalOpen(false);
      }
    });

    observer.observe(document, {
      childList: true,
      subtree: true
    });
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      try {
        sendGalleryExpandEvent();
      } catch (error) {
        console.log(error);
      }
    }
  }, [isModalOpen]);

  if (!galleryImages || galleryImages.length === 0) {
    return null;
  }

  const onGalleryInteractionAnalytics = (assets, idx) => {
    if (!isModalOpen) {
      setIsModalOpen(true);
    }
    try {
      if (assets[idx]) {
        sendGalleryThumbnailEvent(idx, assets[idx]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const imagesCount = galleryMedia.filter((image) => (image.mediaType === 'IMAGE')).length;
  const internetNum = 'Internet # ';
  const greyMainImage = isSmall
    ? 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjY1cHgiIGhlaWdodD0iMjY1cHgiIHZpZXdCb3g9IjAgMCAyNjUgMjY1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPlJlY3RhbmdsZTwvdGl0bGU+CiAgICA8ZyBpZD0iTW9iaWxlIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iaVBob25lLTgiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0zMS4wMDAwMDAsIC0yODIuMDAwMDAwKSIgZmlsbD0iI0UwREZFMSI+CiAgICAgICAgICAgIDxyZWN0IGlkPSJSZWN0YW5nbGUiIHg9IjMxIiB5PSIyODIiIHdpZHRoPSIyNjUiIGhlaWdodD0iMjY1Ij48L3JlY3Q+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4='
    : 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDc1cHgiIGhlaWdodD0iNDc1cHgiIHZpZXdCb3g9IjAgMCA0NzUgNDc1IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPHRpdGxlPlJlY3RhbmdsZTwvdGl0bGU+CiAgICA8ZyBpZD0iRGVza3RvcCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IkRlc2t0b3AtSEQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yNDMuMDAwMDAwLCAtMjgzLjAwMDAwMCkiIGZpbGw9IiNFMERGRTEiPgogICAgICAgICAgICA8cmVjdCBpZD0iUmVjdGFuZ2xlIiB4PSIyNDMiIHk9IjI4MyIgd2lkdGg9IjQ3NSIgaGVpZ2h0PSI0NzUiPjwvcmVjdD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==';

  return (
    <ThemeProvider theme={{ scheme: 'nucleus' }}>
      <div id="gallery-partner-productID">
        <ErrorBoundary name="gallery-partner-productID">
          <StyledVisualizerText>
            {internetNum}
            {partnerProductId}
          </StyledVisualizerText>
        </ErrorBoundary>
      </div>
      <StyledVisualizerContainer id="gallery-visualizer">
        <ErrorBoundary name="gallery-visualizer">
          <StyledVisualizer
            bottomBoundary="#ChangeToRealIDIfMadeSticky"
            configuratorReady
            getVisualizerImageUrl={() => undefined}
            isSticky={false}
            mediaImages={galleryMedia}
            maxThumbnails={imagesCount > 5 ? 6 : imagesCount}
            productInfo={{
              brand,
              siteProductName: productName,
            }}
            showVideoThumbnails
            preloadedMainImage={greyMainImage}
            videoPlayerComponent={BrightcovePlayer}
            useSkeletons
            responsiveImageHelper={responsiveImageHelper}
            onVisualizerAnalyticsTrack={onGalleryInteractionAnalytics}
          />
        </ErrorBoundary>
      </StyledVisualizerContainer>
      {!isMandI && (
        <StyledSamplesCta>
          <ErrorBoundary name="samples-cta">
            <StyledSamplesCtaSub>
              <div className="dn db-ns">
                <Icon id="swatches-icon" color="var(--orange)" name="swatches" size="original" />
              </div>
              <div>
                <h3>Color Sample Swatches</h3>
                <p>Order up to 10 free samples with free shipping included.</p>
              </div>
            </StyledSamplesCtaSub>
            <StyledSamplesCtaButton
              color="secondary"
              type="button"
              label={isSmall ? 'Order' : 'Order Color Samples'}
              onClick={scrollToProductOptions}
            />
          </ErrorBoundary>
        </StyledSamplesCta>
      )}
    </ThemeProvider>
  );
};

ProductGallery.propTypes = {
  brand: string,
  galleryImages: arrayOf(
    shape({
      ImageUrl: string,
      Caption: string,
    })
  ),
  isMandI: bool,
  productName: string,
  productId: number,
  partnerProductId: number,
  scrollToProductOptions: func,
};

ProductGallery.defaultProps = {
  brand: undefined,
  galleryImages: [],
  isMandI: false,
  productId: undefined,
  productName: undefined,
  partnerProductId: undefined,
  scrollToProductOptions: () => {},
};

export default ProductGallery;
