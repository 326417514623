import { sizeSplit } from '@gcc/autobahn-utilities';
import Cookies from 'js-cookie';

export const getWidthCookie = () => {
  const widthCookie = Cookies.get('widthz');
  return widthCookie ? sizeSplit(widthCookie) : { wholes: 24, eighths: 0 };
};

export const getHeightCookie = () => {
  const heightCookie = Cookies.get('hghtz');
  return heightCookie ? sizeSplit(heightCookie) : { wholes: 36, eighths: 0 };
};