import { Price, styled } from '@gcc/autobahn-components';

const StyledPrice = styled(Price)`
  & [class*='StyledPromoDescription'] {
    color: #666;
    font-size: var(--font-size-6);
    text-transform: none;
  }  
  & [class*='StyledSaving'] {
    color: #00873C;
  }
`;

export default StyledPrice;
