import { SelectionBox, useBreakpoint } from '@gcc/autobahn-components';
import { isInternalUser } from '@gcc/autobahn-utilities';
import { useIsMobile } from '@gcc/pip-components/dist/hooks';
import currency from 'currency.js';
import { observer } from 'mobx-react-lite';
import React, { useMemo, useState } from 'react';

import {
  StyledOptionsSectionsChoice,
  StyledOptionsSectionsChoiceName,
  StyledOptionsSectionsChoiceNotAvailable,
  StyledOptionsSectionsChoicePrice,
  StyledRatioBox,
  StyledSampleButton,
  StyledZoomInPreview,
  StyledZoomInSwatch,
  StyledOriginalPrice,
  StyledPrice,
  StyledSavingsInline,
  StyledSavingsDiv,
} from './product-swatch.styles';
import ProductSwatchViewModel from './product-swatch.view-model';

const renderPricing = (price, percentOff, strikethroughPrice, isMobile) => {
  // In some cases, there was a penny discrepancy between the swatch price
  // and the product preview price. So we will match the product preview
  // price by doing the same logic from that component (Price component
  // in gcc-autobahn-components library)
  const discount = currency(price).multiply(percentOff / 100).value;
  const displayPrice = currency(price).subtract(discount).value;

  return (
    <div>
      <StyledOriginalPrice>{`${currency(price).format()}`}</StyledOriginalPrice>
      {isMobile
        ? <StyledSavingsDiv>Save {percentOff}%</StyledSavingsDiv>
        : <StyledSavingsInline> Save {percentOff}%</StyledSavingsInline>}
      <StyledPrice>{`${currency(displayPrice).format()}`}</StyledPrice>
    </div>
  );
};

const ProductSwatch = observer(
  ({
    choiceId,
    choiceName,
    image,
    isSampleable,
    isSampleAvailable,
    isChoiceSelected,
    isInCart,
    isPrimary,
    optionId,
    price,
    strikethroughPrice,
    percentOff,
    rootVm,
  }) => {
    const vm = useMemo(
      () => new ProductSwatchViewModel({
        action: '',
        choiceId,
        choiceName,
        image,
        isSampleable,
        isSampleAvailable,
        isChoiceSelected,
        isInCart,
        isPrimary,
        optionId,
        price,
        strikethroughPrice,
        percentOff,
        rootVm,
      }),
      [
        choiceId,
        choiceName,
        image,
        isSampleable,
        isSampleAvailable,
        isChoiceSelected,
        isInCart,
        isPrimary,
        optionId,
        price,
        strikethroughPrice,
        percentOff,
        rootVm,
      ]
    );

    const [showZoom, setShowZoom] = useState(false);
    const [zoomPosition, setZoomPosition] = useState(false);
    const { isLarge } = useBreakpoint();

    const {
      imageUrl,
      isSampleEnabled,
      previewSize,
      getZoomPreviewPosition,
      sampleButtonAction,
      sampleButtonType,
      sampleLabel,
      updateSwatchSelections,
    } = vm;

    const { isMandI, isSamplingEnabled, isSamplingEnabledCecOnly } = rootVm;
    const shouldEnableSampling = useMemo(
      () => isSamplingEnabled || (isSamplingEnabledCecOnly && isInternalUser()),
      [isSamplingEnabled, isSamplingEnabledCecOnly]
    );

    // eslint-disable-next-line id-length
    const toggleZoom = (e) => {
      setZoomPosition(getZoomPreviewPosition(e));
      setShowZoom(!showZoom);
    };

    if (isPrimary && !price) {
      return null;
    }

    const isMobile = useIsMobile();
    const hasStrikethrough = strikethroughPrice && strikethroughPrice !== price;

    return (
      <StyledOptionsSectionsChoice>
        {showZoom && (
          <StyledZoomInPreview
            imageUrl={`${imageUrl(image)}?width=290`}
            previewSize={previewSize}
            className={zoomPosition}
          />
        )}
        <SelectionBox
          onClick={() => (!isPrimary || (isPrimary && price) ? updateSwatchSelections() : () => null)}
          data-selected={isChoiceSelected}
          isSelected={isChoiceSelected}
        >
          <StyledRatioBox width={1} height={1} image={imageUrl(image)}>
            {isPrimary && !price && (
              <StyledOptionsSectionsChoiceNotAvailable>
                <small>Not available in this size</small>
              </StyledOptionsSectionsChoiceNotAvailable>
            )}
            {isLarge && isChoiceSelected && (
              <StyledZoomInSwatch
                onClick={toggleZoom}
                onMouseOut={toggleZoom}
                onMouseOver={toggleZoom}
                role="button"
                tabIndex="0"
              />
            )}
          </StyledRatioBox>
          <StyledOptionsSectionsChoiceName>{choiceName}</StyledOptionsSectionsChoiceName>
          {hasStrikethrough && renderPricing(price, percentOff, strikethroughPrice, isMobile)}
          {!hasStrikethrough && isPrimary && price && (
            // eslint-disable-next-line max-len
            <StyledOptionsSectionsChoicePrice>{`${currency(price).format()}`}</StyledOptionsSectionsChoicePrice>
          )}
        </SelectionBox>
        {shouldEnableSampling && !isMandI && (
          <>
            {(isSampleEnabled || isInCart) && (
              <StyledSampleButton
                element="button"
                onClick={sampleButtonAction}
                label={sampleLabel}
                color={sampleButtonType}
                spinner
              />
            )}
            {!isSampleEnabled && !isInCart && (
              <StyledSampleButton
                element="button"
                label={sampleLabel}
                style={{ cursor: 'not-allowed' }}
                disabled
                outline
              />
            )}
          </>
        )}
      </StyledOptionsSectionsChoice>
    );
  }
);

export default ProductSwatch;
