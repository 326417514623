import React, { Component, Fragment } from 'react';
import Scrollspy from 'react-scrollspy';
import PropTypes from 'prop-types';

class Links extends Component {

  state = {
    items: [],
    show: false
  };

  componentDidMount() {
    this.getItems();
  }

  getItems = () => {
    let loadedItems = [];
    const { children } = this.props;
    const kids = Array.isArray(children) ? children : [children];
    kids
      .map((kid, index) => {
        const { href = '' } = kid.props || {};
        const selector = {
          href,
          index
        };
        this.checkElement(selector) // use whichever selector you want
          .then((position) => {
            if (href.charAt(0) === '#') {
              loadedItems[position] = href.substring(1, href.length);
              if (kids.length === loadedItems.length) {
                this.setState({
                  items: loadedItems,
                  show: true
                });
              }
            }
          });
        return false;
      });

  };

  /* eslint-disable no-await-in-loop */
  checkElement = async (selector) => {
    while (document.querySelector(selector.href) === null) {
      await new Promise((resolve) => requestAnimationFrame(resolve));
    }
    return (selector.index);
  };

  render() {
    const { children } = this.props;
    const { show, items } = this.state;
    return (
      <>
        {show && (
          <Scrollspy items={items} offset={-200} currentClassName="active">
            {children}
          </Scrollspy>
        )}
      </>
    );
  }
}

Links.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};
Links.defaultProps = {
  children: null
};

export default Links;
