import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext, useStore } from '@thd-nucleus/experience-context';
import {
  useDataModel, params, string, number, shape, arrayOf, bool, extend, customType
} from '@thd-nucleus/data-sources';
import { Promotion } from '@thd-olt-component-react/promotion';
import { useLifeCycleEventBus } from '@thd-olt-functional/utils';
import Sku from '../models/Sku';
import PriceSimple from './partials/PriceSimple';
import PriceDetailed from './partials/PriceDetailed';
import { PreferredPricingBadge } from './partials/PreferredPricingBadge';
import '../styles/price-global.style.scss';

const isValidProps = (props) => {
  const { itemId, product: propProductData } = props;
  const propPricingData = propProductData?.pricing;
  return Boolean(propPricingData || /^\d{9}$/.test(itemId));
};

const isValidData = (sku) => {
  const { _product } = sku;
  return Boolean(
    _product
  );
};

const Price = (props) => {
  const {
    channel
  } = useContext(ExperienceContext);
  const {
    storeId: defaultStoreId,
    storeZip: defaultStoreZip,
    isLocalized
  } = useStore();
  const {
    basic,
    disableRangePricing,
    displayEachUom,
    hideBadge,
    hideBulkPrice,
    hideConditionalPromoDescription,
    hideLimitPerOrder,
    hidePromotions,
    hideSavingsText,
    hideRebates,
    itemId,
    large,
    omitDetails,
    onSavingsCenterToggle,
    showPreferredPricingBadge,
    showPreferredPricingBadgeToolTip,
    product: propProductData,
    skip,
    storeId = defaultStoreId,
    zipCode = defaultStoreZip,
    clsRemediation
  } = props;
  const {
    placeholders,
    preservePlaceholders
  } = clsRemediation || {};

  const hasValidProps = isValidProps(props);

  const { data, loading } = useDataModel('product', {
    ssr: !skip,
    skip: !hasValidProps || Boolean(propProductData),
    variables: {
      itemId,
      storeId
    }
  });

  const [hasDataFullyLoaded, setHasDataFullyLoaded] = useState(false);

  useEffect(() => {
    setHasDataFullyLoaded(!loading && isLocalized);
  }, [loading, isLocalized]);

  const { configuredPrice } = useLifeCycleEventBus('configurator.configurator_pricing_event');
  const productData = propProductData || data?.product;
  const productSku = new Sku(productData, configuredPrice);
  const hasValidData = isValidData(productSku);

  // (1) invalidate props
  if (!hasValidProps) {
    return null;
  }

  // (2) invalidate data
  if (!hasValidData) {
    if (!placeholders) {
      return null;
    }

    if (hasDataFullyLoaded && !preservePlaceholders) {
      return null;
    }
  }

  const priceClasses = classNames({
    'price--hidden': !hasValidData || !hasDataFullyLoaded
  });

  return (
    <div className={priceClasses}>
      {(showPreferredPricingBadge && productSku.preferredPriceFlag) && (
        <PreferredPricingBadge
          channel={channel}
          large={large}
          showPreferredPricingBadgeToolTip={showPreferredPricingBadgeToolTip}
        />
      )}
      {large && (
        <PriceDetailed
          channel={channel}
          disableRangePricing={disableRangePricing}
          displayEachUom={displayEachUom}
          hideBadge={hideBadge}
          hideBulkPrice={hideBulkPrice}
          hideConditionalPromoDescription={hideConditionalPromoDescription}
          hideLimitPerOrder={hideLimitPerOrder}
          hidePromotions={hidePromotions}
          hideRebates={hideRebates}
          hideSavingsText={hideSavingsText}
          omitDetails={omitDetails}
          product={productSku}
          storeId={storeId}
          zipCode={zipCode}
        />
      )}
      {!large && (
        <PriceSimple
          basic={basic}
          channel={channel}
          disableRangePricing={disableRangePricing}
          displayEachUom={displayEachUom}
          hideBadge={hideBadge}
          hideBulkPrice={hideBulkPrice}
          hideLimitPerOrder={hideLimitPerOrder}
          hidePromotions={hidePromotions}
          hideRebates={hideRebates}
          hideSavingsText={hideSavingsText}
          omitDetails={omitDetails}
          onSavingsCenterToggle={onSavingsCenterToggle}
          product={productSku}
          zipCode={zipCode}
        />
      )}
    </div>
  );
};

Price.propTypes = {
  basic: PropTypes.bool,
  clsRemediation: PropTypes.shape({
    placeholders: PropTypes.bool,
    preservePlaceholders: PropTypes.bool
  }),
  disableRangePricing: PropTypes.bool,
  displayEachUom: PropTypes.bool,
  hideBadge: PropTypes.bool,
  hideBulkPrice: PropTypes.bool,
  hideConditionalPromoDescription: PropTypes.bool,
  hideLimitPerOrder: PropTypes.bool,
  hidePromotions: PropTypes.bool,
  /** Hide Savings Text */
  hideSavingsText: PropTypes.bool,
  /** Hide Rebate Messaging and prevent Rebate Calls */
  hideRebates: PropTypes.bool,
  itemId: PropTypes.string.isRequired,
  /** Determines whether to display larger or smaller Price Component Variant */
  large: PropTypes.bool,
  omitDetails: PropTypes.bool,
  onSavingsCenterToggle: PropTypes.func,
  product: PropTypes.shape({}),
  showPreferredPricingBadge: PropTypes.bool,
  showPreferredPricingBadgeToolTip: PropTypes.bool,
  skip: PropTypes.bool,
  /** Store Id for Pricing Query */
  storeId: PropTypes.string,
  zipCode: PropTypes.string
};

Price.defaultProps = {
  basic: false,
  clsRemediation: {
    placeholders: false,
    preservePlaceholders: false
  },
  disableRangePricing: false,
  displayEachUom: false,
  hideBadge: false,
  hideBulkPrice: false,
  hideConditionalPromoDescription: false,
  hideLimitPerOrder: false,
  hidePromotions: false,
  hideSavingsText: false,
  hideRebates: false,
  large: true,
  omitDetails: false,
  onSavingsCenterToggle: () => {},
  product: null,
  showPreferredPricingBadge: false,
  showPreferredPricingBadgeToolTip: false,
  skip: false,
  storeId: undefined,
  zipCode: undefined
};

Price.dataModel = extend({
  product: params({
    itemId: string().isRequired(),
    dataSource: string(),
    loyaltyMembershipInput: customType('LoyaltyMembershipInput').shape({
      svocID: string(),
      programTiers: arrayOf(shape({
        tier: string(),
        program: string()
      }))
    })
  }).shape({
    itemId: string(),
    dataSources: string(),
    identifiers: shape({
      itemId: string()
    }),
    info: shape({
      hidePrice: bool(),
      ecoRebate: bool(),
      quantityLimit: number(),
      sskMin: string(),
      sskMax: string(),
      unitOfMeasureCoverage: string(),
      wasMaxPriceRange: number({ float: true }),
      wasMinPriceRange: number({ float: true })
    }),
    media: shape({
      images: arrayOf(shape({
        url: string(),
        type: string(),
        subType: string(),
        sizes: arrayOf(string())
      }))
    }),
    pricing: params({ storeId: string() }).shape({
      alternatePriceDisplay: bool(),
      alternate: shape({
        bulk: shape({
          pricePerUnit: number({ float: true }),
          thresholdQuantity: number(),
          value: number({ float: true })
        }),
        unit: {
          caseUnitOfMeasure: string(),
          unitsOriginalPrice: number({ float: true }),
          unitsPerCase: number({ float: true }),
          value: number({ float: true }),
        },
      }),
      original: number({ float: true }),
      mapAboveOriginalPrice: bool(),
      message: string(),
      preferredPriceFlag: bool(),
      promotion: shape({
        type: string(),
        description: shape({
          shortDesc: string(),
          longDesc: string()
        }),
        dollarOff: number({ float: true }),
        percentageOff: number({ float: true }),
        savingsCenter: string(),
        savingsCenterPromos: string(),
        specialBuySavings: string(),
        specialBuyDollarOff: number({ float: true }),
        specialBuyPercentageOff: number({ float: true })
      }),
      specialBuy: number({ float: true }),
      unitOfMeasure: string(),
      value: number({ float: true }),
    }),
  })
},
Promotion
);

export default Price;
