import { Icon, Tooltip, useBreakpoint } from '@gcc/autobahn-components';
import { string } from 'prop-types';
import React from 'react';

import {
  Installation,
  CtaButton,
  HeaderRow,
  InstallationSection,
  ToolTipBox,
  ToolTipContainer,
  ToolTipFooter,
  ToolTipHeader,
  ToolTipInfoIcon,
  ToolTipLink,
  ToolTipSubHeader,
  ToolTipText,
} from './product-installation-cta.styles';
import ProductInstallationCTAModal from './product-installation-cta-modal/product-installation-cta-modal.index';

const ProductInstallationCta = ({ mandIPhoneNumber }) => {
  const { isSmall } = useBreakpoint();
  const mniToolTipLink = (
    <ToolTipLink>
      What to Expect
      <ToolTipInfoIcon>
        <Icon name="info" size="original" />
      </ToolTipInfoIcon>
    </ToolTipLink>
  );
  const mniToolTipContent = (
    <ToolTipBox>
      <ToolTipHeader>How Professional Installation Works</ToolTipHeader>
      <ToolTipSubHeader>Step 1: Check Availability</ToolTipSubHeader>
      <ToolTipText>Confirm our services in your area</ToolTipText>
      <ToolTipSubHeader>Step 2: Measure</ToolTipSubHeader>
      <ToolTipText>We measure your windows for you</ToolTipText>
      <ToolTipSubHeader>Step 3: Build</ToolTipSubHeader>
      <ToolTipText>Your window treatments are custom made</ToolTipText>
      <ToolTipSubHeader>Step 3: Install</ToolTipSubHeader>
      <ToolTipText>We install your window treatments</ToolTipText>
      <ToolTipFooter>
        Have questions? Call us at
        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
        <b> {mandIPhoneNumber || '877-865-9871'} </b>
        and we&apos;ll help you out!
      </ToolTipFooter>
    </ToolTipBox>
  );

  return (
    <InstallationSection>
      <Installation id="zone-a-installation">
        <Icon id="drill-icon" color="var(--orange)" name="drill" size="original" />
        <div>
          <HeaderRow>
            <h3> Want Professional Installation?</h3>
            {!isSmall && (
              <ToolTipContainer>
                <Tooltip linkText={mniToolTipLink} hideOnClick interactive showClose trigger="click" placement="bottom">
                  {mniToolTipContent}
                </Tooltip>
              </ToolTipContainer>
            )}
          </HeaderRow>
          <p>Have one of our licensed pros install your window treatments.</p>
          <CtaButton
            label="Check Availability"
            onClick={() => {
              window.location = '/custom-blinds/schedule';
            }}
            outline
          />
          {isSmall && (
            <ToolTipContainer>
              <ProductInstallationCTAModal title={mniToolTipLink}>{mniToolTipContent}</ProductInstallationCTAModal>
            </ToolTipContainer>
          )}
        </div>
      </Installation>
    </InstallationSection>
  );
};

ProductInstallationCta.propTypes = {
  mandIPhoneNumber: string,
};

ProductInstallationCta.defaultProps = {
  mandIPhoneNumber: undefined,
};

export default ProductInstallationCta;
