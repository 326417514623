import React from 'react';

export const PRODUCT_DOMAINS = ['identifiers', 'media', 'pricing', 'info'];

export const PRICE_TYPES = {
  STANDARD: 'STANDARD',
  BASIC: 'BASIC',
  STRIKE_THROUGH: 'STRIKE_THROUGH',
  STARTING_AT: 'STARTING_AT',
  MAP_PRICE: 'MAP_PRICE',
  ECO_REBATE: 'ECO_REBATE',
  INSTANT_REBATE: 'INSTANT_REBATE',
  BULK_PRICE: 'BULK_PRICE',
  UNIT_AND_CASE: 'UNIT_AND_CASE',
  LIMIT_PER_ORDER: 'LIMIT_PER_ORDER',
  RANGE_PRICE: 'RANGE_PRICE',
  BRIO: 'BRIO',
  LIVE_GOODS: 'LIVE_GOODS',
  NO_DETAILS: 'NO_DETAILS'
};

const helpers = {
  getPricingObject: ({
    product, displayEachUom, omitDetails, basic, disableRangePricing = false
  }) => {
    const { pricing } = product;
    const ret = {};
    let priceType = PRICE_TYPES.STANDARD;

    if (!pricing) {
      return { _price: ret, type: '' };
    }

    const {
      alternatePriceDisplay,
      alternatePricingDisplay,
      unitOriginalPrice,
      unitSpecialPrice,
      unitOfMeasureCoverage,
      pricingMessage,
      specialBuyPrice,
      specialPrice,
      originalPrice,
      minPriceRange,
      maxPriceRange,
      wasMinPriceRange,
      wasMaxPriceRange,
      percentageOff,
      dollarOff,
      installationServiceType,
      productSubType,
      savingsCenter,
      hidePrice,
      bulkPrice,
      bulkPriceThresholdQty,
      caseUnitUom,
      uom,
      rebates,
      storePromotion,
      quantityLimit,
      mapAboveOriginalPrice,
      promotionDetails
    } = pricing;

    if (omitDetails) {
      if (pricingMessage) {
        if (mapAboveOriginalPrice) {
          return { _price: ret, type: PRICE_TYPES.NO_DETAILS };
        }

      } else if (alternatePriceDisplay || alternatePricingDisplay) {
        if (uom === 'sq. ft.') {
          if (unitOriginalPrice !== unitSpecialPrice) {
            ret.price = helpers.toFixed(unitSpecialPrice, 2);
          } else {
            ret.price = helpers.toFixed(unitOriginalPrice, 2);
          }
        } else if (uom !== 'sq. ft.') {
          if (originalPrice !== specialPrice) {
            ret.price = helpers.toFixed(specialPrice, 2);
          } else {
            ret.price = helpers.toFixed(originalPrice, 2);
          }
        }
      } else if (percentageOff) {
        ret.save = helpers.savingsPrice(
          percentageOff, dollarOff,
          originalPrice, specialBuyPrice, specialPrice
        );
        ret.price = helpers.toFixed(specialPrice, 2);
        ret.wasPrice = {
          original: helpers.toFixed(originalPrice, 2),
          special: ret.price
        };
      } else if (!pricingMessage && !specialBuyPrice) {
        ret.price = helpers.toFixed(specialPrice, 2);
      }
      ret.uom = uom ? uom.toLowerCase() : '';
      ret.priceBadge = helpers.priceBadge(savingsCenter);
      return { _price: ret, type: PRICE_TYPES.NO_DETAILS };
    }

    // Basic price
    if (basic) {
      if (pricingMessage) {
        if (mapAboveOriginalPrice) {
          return {
            _price: ret,
            type: PRICE_TYPES.NO_DETAILS
          };
        }
      } else {
        ret.price = helpers.toFixed(specialPrice, 2);
        return {
          _price: ret,
          type: PRICE_TYPES.BASIC
        };
      }
    }

    // Range Price
    if (!disableRangePricing && pricing && minPriceRange && maxPriceRange
      && minPriceRange !== maxPriceRange) {
      const minPrice = parseFloat(minPriceRange);
      const maxPrice = parseFloat(maxPriceRange);
      ret.minPrice = helpers.toFixed(minPriceRange, 2);
      ret.maxPrice = helpers.toFixed(maxPriceRange, 2);
      // strike-through range
      if (wasMinPriceRange && wasMaxPriceRange) {
        const wasMin = parseFloat(wasMinPriceRange);
        const wasMax = parseFloat(wasMaxPriceRange);
        if (wasMin > minPrice && wasMax > maxPrice) {
          if (wasMin !== wasMax) {
            ret.wasMinPrice = helpers.toFixed(wasMinPriceRange, 2);
            ret.wasMaxPrice = helpers.toFixed(wasMaxPriceRange, 2);
          } else {
            ret.wasMinPrice = helpers.toFixed(wasMinPriceRange, 2);
          }
        }
      }
      // savings %
      if (percentageOff && percentageOff > 0) {
        ret.percentageOff = helpers.toFixed(percentageOff);
      }

      return { _price: ret, type: PRICE_TYPES.RANGE_PRICE };
    }

    // BRIO SKU
    if (pricing
      && ((installationServiceType && installationServiceType === 'SFI')
        || (productSubType && productSubType.name === 'SFI'))
    ) {
      ret.isBRIOSku = true;
      return { _price: ret, type: PRICE_TYPES.BRIO };
    }

    if (hidePrice) {
      return { _price: {}, type: PRICE_TYPES.LIVE_GOODS };
    }

    // Price Badges
    ret.priceBadge = helpers.priceBadge(savingsCenter);

    // Bulk pricing
    if (bulkPrice && bulkPriceThresholdQty) {
      ret.bulkPrice = bulkPrice;
      ret.bulkPriceThresholdQty = bulkPriceThresholdQty;
      ret.priceBadge = 'Bulk-Price';
      priceType = PRICE_TYPES.BULK_PRICE;
    }

    // Save
    ret.save = helpers.savingsPrice(
      percentageOff, dollarOff,
      originalPrice, specialBuyPrice, specialPrice
    );
    // Starting at price
    if (pricingMessage && pricingMessage === 'Starting at') {
      ret.pricingMessage = pricingMessage;
      if (originalPrice) {
        ret.price = helpers.toFixed(originalPrice, 2);
      }
      if (specialPrice) {
        ret.price = helpers.toFixed(specialPrice, 2);
      }
      if (originalPrice && specialPrice && specialPrice !== originalPrice) {
        ret.wasPrice = {
          original: helpers.toFixed(originalPrice, 2),
          special: helpers.toFixed(specialPrice, 2)
        };
      }

      ret.uom = uom ? uom.toLowerCase() : '';
      return { _price: ret, type: PRICE_TYPES.STARTING_AT };
    }

    // MAP pricing
    if (pricingMessage) {
      if (mapAboveOriginalPrice) {
        ret.pricingMessage = 'See Low Price in Cart';
        priceType = PRICE_TYPES.MAP_PRICE;
      } else if (typeof mapAboveOriginalPrice !== 'undefined') {
        if (originalPrice && specialPrice !== originalPrice) {
          ret.wasPrice = {
            original: helpers.toFixed(originalPrice, 2),
            special: ''
          };
          ret.pricingMessage = 'See Lower Price in Cart';
        } else {
          ret.pricingMessage = 'See Low Price in Cart';
        }
        return { _price: ret, type: PRICE_TYPES.MAP_PRICE };
      }
    } else if (specialBuyPrice
      && originalPrice >= specialBuyPrice && specialBuyPrice > specialPrice) {
      ret.mapPrice = {
        original: helpers.toFixed(originalPrice),
        pricingMessage: 'See Lower Price in Cart',
        hasStrikeThrough: true
      };
      if (originalPrice && specialPrice && specialPrice !== originalPrice) {
        ret.wasPrice = {
          original: helpers.toFixed(originalPrice, 2),
          special: ''
        };
      }
      ret.pricingMessage = 'See Lower Price in Cart';
      priceType = PRICE_TYPES.MAP_PRICE;
    } else if (mapAboveOriginalPrice) {
      ret.pricingMessage = 'See Low Price in Cart';
      priceType = PRICE_TYPES.MAP_PRICE;
    }

    // Unit pricing/Alternate pricing
    const mapViolation = !pricingMessage && !mapAboveOriginalPrice;
    const hasAlternatePrice = !!alternatePriceDisplay || !!alternatePricingDisplay;
    const hasQuantityLimit = !!quantityLimit;
    const hasUnitOfMeasureCoverage = !!unitOfMeasureCoverage;
    const bulbProductScenario = caseUnitUom === 'bulb' && hasUnitOfMeasureCoverage;
    const bulbProductCheck = bulbProductScenario && !hasQuantityLimit;
    if (mapViolation && (hasAlternatePrice || bulbProductCheck)) {
      if (unitOriginalPrice && unitSpecialPrice && unitOriginalPrice !== unitSpecialPrice) {
        ret.unitWasPrice = {
          original: helpers.toFixed(unitOriginalPrice, 2),
          special: helpers.toFixed(unitSpecialPrice, 2)
        };
      }
      if (originalPrice && specialPrice && specialPrice !== originalPrice) {
        ret.wasPrice = {
          original: helpers.toFixed(originalPrice, 2),
          special: helpers.toFixed(specialPrice, 2)
        };
      }

      ret.price = helpers.toFixed(unitSpecialPrice, 2);

      if (caseUnitUom) {
        ret.uom = caseUnitUom.toLowerCase();
      }
      if (percentageOff > 0) {
        ret.save = {
          dollarOff: helpers.toFixed(dollarOff, 2),
          percentageOff: helpers.toFixed(percentageOff)
        };
      }
      if (uom) {
        ret.alternatePrice = specialPrice;
        ret.alternateUom = uom.toLowerCase();
      }
      if (promotionDetails) {
        ret.promotionDetails = promotionDetails;
      }
      if (rebates && rebates.hasEcoRebates) {
        ret.hasEcoRebates = rebates.hasEcoRebates;
      }
      return { _price: ret, type: PRICE_TYPES.UNIT_AND_CASE };
    }

    // was pricing
    if (specialPrice && originalPrice && specialPrice !== originalPrice) {
      ret.wasPrice = {
        original: helpers.toFixed(originalPrice, 2),
        special: helpers.toFixed(specialPrice, 2)
      };
      if (priceType === PRICE_TYPES.STANDARD) {
        priceType = PRICE_TYPES.STRIKE_THROUGH;
      }
    }

    ret.price = '';
    if (originalPrice) {
      ret.price = helpers.toFixed(originalPrice, 2);
    } else if (specialPrice) {
      ret.price = helpers.toFixed(specialPrice, 2);
    }

    if (displayEachUom && uom) {
      ret.uom = uom.toLowerCase();
    } else if (uom && uom !== 'each') {
      ret.uom = uom.toLowerCase();
    }

    if (promotionDetails) {
      ret.promotionDetails = promotionDetails;
    }

    // Final Price Type Logic
    if (rebates && rebates.hasEcoRebates) {
      ret.hasEcoRebates = rebates.hasEcoRebates;
      priceType = PRICE_TYPES.ECO_REBATE;
    }
    if (storePromotion && storePromotion.longDescription) {
      priceType = PRICE_TYPES.INSTANT_REBATE;
    }
    if (hasQuantityLimit) {
      priceType = PRICE_TYPES.LIMIT_PER_ORDER;
    }
    return { _price: ret, type: priceType };
  },
  toFixed: (price, n) => {
    if (!price) {
      return '0';
    }
    if (typeof price === 'string') {
      return parseFloat(price).toFixed(n);
    }
    return price.toFixed(n);
  },
  parsePriceValue: (price) => {
    let ret = {};
    if (!price) {
      return '';
    }
    const priceSplit = price.toString().split('.');
    ret.dollars = priceSplit[0]; // eslint-disable-line
    ret.cents = priceSplit[1] || '00';
    if (ret.cents.length === 1) {
      ret.cents += '0';
    }
    return ret;
  },
  parseUom: (uom, isDisplayEachUom) => {
    let parsedUom = `/${uom}`;
    if (uom === 'each') {
      if (isDisplayEachUom) {
        parsedUom = '/each';
      } else {
        parsedUom = '';
      }
    } else if (uom.indexOf('pallet(') > -1) {
      parsedUom = '/pallet';
    }
    return parsedUom;
  },
  handlePriceParsing: (priceToParse, skipFormat) => {
    const retPrice = helpers.parsePriceValue(priceToParse);
    if (retPrice.dollars === '0' && !skipFormat) {
      return (<span>{retPrice.cents}<span className="price__cent-format">¢</span></span>);
    }
    if (retPrice.dollars === '0' && skipFormat) {
      return (<span>{retPrice.cents}¢</span>);
    }
    return (<span>${retPrice.dollars}.{retPrice.cents}</span>);
  },
  triggerAnalytics: (tooltipLocation) => {
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS) {
      window.LIFE_CYCLE_EVENT_BUS.trigger('price.click', { location: tooltipLocation });
    }
  },
  savingsPrice: (percentageOff, dollarOff, originalPrice, specialBuyPrice, specialPrice) => {
    // Save
    let ret = {};
    const isOnSale = !(originalPrice >= specialBuyPrice && specialBuyPrice > specialPrice);
    if (percentageOff > 0 && isOnSale) {
      ret.save = {
        dollarOff: helpers.toFixed(dollarOff, 2),
        percentageOff: helpers.toFixed(percentageOff)
      };
    }
    return ret.save;
  },
  priceBadge: (savingsCenter) => {
    // Price Badges
    let ret = {};
    const badge = savingsCenter && savingsCenter.toLowerCase();
    if (badge === 'overstock') {
      ret.priceBadge = 'Over-Stock';
    } else if (badge === 'special buys') {
      ret.priceBadge = 'Special-Buy';
    } else if (badge === 'new lower prices') {
      ret.priceBadge = 'New-Lower-Price';
    } else if (badge === 'clearance') {
      ret.priceBadge = 'Clearance';
    }
    return ret.priceBadge;
  }

};

export default helpers;
