import {
  BazaarVoiceProvider,
  ContentBox,
  InternalUserHeader,
  useBreakpoint,
  withTheme,
  Breadcrumbs,
} from '@gcc/autobahn-components';
import { MdMailOutline } from '@react-icons/all-files/md/MdMailOutline';
import { MdPhoneInTalk } from '@react-icons/all-files/md/MdPhoneInTalk';
import { observer } from 'mobx-react-lite';
import { shape } from 'prop-types';
import React, { useRef, useState, useEffect, useMemo } from 'react';
import { InView } from 'react-intersection-observer';

// Rollback: use GCC Breadcrumbs until IDM data is ready then we can switch to harmony breadCrumbs**
// import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { Link, Links, StickyNav } from '@thd-olt-component-react/sticky-nav';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { useParams } from '@thd-olt-component-react/router';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import ProductGallery from '../product-gallery/product-gallery.view';
import ProductReviews from '../product-reviews/product-reviews.view';
import ProductSummary from '../product-summary/product-summary.view';
import QuestionsAndAnswers from '../questions-and-answers/questions-and-answers.view';
import ReactiveAccordion from '../reactive-accordion/reactive-accordion.view';
import { App as ProductOptions } from '../product-options';
import { App as ProductOverview } from '../product-overview';
import { App as ProductSpecifications } from '../product-specifications';
import FreeSamples from '../product-options/components/free-samples/free-samples.view';

import {
  BreadcrumbRow,
  StyledFlyout,
  StyledFlyoutCallUs,
  StyledFlyoutIcon,
  StyledFlyoutMailUs,
  StyledFlyoutText,
  StyledGallery,
  StyledLink,
  StyledMainContainer,
  StyledMandIHeader,
  StyledProductOptions,
  StyledProductOverview,
  StyledProductReviews,
  StyledProductSpecifications,
  StyledQuestionsAndAnswers,
  StyledSummary,
  StyledZoneA,
} from './thd-pip.styles';

const ThdPipView = observer((props) => {
  const {
    theme,
    vm: {
      bazaarVoiceApiUrl,
      bazaarVoiceConversationsApiUrl,
      bazaarVoicePasskey,
      breadcrumbs,
      contactNumber,
      galleryImages,
      isMandI,
      micro,
      product: { AliasId: aliasId, Brand: brand, PartnerProductId: partnerProductId, ProductName: productName },
      scrollToProductOptions,
      isProfessionallyInstallable,
      isSamplingEnabled,
      sampleIdsInCart,
    },
    vm,
  } = props;

  const { isSmall } = useBreakpoint();

  const shouldShowFreeSamplesSection = useMemo(
    () => ((isSamplingEnabled && !isSmall) || sampleIdsInCart.length > 0) && !isMandI,
    [isSamplingEnabled, isSmall, isMandI, sampleIdsInCart.length]
  );

  const { itemId } = useParams();

  const productOverviewRef = useRef(null);
  vm.productOverviewRef = productOverviewRef;

  const productSpecificationsRef = useRef(null);
  vm.productSpecificationsRef = productSpecificationsRef;

  const customerReviewsRef = useRef(null);
  vm.customerReviewsRef = customerReviewsRef;

  const questionsAndAnswersRef = useRef(null);
  vm.questionsAndAnswersRef = questionsAndAnswersRef;

  const productOptionsRef = useRef(null);
  vm.productOptionsRef = productOptionsRef;

  const { breakpoint, isLarge } = useBreakpoint();
  vm.setBreakpoint(breakpoint);

  const stickyNavonGetElement = (res) => {
    const handleStickyNavClicks = (r) => { // Used to expand accordions
      const text = r?.target?.text;
      switch (text) {
      case 'Product Overview':
        productOverviewRef.current.querySelector('input[type="checkbox"]').checked = true;
        break;
      case 'Specifications':
        productSpecificationsRef.current.querySelector('input[type="checkbox"]').checked = true;
        break;
      case 'Questions & Answers':
        questionsAndAnswersRef.current.querySelector('input[type="checkbox"]').checked = true;
        break;
      case 'Customer Reviews':
        customerReviewsRef.current.querySelector('input[type="checkbox"]').checked = true;
        break;
      default:
        break;
      }
    };
    res.current.onclick = handleStickyNavClicks;
  };

  return (
    <StyledMainContainer itemScope itemType="https://schema.org/Product">
      {isLarge && (
        <StyledFlyout>
          <ErrorBoundary name="flyout">
            <StyledFlyoutCallUs>
              <a href={`tel:${contactNumber}`}>
                <StyledFlyoutIcon>
                  <MdPhoneInTalk />
                </StyledFlyoutIcon>
                <StyledFlyoutText>{contactNumber}</StyledFlyoutText>
              </a>
            </StyledFlyoutCallUs>
            <StyledFlyoutMailUs>
              <a href="mailto:customblinds@homedepot.com">
                <StyledFlyoutIcon>
                  <MdMailOutline />
                </StyledFlyoutIcon>
                <StyledFlyoutText>Email Us</StyledFlyoutText>
              </a>
            </StyledFlyoutMailUs>
          </ErrorBoundary>
        </StyledFlyout>
      )}
      <QueryProvider cacheKey="custom-measure-and-install">
        <BazaarVoiceProvider
          bvApiUrl={bazaarVoiceApiUrl}
          bvPasskey={bazaarVoicePasskey}
          bvConversationsApiUrl={bazaarVoiceConversationsApiUrl}
          productId={partnerProductId}
        >
          <InternalUserHeader isTHD />
          {isMandI && (
            <StyledMandIHeader>
              <StyledLink href="/b/Decor-Blinds-Window-Treatments/N-5yc1vZar4w">
                You are using our custom window treatments service. Click here to return to the regular website.
              </StyledLink>
            </StyledMandIHeader>
          )}
          {
            isLarge && (
              <ErrorBoundary name="sticky-nav">
                <StickyNav itemId={itemId} onGetElement={stickyNavonGetElement}>
                  <Links>
                    <Link href="#product-overview">Product Overview</Link>
                    <Link href="#product-specifications">Specifications</Link>
                    <Link href="#questions-and-answers">Questions & Answers</Link>
                    <Link href="#product-reviews">Customer Reviews</Link>
                  </Links>
                </StickyNav>
              </ErrorBoundary>
            )
          }
          <meta itemProp="productID" content={partnerProductId} />
          <div id="thd-pip-sticky-header" />
          <BreadcrumbRow>
            <ErrorBoundary name="breadcrumbs">
              <Breadcrumbs items={breadcrumbs} home={{ show: false }} mobileView="inline" />
              {/* Rollback: use GCC Breadcrumbs until IDM data is ready then we can switch to harmony breadCrumbs */}
              {/* <Breadcrumbs itemId={itemId} /> */}
            </ErrorBoundary>
          </BreadcrumbRow>
          <StyledZoneA>
            <StyledGallery>
              <ContentBox plain>
                <ProductGallery
                  brand={brand}
                  galleryImages={galleryImages}
                  isMandI={isMandI}
                  productId={aliasId}
                  productName={productName}
                  partnerProductId={partnerProductId}
                  scrollToProductOptions={scrollToProductOptions}
                />
              </ContentBox>
            </StyledGallery>
            <StyledSummary>
              <ContentBox plain>
                <ProductSummary vm={vm} />
              </ContentBox>
            </StyledSummary>
          </StyledZoneA>
          <StyledProductOptions id="product-options" ref={productOptionsRef}>
            {/* {ProductOptionsTemplate && (
              <ErrorBoundary>
                <MicroApp theme={theme} vm={vm} micro={micro} config={ProductOptionsTemplate} />
              </ErrorBoundary>
            )} */}
            <ErrorBoundary name="product-options">
              <ProductOptions theme={theme} vm={vm} micro={micro} />
            </ErrorBoundary>
          </StyledProductOptions>
          {/* {ProductOverviewTemplate && (
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <StyledProductOverview id="product-overview" ref={productOverviewRef}>
                    <ReactiveAccordion expandText="Info & Guides" title="Product Overview">
                      {inView && (
                        <ErrorBoundary>
                          <MicroApp vm={vm} micro={micro} config={ProductOverviewTemplate} />
                        </ErrorBoundary>
                      )}
                    </ReactiveAccordion>
                  </StyledProductOverview>
                </div>
              )}
            </InView>
          )} */}
          <ErrorBoundary name="product-overview">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <StyledProductOverview id="product-overview" ref={productOverviewRef}>
                    <ReactiveAccordion expandText="Info & Guides" title="Product Overview">
                      {inView && (
                        <ProductOverview vm={vm} micro={micro} />
                      )}
                    </ReactiveAccordion>
                  </StyledProductOverview>
                </div>
              )}
            </InView>
          </ErrorBoundary>
          {/* {ProductSpecificationsTemplate && (
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <StyledProductSpecifications id="product-specifications" ref={productSpecificationsRef}>
                    <ReactiveAccordion title="Specifications">
                      {inView && (
                        <ErrorBoundary>
                          <MicroApp vm={vm} micro={micro} config={ProductSpecificationsTemplate} />
                        </ErrorBoundary>
                      )}
                    </ReactiveAccordion>
                  </StyledProductSpecifications>
                </div>
              )}
            </InView>
          )} */}
          <ErrorBoundary name="product-specifications">
            <InView triggerOnce>
              {({ inView, ref }) => (
                <div ref={ref}>
                  <StyledProductSpecifications id="product-specifications" ref={productSpecificationsRef}>
                    <ReactiveAccordion title="Specifications">
                      {inView && (
                        <ProductSpecifications vm={vm} micro={micro} />
                      )}
                    </ReactiveAccordion>
                  </StyledProductSpecifications>
                </div>
              )}
            </InView>
          </ErrorBoundary>
          <StyledQuestionsAndAnswers ref={questionsAndAnswersRef} id="questions-and-answers">
            <ErrorBoundary name="questions-and-answers">
              <QuestionsAndAnswers vm={vm} />
            </ErrorBoundary>
          </StyledQuestionsAndAnswers>
          <StyledProductReviews ref={customerReviewsRef} id="product-reviews">
            <ErrorBoundary name="product-reviews">
              <ProductReviews vm={vm} />
            </ErrorBoundary>
          </StyledProductReviews>
          {shouldShowFreeSamplesSection && (
            <ErrorBoundary name="free-samples">
              <FreeSamples sampleIdsInCart={sampleIdsInCart} />
            </ErrorBoundary>
          )}
        </BazaarVoiceProvider>
      </QueryProvider>
    </StyledMainContainer>
  );
});

ThdPipView.propTypes = {
  vm: shape({}),
};

ThdPipView.defaultProps = {
  vm: {},
};

export default withTheme(ThdPipView);
