import { MdKeyboardArrowLeft } from '@react-icons/all-files/md/MdKeyboardArrowLeft';
import throttle from 'lodash/throttle';
import { node, string } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import {
  StyledContentBox,
  StyledContentBoxBack,
  StyledContentBoxContent,
  StyledContentBoxTitle,
  StyledContentBoxWrapper,
} from './product-installation-cta-modal.styles';

const ProductInstallationCTAModal = ({ children, className, title }) => {
  const wrapperRef = useRef(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const toggleCollapse = (event) => {
    if (event.target.tagName === 'H2') {
      if (!isCollapsed) {
        return false;
      }
    }
    wrapperRef.current.scrollTop = 0;
    return setIsCollapsed(!isCollapsed);
  };

  const [isContentAtTop, setIsContentAtTop] = useState(true);
  const toggleContentAtTop = () => {
    setIsContentAtTop(wrapperRef.current.scrollTop === 0);
  };

  useEffect(() => {
    const throttled = throttle(toggleContentAtTop, 100);
    wrapperRef.current.addEventListener('scroll', throttled);
  }, []);

  return (
    <StyledContentBox className={className} isCollapsed={isCollapsed}>
      {!isCollapsed && (
        <StyledContentBoxBack role="presentation" isContentAtTop={isContentAtTop}>
          <div role="button" tabIndex={0} onClick={toggleCollapse}>
            <MdKeyboardArrowLeft />
            Back
          </div>
        </StyledContentBoxBack>
      )}
      <StyledContentBoxWrapper ref={wrapperRef} isCollapsed={isCollapsed}>
        {isCollapsed && <StyledContentBoxTitle onClick={toggleCollapse}>{title}</StyledContentBoxTitle>}
        <StyledContentBoxContent isCollapsed={isCollapsed}>{children}</StyledContentBoxContent>
      </StyledContentBoxWrapper>
    </StyledContentBox>
  );
};

ProductInstallationCTAModal.defaultProps = {
  children: undefined,
  className: '',
};

ProductInstallationCTAModal.propTypes = {
  /** Content box contents */
  children: node,
  /** Custom class to be added to the content box container */
  className: string,
  /** Title of the content box */
  title: node.isRequired,
};

export default ProductInstallationCTAModal;
