import React, { useContext } from 'react';
import { string, number } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { QuestionsAndAnswersMobile } from './QuestionsAndAnswersMobile';
import { QuestionsAndAnswersDesktop } from './QuestionsAndAnswersDesktop';
import { dataModel } from './dataModel';
import './QuestionsAndAnswers.scss';

// TODO: holding for reference
// ugc = 'https://homedepot.ugc.bazaarvoice.com',
// bazaarVoiceApps = 'https://apps.bazaarvoice.com/deployments/homedepot/main_site/production'

export const QuestionsAndAnswers = ({ itemId, seoPageNumber }) => {
  const { channel } = useContext(ExperienceContext);

  const RenderedView = channel === 'mobile'
    ? QuestionsAndAnswersMobile
    : QuestionsAndAnswersDesktop;

  return <RenderedView itemId={itemId} seoPageNumber={seoPageNumber} />;
};

QuestionsAndAnswers.displayName = 'QuestionsAndAnswers';

QuestionsAndAnswers.dataModel = dataModel;

QuestionsAndAnswers.propTypes = {
  itemId: string.isRequired,
  seoPageNumber: number
};

QuestionsAndAnswers.defaultProps = {
  seoPageNumber: null
};
