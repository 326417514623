import { breakpoints, ContentBox, styled, ty } from '@gcc/autobahn-components';

export const StyledPreviewPrice = styled.div`
  ${ty`pa0 pa3-l bg-white`}
  position: sticky;
  top: 9.5rem;
  overflow: visible;
  & [class*='ContentBoxWrapper'] {
    overflow: visible;
  }
`;

export const StyledContentBox = styled(ContentBox)`
  overflow: visible;
  min-height: 601px;
`;

export const StyledOptions = styled.div`
  ${ty`w-100 w-70-ns z-0 relative`}
`;

export const StyledOptionsPreview = styled.div`
z-index: 200;
${breakpoints.ns} {
  z-index: 0;
  ${ty`w-30 pr3 relative db`}
  box-shadow: 0px 0px 0px rgba( 0, 0, 0, 0);
}
${breakpoints.s} {
  ${ty`w-100`}
}
`;

export const StyledOptionsWrapper = styled.div`
  ${ty`flex flex-wrap w-100`}
`;

export const StyledOptionsSections = styled.dl`
  ${ty`flex flex-wrap w-100`}
  > dt {
    ${ty`w-100`}
  }
  > dd {
    ${ty`ma0 w-100`}
  }
`;

export const StyledOptionsSectionsGroups = styled.ul`
  ${ty`flex flex-wrap w-100 ma0 pa0 list`}
  & > li {
    ${ty`w-100`}
  }
`;

export const StyledOptionSectionGroupTitle = styled.h3`
  ${ty`f3 mb2`}
`;

export const StyledOptionsSectionsChoices = styled.ul`
  ${ty`flex flex-wrap list ma0 pa0 nl2 nr2`}
  & > li {
    ${ty`tc mb3 ph1 ph2-ns w-third`}
    ${breakpoints.ns} {
      width: 15rem;
    }
  }
`;

export const StyledOptionsSectionsChoice = styled.li`
  ${ty`flex flex-column relative`}
`;

export const StyledOptionSectionTitle = styled.h3`
  ${ty`bb bw1 b--orange dib f2 lh-title mb3`}
`;

export const StyledViewMoreColors = styled.div`
  ${ty`mb4 flex justify-center`}
`;

export const StyledProductPreview = styled.div`
  ${ty`pa3-ns`}
`;
