import React, { useContext, useState } from 'react';
import { string } from 'prop-types';
import {
  Button, Card, CardContent, Image, Overlay
} from '@thd-olt-component-react/core-ui';
import { useDataModel } from '@thd-nucleus/data-sources';
import { NoContent } from './NoContent/NoContent.component';
import { QAResultMobile } from './QAResult/QAResultMobile.component';
import { Header } from './QAResult/component/Header';
import { useQAResult } from './QAResult/useQAResult';
import QAContext from '../context/QAContext';
import { dataModel } from './dataModel';
import './QuestionsAndAnswers.scss';

const QuestionsAndAnswersMobileCard = ({ canonicalUrl }) => {
  const {
    TotalResults, Results, searchText, seoPageNumber, itemId
  } = useContext(QAContext);

  const { data } = useDataModel('product', { variables: { itemId } });
  const seoLink = data?.product?.identifiers?.canonicalUrl?.replace('/p', '/p/questions') + '/1';

  const qaResult = useQAResult();

  const [overlayOpen, setOverlayOpen] = useState(false);
  const noResults = (TotalResults === 0 || Results?.length === 0) && !searchText;
  let url = '#';

  if (canonicalUrl) {
    const [withoutQuery] = canonicalUrl.split('?');
    const path = withoutQuery.replace('/p', '');
    url = `/p/questions${path}/1`;
  }

  const HeaderTitle = () => {
    return (
      <>
        <div className="row-header__title questions-and-answers">
          <a
            href={seoLink}
            className="row-header__text"
            onClick={(evt) => evt.preventDefault()}
          >
            Questions &amp; Answers
          </a>
        </div>
      </>
    );
  };

  const stopPropagation = (evt) => evt.stopPropagation();

  const CardContents = noResults ? (
    <span onClick={stopPropagation} role="none">
      {HeaderTitle()}
      <NoContent />
    </span>
  ) : (
    <>
      <div className="row-header row-header--nomargin row-header--space-between questions-and-answers__title">
        {HeaderTitle()}
        <span>
          <Image
            tabIndex="-1"
            alt="More"
            data-testid="caret"
            src="https://assets.thdstatic.com/images/v1/caret-orange.svg"
            width="1"
            height="1"
            stretchy
          />
        </span>
      </div>
      <div id="BVQAContainer">
        <div className="questions-and-answers__seo-content">
          <Header showTitleWithTotalQns />
        </div>
      </div>
    </>
  );

  const closeOverlay = () => {
    if (overlayOpen) {
      setOverlayOpen(false);
    }
  };
  const openOverlay = () => {
    if (!overlayOpen) {
      setOverlayOpen(true);
    }
  };

  return (
    seoPageNumber
      ? <QAResultMobile qaResult={qaResult} />
      : (
        <div onClick={openOverlay} role="presentation" data-component="QuestionsAndAnswersMobileCard">
          <Card noHide className="questions-and-answers">
            <CardContent
              className="questions-and-answers-card-content"
            >
              {CardContents}
              <Overlay onClose={() => {}} open={overlayOpen} className="qa-modal-wrapper">
                <Button
                  link
                  className="content-card__overlay-back"
                  contentClass="content-card__overlay-bttn-content"
                  onClick={closeOverlay}
                >
                  <span>
                    <img
                      className="content-card__overlay-caret"
                      src="https://assets.homedepot-static.com/images/v1/back-arrow-orange.svg"
                      alt="Back"
                      width={12}
                      height={14}
                    />
                  </span>
                  Back
                </Button>
                <div className="row-header qa-section">
                  <div className="row-header__title">
                    <h2 className="row-header__text">Questions &amp; Answers</h2>
                  </div>
                </div>
                <QAResultMobile qaResult={qaResult} />
              </Overlay>
            </CardContent>
          </Card>
        </div>
      )
  );
};

QuestionsAndAnswersMobileCard.displayName = 'QuestionsAndAnswersMobileCard';

QuestionsAndAnswersMobileCard.propTypes = {
  canonicalUrl: string
};

QuestionsAndAnswersMobileCard.defaultProps = {
  canonicalUrl: null
};

QuestionsAndAnswersMobileCard.dataModel = dataModel;

export { QuestionsAndAnswersMobileCard };
