export default {
  ref: null,
  set stickNavRef(ref) {
    this.ref = ref;
  },
  setStickyNavRef(ref) {
    this.stickNavRef = ref;
  },
  get stickyNavRef() {
    return this.ref;
  },
  getStickyNavRef() {
    return this.ref;
  },
  findPos(id) {
    const stickyNavHeight = this.stickyNavRef?.current?.offsetHeight;
    let node = document.getElementById(id.replace('#', ''));
    if (node && window) {
      let nodeHeight = node.getBoundingClientRect().top + window.pageYOffset;
      return nodeHeight - stickyNavHeight;
    }
    return 0;
  },
  getAnalyticsTarget: (hash) => {
    switch (hash) {
    case 'product-overview':
      return 'Product Overview';
    case 'specifications':
      return 'Specifications';
    case 'questions_answers_full':
      return 'Questions & Answers';
    case 'ratings-and-reviews':
      return 'Customer Reviews';
    default:
      return null;
    }
  }
};
