import { useContext, useEffect, useRef } from 'react';
import { useDataModel } from '@thd-nucleus/data-sources';
import {
  QUESTION_DISPLAY_MOBILE,
  QUESTION_PER_PAGE_DESKTOP,
  DEFAULT_NUMBER_OF_PAGES,
  DESKTOP_PAGE_2_START_INDEX,
} from '../../constants';
import QAContext from '../../context/QAContext';

export const useQAResult = () => {
  let {
    itemId,
    searchText,
    sortBy,
    startIndex,
    dispatch,
    channel,
    currentPage,
    seoPageNumber
  } = useContext(QAContext);
  const isMobile = channel === 'mobile';
  let desktopPageSize = currentPage === 1 ? 4 : QUESTION_PER_PAGE_DESKTOP;
  let pageSize = isMobile ? 8 : desktopPageSize;

  const shouldUseSEOResults = useRef({});
  // This will only run if JS is disabled
  if (seoPageNumber !== null && shouldUseSEOResults?.current) {
    desktopPageSize = seoPageNumber === 1 ? 4 : QUESTION_PER_PAGE_DESKTOP;
    pageSize = isMobile ? QUESTION_DISPLAY_MOBILE : desktopPageSize;

    if (seoPageNumber === 1) {
      startIndex = 1;
    } else if (!isMobile) {
      startIndex = DESKTOP_PAGE_2_START_INDEX + (seoPageNumber - 2) * QUESTION_PER_PAGE_DESKTOP;
    } else {
      startIndex = QUESTION_DISPLAY_MOBILE * (seoPageNumber - DEFAULT_NUMBER_OF_PAGES) + DEFAULT_NUMBER_OF_PAGES;
    }
  }

  const QandA = useDataModel('questionsAnswers', {
    variables: {
      itemId,
      startindex: startIndex,
      pagesize: pageSize,
      searchTerm: searchText,
      sortBy
    },
  });

  const qaData = QandA?.data;
  const qaError = QandA?.error;
  const qaLoading = QandA?.loading;
  const qaSeoData = {
    data: {
      Results: qaData?.questionsAnswers?.Results,
      Answers: qaData?.questionsAnswers?.Includes?.Answers,
      TotalResults: qaData?.questionsAnswers?.TotalResults,
      Offset: qaData?.questionsAnswers?.Offset,
      Limit: qaData?.questionsAnswers?.Limit
    }
  };

  useEffect(() => {
    dispatch({
      action: 'SET_LOADING',
      value: qaLoading
    });
    if (qaData?.questionsAnswers && !qaLoading && !qaError) {
      dispatch({
        action: 'SET_RESULTS',
        value: {
          TotalResults: qaData?.questionsAnswers?.TotalResults,
          Results: qaData?.questionsAnswers?.Results,
          Answers: qaData?.questionsAnswers?.Includes?.Answers,
          qaLoading
        }
      });
    }
  }, [itemId, startIndex, pageSize, searchText, sortBy, qaLoading]);

  if (seoPageNumber && shouldUseSEOResults?.current) {
    shouldUseSEOResults.current = false;
    return qaSeoData;
  }

  return null;
};
