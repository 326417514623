const QUESTION_DISPLAY_MOBILE = 8;
const QUESTION_PER_PAGE_DESKTOP = 10;
const QUESTION_ON_PAGE_LOAD_DESKTOP = 4;
const DEFAULT_NUMBER_OF_PAGES = 1;
const DESKTOP_PAGE_2_START_INDEX = 5;
const BASE_10 = 10;
const TEN_MB = 10000000;

export {
  QUESTION_DISPLAY_MOBILE,
  QUESTION_PER_PAGE_DESKTOP,
  QUESTION_ON_PAGE_LOAD_DESKTOP,
  DEFAULT_NUMBER_OF_PAGES,
  DESKTOP_PAGE_2_START_INDEX,
  BASE_10,
  TEN_MB
};
