/* eslint-disable max-len */
const assetsLink = 'https://assets.thdstatic.com/images/v1/';

export const PLCN_CARD_IMAGE_SMALL = `${assetsLink}payment-credit-card-thd.png`;
export const INFO_ICON = `${assetsLink}info.svg`;
export const CARD_IMAGE = {
  PLCN_HOMEDEPOT: `${assetsLink}plcc-credit-card.png`,
  PLNP_HOMEDEPOT: `${assetsLink}plcc-prox-credit-card.png`,
  PLCR_HOMEDEPOT: `${assetsLink}plcc-proxtra-credit-card.png`,
};

const citiLink = 'https://citiretailservices.citibankonline.com/CRS/acq/launch/index.action?';

export const PLCN_HOMEDEPOT = 'PLCN_HOMEDEPOT';
export const PLNP_HOMEDEPOT = 'PLNP_HOMEDEPOT';
export const PLCR_HOMEDEPOT = 'PLCR_HOMEDEPOT';

export const DEFAULT_PREQUAL_URL = `${citiLink}app=PQ01&siteId=PLCN_HOMEDEPOT&sc=30052&cmp=A~H~8~C~8~A~ZZZ0~AI~HD~ZZ_1DT`;
export const DEFAULT_APPLYNOW_URL = `${citiLink}app=UNSOL&siteId=PLCN_HOMEDEPOT&sc=30005&cmp=A~H~8~C~8~A~ZZZ0~AI~HD~ZZ_1DT`;
export const B2B_DEFAULT_APPLYNOW_URL = `${citiLink}app=UNSOL&siteId=PLCR_HOMEDEPOT&sc=40012&cmp=A~E~Z~O~3~B~ZZZ0~CR~HD~ZZ_C3T`;
export const DEFAULT_URL = {
  PLCN_HOMEDEPOT:
    'https://citiretailservices.citibankonline.com/RSnextgen/svc/launch/index.action?siteId=PLCN_HOMEDEPOT#signon&cmp=A~C~C~M~Z~Z~ZZZZ~ZZ~HD~ZZ&desc=R~A~I~Z~Z~Z~ZZZZ~ZZ~HD',
  PLNP_HOMEDEPOT:
    'https://www.retailservicescommercial.citi.com/USCRSF/CMLSVC/index.html?siteId=PLNP_HOMEDEPOT#sec/signon',
  PLCR_HOMEDEPOT:
    'https://www.retailservicescommercial.citi.com/USCRSF/CMLSVC/index.html?siteId=PLCR_HOMEDEPOT#sec/signon',
};

export const CARD_NAME = {
  PLCN_HOMEDEPOT: 'Consumer Card',
  PLNP_HOMEDEPOT: 'Commercial Account',
  PLCR_HOMEDEPOT: 'Pro Xtra Card',
};
export const ANALYTICS_ACCOUNT_SNAPSHOT = {
  PLCN_HOMEDEPOT: 'plcc-account snapshot',
  PLNP_HOMEDEPOT: 'plcc-account snapshot-commercial',
  PLCR_HOMEDEPOT: 'plcc-account snapshot-commercial rev',
};
export const ANALYTICS_SELECT_ACCOUNT = {
  PLCN_HOMEDEPOT: 'plcc:select account:consumer',
  PLNP_HOMEDEPOT: 'plcc:select account:commercial',
  PLCR_HOMEDEPOT: 'plcc:select account:commercial rev',
};

export const CARD_IMAGE_TESTID = {
  PLCN_HOMEDEPOT: 'plcn-img',
  PLNP_HOMEDEPOT: 'plnp-img',
  PLCR_HOMEDEPOT: 'plcr-img',
};

export const NON_CARD_HOLDER_VIEW = {
  PAY_MANAGE: 'plcc:continue to pay and manage',
  LINK_ACCOUNT: 'plcc:link accounts',
  MODAL_VIEW_LINK_ACCOUNT: 'my account>select account to link'
};

export const UNLINKED_CARD_TEXT = {
  PLCN_HOMEDEPOT: 'Consumer',
  PLNP_HOMEDEPOT: 'Commercial',
  PLCR_HOMEDEPOT: 'Pro Xtra',
};

export const TOOL_TIP_TEXT = {
  CURRENT_BALANCE: 'The Current Balance is updated once each evening and may not reflect all transactions (payments, purchases, returns and adjustments) you have made on your account.',
  AVAILABLE_CREDIT: 'Available Credit may not reflect recent purchases, returns, payments or changes in your credit limit. You may not have access to it if your account is past due.',
};

export const PROCESS_INDICATORS = {
  MAKE_A_PAYMENT: 'payments',
  VIEW_STATEMENTS: 'statements',
  ADD_AUTHORIZED_CARD_USER: 'addauthusers',
  PAPERLESS_SETTINGS: 'paperless',
  ENROLL_AUTO_PAY: 'autopay',
  MANAGE_PAYMENT_SOURCES: 'managepaymentsrc',
  HELP_AND_CONTACT: 'helpcontact',
  FAQ: 'faqlibrary',
  VIEW_INVOICES: 'invoices',
  MANAGE_AUTH_BUYERS: 'managebuyers',
  SPECIAL_OFFERS: 'specialoffers',
  SAVE_CARD: 'savecard',
};

export const MY_ACCOUNT_DISCLAIMER = 'Notice: You should not give access to your homedepot.com sign-in to anyone you would not\n'
  + 'want to have access to your financial information. If you have concerns about anyone with\n'
  + 'access to your My Account, you should change the password associated with your My Account\n'
  + 'sign-in, or contact Home Depot Credit Services to discuss your questions or concerns at\n'
  + '1‑866‑875‑5488.';
