import axios from 'axios';
import { appendAutobahnUrl } from '../../../../../utility/customMeasureAndInstallExpConfiguration';

class SampleCart {
  static getSampleCart() {
    const {
      pageModel: { getSampleCartUrl },
    } = window.pageContextJavascript;
    return axios({
      url: appendAutobahnUrl(getSampleCartUrl),
      header: { 'Cache-Control': 'no-store' },
      withCredentials: true,
      crossDomain: true,
      method: 'GET'
    });
  }

  static async addSampleToCart({ choiceId, optionId }) {
    const {
      pageModel: { addSamplesToCartUrl, productId },
    } = window.pageContextJavascript;

    const response = await axios({
      url: appendAutobahnUrl(addSamplesToCartUrl),
      data: {
        choiceId,
        optionId,
        productId,
      },
      withCredentials: true,
      crossDomain: true,
      method: 'POST'
    });

    const { data } = response;
    const cartUpdateEvent = new CustomEvent('cart.list.updated');
    document.dispatchEvent(cartUpdateEvent);
    return data.Data;
  }

  static async removeSampleFromCart({ choiceId, optionId }) {
    const {
      pageModel: { removeSampleFromCartUrl, productId },
    } = window.pageContextJavascript;

    const response = await axios({
      url: appendAutobahnUrl(removeSampleFromCartUrl),
      data: {
        choiceId,
        optionId,
        productId,
      },
      withCredentials: true,
      crossDomain: true,
      method: 'POST'
    });
    const { data } = response;
    const cartUpdateEvent = new CustomEvent('cart.list.updated');
    document.dispatchEvent(cartUpdateEvent);
    return data.Data;
  }

  static submitAnalytics({ eventName, sampleProductId }) {
    const thdAnalyticsEvent = {
      category: {
        primaryCategory: eventName === 'list add' ? 'button submission' : 'list view modification',
      },
      eventInfo: {
        eventName,
      },
      item: [
        {
          listLocation: 'pip',
          quantity: 1,
          price: {
            basePrice: 0,
          },
          productInfo: {
            sku: sampleProductId,
          },
        },
      ],
    };
    // eslint-disable-next-line no-underscore-dangle
    if (typeof window._T !== 'undefined') {
      // eslint-disable-next-line no-undef
      digitalData.event.push(thdAnalyticsEvent);
    }
  }
}

export default SampleCart;
