import styled from 'styled-components';

export const ImageNotFoundWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  & > span {
    font-weight: 700;
    font-size: ${({ isMobile }) => (isMobile ? '16px' : '24px')};
    width: 100%;
    text-align: center;
    color: #000000;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;

    /* vertically and horizontally center */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    z-index: 100;
  }

  & > img {
    width: ${({ isMobile }) => (isMobile ? '100%' : '450px')};
    opacity: 0.25;
  }

  & > .center-backup-img {
    /* vertically and horizontally center */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
