import axios from 'axios';
import Cookies from 'js-cookie';
import { mapKeys } from 'lodash';
import { CustomMeasureAndInstallExpConfiguration as expConfig } from
'../../../../utility/customMeasureAndInstallExpConfiguration';

const getPostMeasureCookie = async () => {
  const cookie = Cookies.get('postMeasureOptions');
  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  if (!cookie) {
    const urlParams = new URLSearchParams(window.location.search);
    const designId = urlParams.get('designId');
    const itemId = urlParams.get('itemId');
    let cookieData;
    if (designId && itemId) {
      const path = '/designs/getPostMeasureOptions/';
      const designBuilderApiUrl = `${expConfig.DESIGN_BUILDER_BASE_URL}${path}${designId}/${itemId}`;
      let config = {
        headers: {
          'x-api-key': expConfig.DESIGN_BUILDER_API_KEY
        }
      };
      const result = await axios.get(`${designBuilderApiUrl}`, config);
      const responseData = await result.data;
      cookieData = mapKeys(responseData, (_v, k) => capitalize(k));
      return cookieData;
    }
    return cookieData;
  }
  return JSON.parse(cookie);
};

export default getPostMeasureCookie;