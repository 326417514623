/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Alert, Button, useBreakpoint } from '@gcc/autobahn-components';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import FreeSamples from '../free-samples/free-samples.view';
import ProductPreview from '../product-preview/product-preview.view';
import ProductSwatch from '../product-swatch/product-swatch.view';
import {
  StyledContentBox,
  StyledOptions,
  StyledOptionSectionGroupTitle,
  StyledOptionSectionTitle,
  StyledOptionsPreview,
  StyledOptionsSections,
  StyledOptionsSectionsChoices,
  StyledOptionsSectionsGroups,
  StyledOptionsWrapper,
  StyledPreviewPrice,
  StyledViewMoreColors,
  StyledProductPreview,
} from './product-options.styles';

const ProductOptionsView = observer(({ vm }) => {
  const {
    isMandI,
    isSamplingEnabled,
    productOptionsViewModel: { colorSwatchesSections, setShowAllChoices, showAllChoices, swatchSelections },
    sampleIdsInCart,
  } = vm;
  const { isSmall } = useBreakpoint();
  const shouldShowFreeSamplesSection = useMemo(
    () => ((isSamplingEnabled && !isSmall) || sampleIdsInCart.length > 0) && !isMandI,
    [isSamplingEnabled, isSmall, isMandI, sampleIdsInCart.length]
  );
  return (
    <>
      <StyledOptionsWrapper>
        <StyledOptionsPreview>
          <StyledPreviewPrice>
            <StyledProductPreview>
              <ProductPreview vm={vm} />
            </StyledProductPreview>
            {shouldShowFreeSamplesSection && <FreeSamples desktopView sampleIdsInCart={sampleIdsInCart} />}
          </StyledPreviewPrice>
        </StyledOptionsPreview>
        <StyledOptions>
          <StyledContentBox plain>
            {!isSamplingEnabled && !isMandI && (
              <Alert
                showIcon
                text="Sample orders are limited at this time and should be available for this product soon!"
                type="warning"
              />
            )}
            {colorSwatchesSections
              && colorSwatchesSections.map((swatchesSection) => {
                const { optionGroups, sectionName } = swatchesSection;
                return (
                  <StyledOptionsSections key={sectionName}>
                    <dt>
                      <StyledOptionSectionTitle>{sectionName}</StyledOptionSectionTitle>
                    </dt>
                    <dd>
                      <StyledOptionsSectionsGroups>
                        {optionGroups.map((optionGroup) => {
                          const { Name, Swatches } = optionGroup;
                          const swatchesToRenderQuantity = 20;
                          const swatchesToRender = showAllChoices
                            ? Swatches
                            : Swatches.slice(0, swatchesToRenderQuantity);
                          // eslint-disable-next-line max-len
                          const shouldShowToggleMoreChoices = Swatches.length > swatchesToRenderQuantity && !showAllChoices;
                          let hasAvailableSwatches = false;
                          return (
                            <li key={Name}>
                              <StyledOptionSectionGroupTitle>{Name}</StyledOptionSectionGroupTitle>
                              <StyledOptionsSectionsChoices>
                                {swatchesToRender.map((swatch) => {
                                  const {
                                    ChoiceId: choiceId,
                                    ImageUrl,
                                    IsInCart,
                                    IsPrimary,
                                    IsSampleable,
                                    IsSampleAvailable,
                                    Name: choiceName,
                                    OptionId: optionId,
                                    Price: price,
                                    StrikeThroughPrice: strikethroughPrice,
                                    PercentOff: percentOff,
                                  } = swatch;
                                  const selectedSwatch = swatchSelections.find((ss) => ss.optionId === optionId);
                                  const selectedChoiceId = selectedSwatch ? selectedSwatch.choiceId : null;
                                  const isChoiceSelected = selectedChoiceId === choiceId;
                                  if (price) {
                                    hasAvailableSwatches = true;
                                  }
                                  return (
                                    <ProductSwatch
                                      key={choiceId}
                                      choiceId={choiceId}
                                      choiceName={choiceName}
                                      image={ImageUrl}
                                      isSampleable={IsSampleable}
                                      isSampleAvailable={IsSampleAvailable}
                                      isChoiceSelected={isChoiceSelected}
                                      isInCart={IsInCart}
                                      isPrimary={IsPrimary}
                                      optionId={optionId}
                                      price={price}
                                      strikethroughPrice={strikethroughPrice}
                                      percentOff={percentOff}
                                      rootVm={vm}
                                    />
                                  );
                                })}
                              </StyledOptionsSectionsChoices>
                              <StyledViewMoreColors>
                                {hasAvailableSwatches && shouldShowToggleMoreChoices && (
                                  <Button
                                    color="secondary"
                                    label="View more rows of colors"
                                    onClick={() => setShowAllChoices()}
                                  />
                                )}
                              </StyledViewMoreColors>
                            </li>
                          );
                        })}
                      </StyledOptionsSectionsGroups>
                    </dd>
                  </StyledOptionsSections>
                );
              })}
          </StyledContentBox>
        </StyledOptions>
      </StyledOptionsWrapper>
    </>
  );
});

export default ProductOptionsView;
