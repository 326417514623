const createAnalyticalEvent = (primaryCategory, eventName, subObj) => {
  let thdAnalyticsEvent = {
    category: {
      primaryCategory,
    },
    eventInfo: {
      eventName
    },
    ...subObj
  };
  return thdAnalyticsEvent;
};

export const sendGalleryThumbnailEvent = (position, { mediaType }) => {
  let pos = parseInt(position, 10) + 1;
  const subObject = {
    subContent: {
      section: 'media',
      component: 'thumbnails',
      target: mediaType?.toLowerCase() || '',
      position: pos || '',
    }
  };
  const thdAnalyticsEvent = createAnalyticalEvent('interaction', 'pip sub-content', subObject);
  if (window.digitalData.event) {
    window.digitalData.event.push(thdAnalyticsEvent);
  }
};

export const sendGalleryExpandEvent = () => {
  const thdAnalyticsEvent = createAnalyticalEvent('overlay', 'expanded-view', null);
  if (window.digitalData.event) {
    window.digitalData.event.push(thdAnalyticsEvent);
  }
};

export const sendInfoAndGuidesEvent = (target) => {
  const subObject = {
    subContent: {
      section: 'product-overview',
      component: 'info-and-guides',
      target: target || '',
    }
  };
  const thdAnalyticsEvent = createAnalyticalEvent('interaction', 'pip sub-content', subObject);
  if (window.digitalData.event) {
    window.digitalData.event.push(thdAnalyticsEvent);
  }
};