import React, { useEffect, useState } from 'react';
import { string, func } from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/grid';
import classNames from 'classnames';
import { Input } from '@thd-olt-component-react/input';
import { notEmptyString, validQA, validateQA } from '../helper/QAModalFormHelper';

export const QAField = ({
  formInputClasses,
  text,
  placeholder,
  value,
  validationError,
  setQAText
}) => {
  const inputClass = classNames(formInputClasses + '__textarea-input', {
    'form-input-field--error': validationError
  });
  const lowerText = text.toLowerCase();

  const [errorTextForQA, setErrorTextForQA] = useState(null);

  useEffect(() => {
    if (!notEmptyString(value)) {
      setErrorTextForQA(`Please provide a ${lowerText}.`);
    } else if (!validQA(value)) {
      setErrorTextForQA(`Please enter a valid ${lowerText}.`);
    } else {
      setErrorTextForQA(null);
    }
  }, [value]);

  return (
    <Row>
      <Col className={`${formInputClasses}__textarea`} nopadding>
        <span className={`${formInputClasses}__title`}>Your {text}</span>
        <Row className="flush" nopadding="true">
          <Col xs="12">
            <Input
              className={inputClass}
              placeholder={placeholder}
              scrollOnFocus
              type="textarea"
              value={value}
              errorText={errorTextForQA}
              validation={(fieldText) => validateQA(fieldText)}
              maxLength="255"
              onChange={(fieldText) => setQAText(fieldText)}
            />
          </Col>
        </Row>
        {validationError && (
          <span className="form-input-field--error-message">{validationError}</span>
        )}
      </Col>
    </Row>
  );
};

QAField.displayName = 'QAField';

QAField.propTypes = {
  formInputClasses: string,
  text: string,
  placeholder: string,
  value: string,
  validationError: string,
  setQAText: func
};

QAField.defaultProps = {
  formInputClasses: null,
  text: '',
  placeholder: '',
  validationError: '',
  value: '',
  setQAText: null
};
