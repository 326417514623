import React, { useContext } from 'react';
import { bool, number } from 'prop-types';
import { formatNumberWithCommas } from '../../helpers/QAHelper';
import QAContext from '../../context/QAContext';
import {
  QUESTION_DISPLAY_MOBILE,
  QUESTION_PER_PAGE_DESKTOP,
  DEFAULT_NUMBER_OF_PAGES,
  DESKTOP_PAGE_2_START_INDEX
} from '../../constants';
import './PagerDisplayText.scss';

export const PagerDisplayText = ({ topPager, loading, seoTotalResults }) => {
  const { channel, currentPage, TotalResults } = useContext(QAContext);
  const isDesktop = channel === 'desktop' || channel === 'tablet';

  const pageSizeDesktop = currentPage === DEFAULT_NUMBER_OF_PAGES ? 4 : QUESTION_PER_PAGE_DESKTOP;
  const questionsDisplayPerPage = isDesktop ? pageSizeDesktop : QUESTION_DISPLAY_MOBILE;

  const results = TotalResults || seoTotalResults;

  if (!results) {
    return topPager && !loading ? (
      <span className="pager-no-results">No results found</span>
    ) : (
      <div className="pager-loading-space" />
    );
  }

  const totalWithCommas = formatNumberWithCommas(results);

  let start = currentPage;
  let end = questionsDisplayPerPage > results
    ? results
    : questionsDisplayPerPage;

  if (isDesktop) {
    if (currentPage > DEFAULT_NUMBER_OF_PAGES) {
      start = DESKTOP_PAGE_2_START_INDEX + (currentPage - 2) * questionsDisplayPerPage;
      end = start + questionsDisplayPerPage - DEFAULT_NUMBER_OF_PAGES > results
        ? results
        : start + questionsDisplayPerPage - DEFAULT_NUMBER_OF_PAGES;
    }
  } else {
    start = questionsDisplayPerPage * (currentPage - DEFAULT_NUMBER_OF_PAGES) + DEFAULT_NUMBER_OF_PAGES;
    end = start + questionsDisplayPerPage - DEFAULT_NUMBER_OF_PAGES > results
      ? results
      : start + questionsDisplayPerPage - DEFAULT_NUMBER_OF_PAGES;
  }

  return (
    <div className="pager-summary">
      {'Showing '}
      <span className="pager-summary__bold">{start}</span>-
      <span className="pager-summary__bold">{end}</span>
      {' of '}
      <span className="pager-summary__bold">{totalWithCommas}</span>
    </div>
  );
};

PagerDisplayText.displayName = 'PagerDisplayText';

PagerDisplayText.propTypes = {
  topPager: bool,
  loading: bool,
  seoTotalResults: number
};

PagerDisplayText.defaultProps = {
  topPager: false,
  loading: false,
  seoTotalResults: null
};
