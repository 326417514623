import { useBreakpoint } from '@gcc/autobahn-components';
import { subscribe, unsubscribe } from '@gcc/autobahn-utilities';
import { observer } from 'mobx-react-lite';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { sendInfoAndGuidesEvent } from '../../../../../../utility/analytics';

import {
  StyledExtraSpecs,
  StyledExtraSpecsSection,
  StyledInfoAndGuides,
  StyledInfoAndGuidesAcrobat,
  StyledListContent,
  StyledSpecifications,
  StyledSpecsSubtitles,
} from './product-specifications.styles';

const ProductSpecificationsView = observer((props) => {
  const {
    vm: { keyValueSections = [], listSections = [], quickLinks, scrollToProductSpecifications },
  } = props;
  const { isNotSmall, isSmall } = useBreakpoint();
  const productSpecificationsRef = useRef(null);

  const handleInfoAndGuidesClick = (e, anchor) => {
    e.preventDefault();
    const target = anchor?.innerHTML;
    try {
      sendInfoAndGuidesEvent(target);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    window.open(anchor?.href, '_blank');
  };

  useEffect(() => {
    const InfoAndGuidesObserver = new MutationObserver(() => {
      const InfoAndGuidesContainer = document.getElementsByClassName('quickLinks-react');
      if (InfoAndGuidesContainer?.length) {
        const InfoAndGuidesLinks = InfoAndGuidesContainer[0].getElementsByTagName('a');
        if (InfoAndGuidesLinks?.length) {
          InfoAndGuidesObserver.disconnect();
          Array.from(InfoAndGuidesLinks).forEach((link) => {
            link.onclick = (e) => handleInfoAndGuidesClick(e, link); // eslint-disable-line no-param-reassign
          });
        }
      }
    });
    InfoAndGuidesObserver.observe(document, {
      childList: true,
      subtree: true,
    });
  }, []);

  useEffect(() => {
    // eslint-disable-next-line max-len
    const scrollTo = subscribe('product-specifications:scrollto', () => scrollToProductSpecifications({ element: productSpecificationsRef.current, isNotSmall })
    );
    return () => unsubscribe(scrollTo);
  });

  return (
    <div ref={productSpecificationsRef}>
      {keyValueSections.map((section) => {
        const { items, title } = section;
        return (
          items && (
            <StyledSpecifications key={section.id}>
              <StyledSpecsSubtitles>{title}</StyledSpecsSubtitles>
              <dl>
                {items.map((spec) => {
                  const { id, label, value } = spec;
                  const shouldHideItem = isSmall && label === '' && value === '';
                  if (shouldHideItem) {
                    return null;
                  }
                  return (
                    <Fragment key={id}>
                      <dt>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{label}</ReactMarkdown>
                      </dt>
                      <dd>
                        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{value}</ReactMarkdown>
                      </dd>
                    </Fragment>
                  );
                })}
              </dl>
            </StyledSpecifications>
          )
        );
      })}
      <StyledListContent>
        <StyledExtraSpecs>
          {listSections.map((section) => {
            const { items, title } = section;
            return (
              items && (
                <StyledExtraSpecsSection key={section.id}>
                  <StyledSpecsSubtitles>{title}</StyledSpecsSubtitles>
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{items}</ReactMarkdown>
                </StyledExtraSpecsSection>
              )
            );
          })}
        </StyledExtraSpecs>
        <div>
          <StyledInfoAndGuides>
            <StyledSpecsSubtitles>Info &amp; Guides</StyledSpecsSubtitles>
            <ReactMarkdown className="quickLinks-react" rehypePlugins={[rehypeRaw]}>
              {quickLinks}
            </ReactMarkdown>
            <StyledInfoAndGuidesAcrobat>
              {' You will need Adobe® Acrobat® Reader to view PDF documents. '}
              <a href="https://get.adobe.com/reader/" target="_blank" rel="noreferrer">
                Download
              </a>
              {' a free copy from the Adobe Web site.'}
            </StyledInfoAndGuidesAcrobat>
          </StyledInfoAndGuides>
        </div>
      </StyledListContent>
    </div>
  );
});
ProductSpecificationsView.defaultProps = {
  vm: {},
};

export default ProductSpecificationsView;
