/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
import { useContext, useEffect, useRef, useMemo } from 'react';
import { useStoreId } from '@thd-nucleus/experience-context';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useQueryAttributes } from './useQueryAttributes';
import { getQueryArgs, getQueryFromAttributes, getQueryType } from './getQueryFromAttributes';
import { useQuery } from '../../hooks/useQuery';
import QueryContext from '../QueryContext';
import { getQueryKey, setNewDataInStorage } from '../util';

const defaultOpts = {
  ssr: true,
  errorPolicy: 'all'
};

// these two function will move to experience as options on DataProvider
const useIsMounted = () => {
  const storeId = useStoreId();
  return storeId !== '8119';
};

// @todo consider using below
// const useIsMounted = () => {
//   const { isLocalized, storeId } = useStore();
//   if (typeof isLocalized === 'undefined') {
//     return storeId !== '8119';
//   }
//
//   return isLocalized;
// };

const shouldSkip = ({ options, mounted, persist }) => {

  if (persist?.hasPersist && !persist?.ready) return true;

  // skip renders until
  if (options.variables?.storeId && options.ssr === false && !mounted) {
    // eslint-disable-next-line no-param-reassign
    return true;
  }

  if (options.variables?.storeId === '8119' && mounted) {
    return true;
  }

  return false;
};

const defaultMountFn = ({ mounted }) => mounted;
const defaultSkipFn = ({ skip }) => skip;
const defaultOptionsFn = ({ options }) => options;

export const useDataModel = (queryName, opts = defaultOpts, lazy = false) => {
  const options = {
    ...defaultOpts,
    ...opts,
  };

  const {
    dataSource,
    defaultVariables,
    queryAttributes = {},
    mounted: mountedFn = defaultMountFn,
    skip: skipFn = defaultSkipFn,
    queryOptions: optionsFn = defaultOptionsFn,
    merge,
    cacheKey: queryProviderCacheKey,
    onResponse,
    persist: persistCtx,
    dataStore,
    forceClientQueriesOnServer: forceClientQueriesOnServerFromContext,
  } = useContext(QueryContext);

  const mutationVariablesRef = useRef();

  const forceClientQueriesOnServer = options.forceClientQueriesOnServer
    || (forceClientQueriesOnServerFromContext || []).includes(queryName);

  // method to wait for client render
  const mounted = forceClientQueriesOnServer || mountedFn({
    mounted: typeof options.mounted !== 'undefined'
      ? options.mounted
      : useIsMounted()
  });

  const variables = options.variables
    ? { ...options.variables }
    : {};
  const { persist } = options;

  const ssr = !persist && options.ssr && !mounted;

  if (merge) {
    // eslint-disable-next-line no-param-reassign
    const keys = Object.keys(merge);

    const allQueryKeys = !mounted
      ? Object.keys(queryAttributes.server || {})
      : Object.keys(queryAttributes.client || {});

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const ret = merge[key].fn({ query: queryName, ssr: options.ssr, mounted, allQueryKeys });
      if (ret !== queryName) {
        // eslint-disable-next-line no-param-reassign
        queryName = ret;
        break;
      }
    }
  }

  if (persist && !persistCtx?.hasPersist) {
    throw new Error('persist must be set on the query provider');
  }
  const { storage: storageLocation, expirationInSeconds } = persist || {};
  const queryKey = useMemo(() => getQueryKey(queryName, variables), [queryName, variables]);

  const { attributes, queryName: aliasedQueryName } = useQueryAttributes({ query: queryName, ssr, dataModel: opts.dataModel });
  options.skip = skipFn({ skip: options.skip || shouldSkip({ options, mounted, persist: { ...persistCtx, ...persist } }), queryName, attributes });
  const isomorphicQueryType = ssr && !forceClientQueriesOnServer
    ? 'server'
    : 'client';

  if (!queryAttributes[isomorphicQueryType]) {
    queryAttributes[isomorphicQueryType] = {};
  }

  if (!queryAttributes[isomorphicQueryType][queryName]) {
    queryAttributes[isomorphicQueryType][queryName] = {};
  }
  let query;
  if (queryAttributes[isomorphicQueryType][queryName]._cachedQuery && !opts.dataModel && queryProviderCacheKey) {
    query = queryAttributes[isomorphicQueryType][queryName]._cachedQuery;
  } else {
    query = getQueryFromAttributes({ name: queryName, attributes, ssr: options.ssr && !mounted });
    if (queryProviderCacheKey) {
      queryAttributes[isomorphicQueryType][queryName]._cachedQuery = query;
    }
  }

  let args;
  if (queryAttributes[isomorphicQueryType][queryName]._cachedArgs && !opts.dataModel && queryProviderCacheKey) {
    args = queryAttributes[isomorphicQueryType][queryName]._cachedArgs;
  } else {
    args = getQueryArgs({ attributes, ssr, asString: false });
    if (queryProviderCacheKey) {
      queryAttributes[isomorphicQueryType][queryName]._cachedArgs = args;
    }
  }

  const queryType = getQueryType({ attributes, name: queryName });

  // if args contain more than all variables
  const missingVariables = Object.keys(args || {}).filter((key) => {
    return typeof variables?.[key] === 'undefined';
  });
  if (missingVariables.length) {
    missingVariables.forEach((key) => {
      if (dataSource && key === 'dataSource') {
        variables.dataSource = dataSource;
      }
      if (typeof defaultVariables.current[key] !== 'undefined') {
        variables[key] = defaultVariables.current[key];
      } else if (args[key]._isRequired && queryType !== 'mutation' && !lazy && !options.skip) {
        throw new Error(`'${key}' is missing from options.variables in useDataModel hook.
This likely due to one of the following reasons:
  1. A parameter was included when building the component dataModel but not passed into options.variables.
  2. The component dataModel was merged with another component requesting the '${key}' using params(). Implement defaultVaribles.${key} attribute in the QueryProvider to avoid this error.`);
      }
    });
  }

  // sort variables alphabetically for matching queries
  const sortedKeys = Object.keys(variables).sort();

  options.variables = sortedKeys.reduce((acc, cur) => {
    return {
      ...acc,
      [cur]: variables[cur]
    };
  }, {});

  // @todo remove this business logic, should be controlled by queryprovider
  if (!options.fetchPolicy && dataSource && dataSource !== 'catalog') {
    options.fetchPolicy = 'cache-only';
  }

  const cachedVariables = {
    ...(options.variables || {})
  };

  const optionsForQuery = optionsFn({ options, queryName });

  if (lazy && queryType !== 'mutation') {
    const [exec, resp] = useLazyQuery(query, optionsForQuery);

    if (resp.data) {
      if (ssr) {
        queryAttributes.server[queryName]._isResolved = true;
      } else if (!resp.loading) {
        queryAttributes.client[queryName]._isResolved = true;
      }

      if (storageLocation) {
        setNewDataInStorage({
          attributes,
          expirationInSeconds,
          queryKey,
          queryName,
          data: resp.data,
          options: optionsForQuery,
          storage: storageLocation,
        });
      }
    }

    return [exec, resp, cachedVariables];
  }

  const response = queryType === 'mutation' ? useMutation(query, optionsForQuery) : useQuery(query, optionsForQuery);
  useEffect(() => {

    // Mutation returns a tuple while queries return object.
    const responseObject = queryType === 'mutation'
      ? response[1]
      : response;

    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS && (responseObject?.data && !responseObject?.loading)) {
      const { data, variables: responseVariables } = responseObject;

      const eventBusVariables = queryType === 'mutation'
        ? {
          ...optionsForQuery?.variables,
          ...(mutationVariablesRef.current?.variables || {})
        }
        : responseVariables;

      window.LIFE_CYCLE_EVENT_BUS.trigger('data-sources.data', {
        type: 'product',
        queryName,
        variables: eventBusVariables,
        data
      });
    }
    if (typeof window !== 'undefined' && window.LIFE_CYCLE_EVENT_BUS && (responseObject?.error && !responseObject?.loading)) {
      const { data, error, variables: responseVariables } = responseObject;
      const eventBusVariables = queryType === 'mutation'
        ? {
          ...optionsForQuery?.variables,
          ...(mutationVariablesRef.current?.variables || {})
        }
        : responseVariables;

      window.LIFE_CYCLE_EVENT_BUS.trigger('data-sources.error', {
        type: 'product',
        queryName,
        variables: eventBusVariables,
        data,
        message: `GQL Error: ${queryName} - ${error?.message}`
      });
    }
  }, [response?.loading, response?.[1]?.loading]);

  if (queryType === 'mutation') {
    const [exec, responseObj] = response;
    const customExec = (execOptions) => {
      mutationVariablesRef.current = execOptions;
      return exec(execOptions);
    };
    return [customExec, responseObj];
  }

  if (optionsForQuery.dataModel) {
    delete optionsForQuery.dataModel;
  }

  if (typeof optionsForQuery.mounted !== 'undefined') {
    delete optionsForQuery.mounted;
  }

  // apollo client is getting overwritten and not providing access to latest.
  if (typeof window !== 'undefined' && window.__APOLLO_CLIENT__) {
    window.__APOLLO_CLIENT__ = response.client;
  }
  if (response.data) {
    if (dataStore) {
      Object.keys(response.data).forEach((key) => {
        dataStore.current[key] = response.data[key];
      });

    }
    if (onResponse) {
      onResponse({ response, queryName });
    }
    if (storageLocation) {
      setNewDataInStorage({
        attributes,
        expirationInSeconds,
        queryKey,
        queryName,
        data: response.data,
        options: optionsForQuery,
        storage: storageLocation,
      });
    }
    if (ssr) {
      queryAttributes.server[queryName]._isResolved = true;
    } else if (!response.loading) {
      queryAttributes.client[queryName]._isResolved = true;
    }
  }
  return response;
};
