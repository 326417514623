/* eslint-disable @mizdra/layout-shift/require-size-attributes */
/* eslint-disable max-lines */
import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  memo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import find from 'lodash/find';
import isString from 'lodash/isString';
import every from 'lodash/every';
import isEqual from 'lodash/isEqual';
import checkValidTexture from '@gcc/pip-components/dist/utils/checkValidTexture';
import { useIsMobile } from '@gcc/pip-components/dist/hooks';
import Portal from '@gcc/pip-components/dist/Portal';
import { Root, MainImageWrapper } from '@gcc/pip-components/dist/Visualizer/styles';
import Thumbnails from '@gcc/pip-components/dist/Visualizer/_Thumbnails';
import MainImage from '@gcc/pip-components/dist/Visualizer/_MainImage';
import VisualizerLiquidPixel from '@gcc/pip-components/dist/VisualizerLiquidPixel';
import { mediaImageShape, productInfoShape } from '@gcc/pip-components/dist/Visualizer/_propTypes';
// import { ImageNotFoundWrapper } from '../VisualizerLiquidPixel/index.styles';
import { ImageNotFoundWrapper } from './ImageNotFoundWrapper';

import { Modal as VisualizerModal } from './Modal';
import MobileCarousel from './_MobileCarousel';
import VideoPlayer from './_VideoPlayer';

function mapImage(image) {
  if (isString(image)) {
    return {
      primaryLink: image,
      thumbnail: image,
      mediaType: 'IMAGE', // IDEA: infer by extension
    };
  }

  return image;
}

const Visualizer = ({
  videoPlayerComponent,
  mediaImages,
  maxThumbnails,
  productInfo,
  onCloseModal,
  onOpenModal,
  magnifyOnHover,
  enlargedImageSize,
  preloadedMainImage,
  responsiveImageHelper,
  useSkeletons,
  onNotifyVisualizerImagesLoaded,
  lpActive,
  onLpError,
  onLiquidPixelVisualizerLoadedSuccessfully,
  activeLogoGroup,
  activeTexture,
  activeColor,
  textureLoadedUpdatePath,
  onVisualizerAnalyticsTrack,
  dragMessage,
  backupLiquidPixelImage,
  ...rest
}) => {
  const isMobile = useIsMobile();

  const [showModal, setShowModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [loadedImages, setStatusOfLoadedImages] = useState([]);
  const [visualizerImagesLoaded, setVisualizerImagesLoaded] = useState(false);
  const [validTexture, setValidTexture] = useState(null);
  const validTextureRef = useRef(null);
  const [prevTexture, setPrevTexture] = useState(null);

  useEffect(() => {
    const getValidStatus = async () => {
      if (activeTexture && !isEqual(activeTexture, prevTexture)) {
        const { model } = activeTexture;
        const isActiveTexture = await checkValidTexture(model);
        setValidTexture(isActiveTexture);
        validTextureRef.current = isActiveTexture;
        if (onLpError && !isActiveTexture) {
          onLpError(
            'LP Surround Visualizer Loading',
            'Liquid Pixel Visualizer Failed',
            `not a valid texture ${activeTexture?.model}`,
          );
        }
      }
    };
    // check texture is valid
    getValidStatus();
    setPrevTexture(activeTexture);
  }, [activeTexture]);

  const images = useMemo(() => mediaImages.map(mapImage), [mediaImages]);

  const updateImageTracking = (imageName, loadStatus) => {
    const imageStatus = { imageName, loadStatus };
    const found = find(loadedImages, { imageName });
    if (found) {
      if (!found.loadStatus && loadStatus) {
        found.loadStatus = loadStatus;
      }
    } else {
      loadedImages.push(imageStatus);
    }
    setStatusOfLoadedImages(loadedImages);
    setVisualizerImagesLoaded(every(loadedImages, ['loadStatus', true]));
  };

  const onImageRequested = (imageName) => {
    updateImageTracking(imageName, false);
  };
  const onImageLoaded = (imageName) => () => {
    updateImageTracking(imageName, true);
  };

  const hideMainImage = useSkeletons && !visualizerImagesLoaded;
  const mainImage = images[activeIndex];
  let Image;
  if (mainImage) {
    const responsiveImageHelperResult = responsiveImageHelper[
      isMobile ? 'mobile' : 'desktop'
    ](mainImage.primaryLink);

    const { srcSet, sizes } = responsiveImageHelper
      ? responsiveImageHelperResult
      : {};

    Image = () => (
      <MainImage
        src={mainImage.primaryLink}
        srcSet={srcSet}
        sizes={sizes}
        magnifyOnHover={magnifyOnHover}
        isMobile={isMobile}
        enlargedImageSize={enlargedImageSize}
        largeSize={mainImage.largeSize}
        caption={mainImage.caption}
        onImageLoaded={onImageLoaded}
        onImageRequested={onImageRequested}
        isHidden={hideMainImage}
      />
    );
  }

  const closeModal = useCallback(() => {
    setShowModal(false);
    onCloseModal();
  }, []);

  const openModal = useCallback((index) => {
    setActiveIndex(index);
    setShowModal(true);
    onOpenModal();
  }, []);

  const handleMainImageClick = useCallback(
    (fromBackupImage) => {
      if (
        fromBackupImage
        || mainImage.mediaType === 'IMAGE'
        || (lpActive && mainImage.mediaType === 'LPSURROUND' && !validTexture)
      ) {
        openModal(activeIndex);
      }
    },
    [mainImage, activeIndex],
  );

  const notifyVisualizerImagesLoaded = useCallback(() => {
    onNotifyVisualizerImagesLoaded();
  });

  useEffect(() => {
    if (visualizerImagesLoaded) {
      notifyVisualizerImagesLoaded();
    }
  }, [visualizerImagesLoaded]);

  const handleLiquidPixelLoadedSuccessfully = () => {
    if (
      !!onLiquidPixelVisualizerLoadedSuccessfully
      && !!validTextureRef.current
    ) {
      onLiquidPixelVisualizerLoadedSuccessfully();
    }
  };

  return (
    <>
      {isMobile && (
        <MobileCarousel
          mediaImages={images}
          videoPlayerComponent={videoPlayerComponent}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          onChangeSlide={setActiveIndex}
          onImageClick={(fromBackupImage) => handleMainImageClick(fromBackupImage)}
          preloadedMainImage={preloadedMainImage}
          onImageLoaded={onImageLoaded}
          onImageRequested={onImageRequested}
          visualizerImagesLoaded={visualizerImagesLoaded}
          responsiveImageHelper={responsiveImageHelper}
          useSkeletons={useSkeletons}
          activeLogoGroup={activeLogoGroup}
          activeTexture={activeTexture}
          activeColor={activeColor}
          lpActive={lpActive}
          isMobile={isMobile}
          validTexture={validTexture}
          textureLoadedUpdatePath={textureLoadedUpdatePath}
          onLpError={() => !!onLpError && onLpError()}
          onLiquidPixelVisualizerLoadedSuccessfully={
            handleLiquidPixelLoadedSuccessfully
          }
          backupLiquidPixelImage={backupLiquidPixelImage}
        />
      )}
      {!isMobile && (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Root {...rest}>
          <MainImageWrapper onClick={() => handleMainImageClick(false)}>
            {preloadedMainImage && useSkeletons && (
              <MainImage
                src={preloadedMainImage}
                magnifyOnHover={false}
                isHidden={visualizerImagesLoaded}
              />
            )}
            {mainImage && mainImage.mediaType === 'IMAGE' && <Image />}
            {mainImage && mainImage.mediaType === 'VIDEO' && (
              <div style={{ width: '100%' }}>
                <VideoPlayer
                  src={mainImage.primaryLink}
                  brightcoveVideoId={mainImage.videoId}
                  videoPlayerComponent={videoPlayerComponent}
                />
              </div>
            )}
            {lpActive
              && mainImage?.mediaType === 'LPSURROUND'
              && validTexture && (
              <VisualizerLiquidPixel
                activeLogoGroup={activeLogoGroup}
                activeTexture={activeTexture}
                activeColor={activeColor}
                lpActive={lpActive}
                isMobile={isMobile}
                onError={(e) => !!onLpError && onLpError(e)}
                backupImage={backupLiquidPixelImage || mainImage.primaryLink}
                onBackupImgClick={() => handleMainImageClick(true)}
                textureLoadedUpdatePath={textureLoadedUpdatePath}
                dragMessage={dragMessage}
                onLiquidPixelVisualizerLoadedSuccessfully={
                  handleLiquidPixelLoadedSuccessfully
                }
              />
            )}
            {lpActive
              && mainImage?.mediaType === 'LPSURROUND'
              && !validTexture && (
              <ImageNotFoundWrapper
                isMobile={isMobile}
                onClick={() => handleMainImageClick(true)}
              >
                <span className={isMobile ? '' : 'center-backup-img'}>
                  Image Not Available
                </span>
                <img
                  className={isMobile ? '' : 'center-backup-img'}
                  src={backupLiquidPixelImage || mainImage.primaryLink}
                  alt="Line Item Thumbnail"
                />
              </ImageNotFoundWrapper>
            )}
          </MainImageWrapper>
          <Thumbnails
            mediaImages={images}
            activeIndex={activeIndex}
            onThumbnailHover={setActiveIndex}
            onThumbnailClick={openModal}
            max={isMobile ? -1 : maxThumbnails}
            onImageLoaded={onImageLoaded}
            onImageRequested={onImageRequested}
            visualizerImagesLoaded={visualizerImagesLoaded}
            responsiveImageHelper={responsiveImageHelper}
            useSkeletons={useSkeletons}
          />
        </Root>
      )}
      {showModal && (
        <Portal>
          <VisualizerModal
            mediaImages={images}
            productInfo={productInfo}
            activeIndex={activeIndex}
            onClose={closeModal}
            responsiveImageHelper={responsiveImageHelper}
            videoPlayerComponent={videoPlayerComponent}
            activeLogoGroup={activeLogoGroup}
            activeTexture={activeTexture}
            activeColor={activeColor}
            lpActive={lpActive}
            validTexture={validTexture}
            textureLoadedUpdatePath={textureLoadedUpdatePath}
            onVisualizerAnalyticsTrack={onVisualizerAnalyticsTrack}
            onError={onLpError}
            onLiquidPixelVisualizerLoadedSuccessfully={
              handleLiquidPixelLoadedSuccessfully
            }
            backupLiquidPixelImage={backupLiquidPixelImage}
          />
        </Portal>
      )}
    </>
  );
};

Visualizer.propTypes = {
  videoPlayerComponent: PropTypes.func.isRequired,
  mediaImages: PropTypes.arrayOf(mediaImageShape),
  maxThumbnails: PropTypes.number,
  productInfo: productInfoShape,
  onCloseModal: PropTypes.func,
  onOpenModal: PropTypes.func,
  magnifyOnHover: PropTypes.bool,
  enlargedImageSize: PropTypes.string,
  preloadedMainImage: PropTypes.string,
  responsiveImageHelper: PropTypes.shape({
    thumbnail: PropTypes.func,
    mobile: PropTypes.func,
    desktop: PropTypes.func,
  }),
  useSkeletons: PropTypes.bool,
  onNotifyVisualizerImagesLoaded: PropTypes.func,
  lpActive: PropTypes.bool,
  activeLogoGroup: PropTypes.shape({}),
  activeTexture: PropTypes.shape({
    model: PropTypes.shape({})
  }),
  activeColor: PropTypes.string,
  textureLoadedUpdatePath: PropTypes.func,
  onVisualizerAnalyticsTrack: PropTypes.func,
  onLpError: PropTypes.func,
  dragMessage: PropTypes.string,
  onLiquidPixelVisualizerLoadedSuccessfully: PropTypes.func,
  backupLiquidPixelImage: PropTypes.string,
};

Visualizer.defaultProps = {
  productInfo: {},
  mediaImages: [],
  maxThumbnails: 6,
  onCloseModal: noop,
  onOpenModal: noop,
  magnifyOnHover: false,
  enlargedImageSize: '125%',
  preloadedMainImage: '',
  responsiveImageHelper: undefined,
  useSkeletons: false,
  onNotifyVisualizerImagesLoaded: noop,
  activeLogoGroup: undefined,
  activeTexture: undefined,
  activeColor: undefined,
  lpActive: false,
  textureLoadedUpdatePath: noop,
  onVisualizerAnalyticsTrack: noop,
  onLpError: noop,
  dragMessage: 'Drag to Spin',
  onLiquidPixelVisualizerLoadedSuccessfully: noop,
  backupLiquidPixelImage: undefined,
};

export default memo(Visualizer);
