import { Shape } from './Shape';
import { PropTypes } from './PropTypes';

export const client = (arg) => {
  return {
    ...arg,
    _client: true
  };
};

export const query = (args, queryArg) => {
  return {
    ...queryArg,
    _query: args,
  };
};

export const arrayOf = (model) => {
  const children = model._children
    ? model._children
    : model;

  // const _query = model._query;
  if (model.query) {
    children._query = model.query;
  } else if (model._query) {
    children._query = model._query;
  }
  if (model._customType) {
    children._value = model._subType;
    if (model._isRequired && children._value?.charAt(children._value.length - 1) !== '!') {
      children._value += '!';
    }
  }
  return new PropTypes('Object', { isArray: true, children });
};

export const string = () => {
  return new PropTypes('String');
};

export const id = () => {
  return new PropTypes('ID');
};

export const json = () => {
  return new PropTypes('JSON');
};

export const number = ({ float = false } = {}) => {
  const type = float ? 'Float' : 'Int';
  return new PropTypes(type);
};

export const bool = () => {
  return new PropTypes('Boolean');
};

export const params = (args) => {
  let addClient = false;
  let mutation = false;
  return {
    // model(model) {
    //   const  model;
    // },
    shape(model) {

      if (model.type === 'Object' && model._children) {
        // eslint-disable-next-line no-param-reassign
        model = model._children;
      }
      const ret = new Shape({
        ...model,
        _mutation: mutation,
        _client: false
      });
      ret._query = args;
      if (addClient) {
        return this._addClient(ret);
      }
      return ret;
    },
    arrayOf(model) {

      if (model instanceof PropTypes) {

        // String or primiative types as array children Will
        // not have model.type === 'Object'
        // you dont want to assign query to them. assign to the ret.
        if (model.type === 'Object') {
          // eslint-disable-next-line no-param-reassign
          model._query = args;
        }
        const ret = arrayOf(model);
        if (model.type !== 'Object') {
          ret._query = args;
        }

        ret._mutation = mutation;
        return ret;
      }
      return arrayOf(this.shape(model));
    },
    client() {
      addClient = true;
      return this;
    },
    mutation() {
      mutation = true;
      return this;
    },
    string() {
      return this.shape(string());
    },
    _addClient(model) {
      return Object.keys(model).map((key) => {
        if (/^_/.test(key)) {
          return { [key]: model[key] };
        }
        return {
          [key]: {
            ...model[key],
            _client: true
          }
        };
      }).reduce((acc, cur) => {
        return {
          ...acc,
          ...cur
        };
      }, {});
    }
  };
};

export const alias = (aliasName) => {
  return {
    params(...paramArgs) {
      const args = params(...paramArgs);
      return {
        shape(model) {
          const ret = args.shape(model);
          return {
            ...ret,
            _alias: aliasName
          };
        },
        arrayOf(model) {
          const ret = arrayOf(this.shape(model));
          ret._alias = aliasName;
          return ret;
        },
      };
    }
  };
};

export const shape = (model, subType = 'Object') => {

  Object.keys(model).forEach((key) => {
    if (typeof model[key] === 'function') {
      // eslint-disable-next-line max-len
      throw new Error(`The property type for '${key}' must be invoked! You may also be trying to use a react 'prop-type' when you should be using a data-source prop-type.`);
    }
  });

  // remove any keys with _
  // dont want to apply params when directly invoking
  // shape(). for params use params().shape()
  const children = Object.keys(model).map((key) => {
    if (/^_/.test(key) && key !== '__typename') return null;
    return { [key]: model[key] };
  }).filter((val) => val)
    .reduce((acc, cur) => {
      return {
        ...acc,
        ...cur,
      };
    }, {});

  return new PropTypes('Object', { isArray: false, children, subType });
};

export const fragment = () => {
  return {
    shape(model) {
      const ret = shape(model);
      return {
        ...ret,
        _fragment: true
      };
    }
  };
};

export const typename = (defaultName) => {
  const ret = shape({ type: 'typename' }, '__typename');
  ret._typename = {
    defaultName
  };
  return ret;
};

export const customType = (typeName) => {
  return {
    shape(model) {
      return shape(model, typeName);
    },
    enum(values, defaultValue) {
      const ret = shape({}, typeName);
      ret._enum = values.reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: true
        };
      }, {});
      if (defaultValue) {
        ret._defaultValue = defaultValue;
      }
      return ret;
    }
  };
};
