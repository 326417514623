import View from './plugin.view';

/**
 * @class PluginModel
 * @description Base plugin data model for all micro apps.
 */
class PluginModel {
  constructor(micro) {
    if (micro) {
      if (micro.model) {
        const { model } = micro;

        this.Id = model.Id;
        this.Container = model.Container;
        this.Attributes = model.Attributes;
        this.View = new View(model.View);
      }

      if (micro.rootStore) {
        this.DataStore.rootStore = micro.rootStore;
      }

      if (micro.localStore) {
        this.DataStore.localStore = micro.localStore;
      }
    }

    this.micro = micro;
  }

  micro;

  /**
   * @configurable true
   * @category Common
   * @description The unique id or name of the micro app.
   * @type {string}
   * @member
   */
  Id;

  /**
   * @configurable true
   * @category Appearance
   * @description Contains style information for the micro apps container.
   * @type {JsonObject}
   * @member
   */
  Container;

  /**
   * @configurable true
   * @category Appearance
   * @description Contains style information for the micro app.
   * @type {JsonObject}
   * @member
   */
  Attributes;

  /**
   * @configurable true
   * @category View
   * @display Inline
   * @description Contains information about the micro app used for the plugins view.
   * @type {View}
   * @member
   */
  View;

  DataStore = [];
}

export default PluginModel;
