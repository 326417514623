import React from 'react';
import { ThemeProvider } from '@gcc/autobahn-components';
import useFixedHarmonyTheme from '../../hooks/_useFixedHarmonyTheme';
import { App } from '../../components/pip/index';

const micro = { View: { Name: 'pcp', Version: 'latest' } };

const {
  pageModel: {
    bazaarVoiceApiUrl,
    bazaarVoiceConversationsApiUrl,
    bazaarVoiceThdPasskey: bazaarVoicePasskey,
    configuratorUrl,
    isProfessionallyInstallable,
    isSamplingEnabled,
    isSamplingEnabledCecOnly,
    mandIPhoneNumber,
    pageMode,
    partnerProductId,
    productId,
    enhancedVisualization,
  },
} = window.pageContextJavascript;

const globalStyle = `{
  a {
    color: inherit;
  }
}`;

export const PipApp = () => {
  const themeHarmony = useFixedHarmonyTheme();
  const liveClickerSettings = window?.globalConfig?.liveClicker;

  return (
    <div>
      <ThemeProvider theme={themeHarmony} globalStyles={globalStyle} liveClickerSettings={liveClickerSettings}>
        <App
          bazaarVoiceApiUrl={bazaarVoiceApiUrl}
          bazaarVoiceConversationsApiUrl={bazaarVoiceConversationsApiUrl}
          bazaarVoicePasskey={bazaarVoicePasskey}
          configuratorUrl={configuratorUrl}
          isProfessionallyInstallable={isProfessionallyInstallable}
          isSamplingEnabled={isSamplingEnabled}
          isSamplingEnabledCecOnly={isSamplingEnabledCecOnly}
          mandIPhoneNumber={mandIPhoneNumber}
          pageMode={pageMode}
          partnerProductId={partnerProductId}
          productId={productId}
          micro={micro}
          enhancedVisualization={enhancedVisualization}
        />
      </ThemeProvider>
    </div>
  );
};

PipApp.propTypes = {
};

PipApp.displayName = 'PipApp';
