import { breakpoints, styled, ty } from '@gcc/autobahn-components';

export const StyledContentBox = styled.div`
  ${ty`
    w-100 relative overflow-hidden flex-auto flex-column flex
  `}
  ${breakpoints.s} {
    ${({ isCollapsed }) => !isCollapsed && ty`fixed left-0 right-0 top-0 bottom-0`}
    ${({ isCollapsed }) => !isCollapsed && 'z-index: 102;'}
  }
`;

export const StyledContentBoxBack = styled.div`
  ${ty`
    w-100 ph2 pv2 top-0 fixed z-2 f6
    ${({ theme: { contentBox } }) => contentBox?.appearance}
  `}
  ${({ isContentAtTop }) => !isContentAtTop && 'box-shadow: 0 2px 10px rgb(0 0 0 / 40%);'}
  & > div {
    ${ty`
      pointer inline-flex items-center
      ${({ theme: { link } }) => link?.default}
    `}
  }
  ${breakpoints.ns} {
    ${ty`dn`}
  }
`;

export const StyledContentBoxContent = styled.div`
  ${ty`
    lh-copy w-100 pl3 bg-white
    ${({ theme: { contentBox } }) => contentBox?.content?.appearance}
    ${({ isCollapsed }) => isCollapsed && 'dn'}
  `}
`;

export const StyledContentBoxTitle = styled.span`
  ${ty`w-100 relative pv2 f6`}
  & > div {
    ${ty`pointer ${({ theme: { link } }) => link?.types?.standard?.default}`}
    &:hover {
      ${ty`${({ theme: { link } }) => link?.types?.standard?.hover}`}
    }
  }
`;

export const StyledContentBoxWrapper = styled.div`
  ${ty`
    w-100 flex-auto flex flex-wrap
    ${({ isCollapsed }) => !isCollapsed && 'pt4 pt0-ns overflow-y-auto overflow-y-hidden-ns overflow-x-hidden'}
  `}
`;
