import { backslashToForwardslash } from '@gcc/autobahn-utilities';
import {
  action, computed, makeAutoObservable, observable, runInAction
} from 'mobx';

import SampleCartService from '../../api/sample-cart.service';

class ProductSwatchViewModel {
  constructor({
    choiceId,
    choiceName,
    image,
    isSampleable,
    isSampleAvailable,
    isChoiceSelected,
    isInCart,
    isPrimary,
    optionId,
    price,
    rootVm,
  }) {
    makeAutoObservable(this, {
      addSampleToCart: action,
      choiceId: observable,
      choiceName: observable,
      getZoomPreviewPosition: action,
      image: observable,
      isChoiceSelected: observable,
      isInCart: observable,
      isPrimary: observable,
      isSampleEnabled: computed,
      optionId: observable,
      price: observable,
      removeSampleFromCart: action,
      rootVm: false,
      sampleButtonAction: action.bound,
      sampleButtonType: computed,
      sampleLabel: computed,
      shouldShowZoom: observable,
      swatchSelections: observable,
      updateSwatchSelections: action,
      zoomPreviewPosition: observable,
    });

    this.choiceId = choiceId;
    this.choiceName = choiceName;
    this.image = image;
    this.isSampleable = isSampleable;
    this.isSampleAvailable = isSampleAvailable;
    this.isChoiceSelected = isChoiceSelected;
    this.isInCart = isInCart;
    this.isPrimary = isPrimary;
    this.optionId = optionId;
    this.price = price;
    this.rootVm = rootVm;
  }

  choiceId = undefined;

  choiceName = undefined;

  image = undefined;

  isSampleable = undefined;

  isSampleAvailable = undefined;

  isChoiceSelected = undefined;

  isInCart = undefined;

  isPrimary = undefined;

  optionId = undefined;

  price = undefined;

  get isSampleEnabled() {
    return this.isSampleable && this.isSampleAvailable;
  }

  get sampleLabel() {
    if (this.isInCart) {
      return 'X Remove';
    }

    return this.isSampleEnabled ? 'Order Sample' : 'No Sample';
  }

  get sampleButtonType() {
    return this.isInCart ? 'primary' : 'secondary';
  }

  sampleButtonAction = () => (this.isInCart ? this.removeSampleFromCart() : this.addSampleToCart());

  colorSwatchesSections = [];

  addSampleToCart = async () => {
    const sampleProductId = await SampleCartService.addSampleToCart({
      choiceId: this.choiceId,
      optionId: this.optionId,
    });
    this.rootVm.getSampleCart({ showSamplesModal: true });
    SampleCartService.submitAnalytics({
      eventName: 'list add',
      sampleProductId,
    });
  };

  removeSampleFromCart = async () => {
    const sampleProductId = await SampleCartService.removeSampleFromCart({
      choiceId: this.choiceId,
      optionId: this.optionId,
    });
    this.rootVm.getSampleCart();
    SampleCartService.submitAnalytics({
      eventName: 'list remove',
      sampleProductId,
    });
  };

  imageUrl = (url) => `${this.rootVm.baseUrl}${backslashToForwardslash(url)}`;

  swatchSelections = [];

  getUpdateSwatchSelectionsAction = (newSelection, swatchSelections) => {
    const { choiceId, optionId } = newSelection;

    const currentSwatchSelectionForOption = swatchSelections.find((swatch) => swatch.optionId === optionId);

    if (!currentSwatchSelectionForOption) return 'add';

    const newSelectionIsSameAsCurrentSwatch = currentSwatchSelectionForOption.choiceId === choiceId;

    if (newSelectionIsSameAsCurrentSwatch) return 'delete';

    return 'replace';
  };

  removeKeysFromObject = (obj) => obj.map((option) => ({
    id: option.choiceId,
    optionId: option.optionId,
  }));

  setLocalStorageSwatchSelections(selection) {
    const { ProductId } = this.rootVm.product;
    if (!selection || selection.length === 0) {
      localStorage.removeItem(`option-state-${ProductId}`);
      localStorage.removeItem(`configurator-${ProductId}`);
      return;
    }
    const { choiceId, choiceName, optionId } = selection[0];
    const selectedColor = {
      choiceId,
      choiceValue: choiceName,
    };
    const persistColorToConfigurator = {};
    persistColorToConfigurator[optionId] = selectedColor;
    const OptionString = JSON.stringify(this.removeKeysFromObject(selection));
    localStorage.setItem(`option-state-${ProductId}`, OptionString);
    localStorage.setItem(`configurator-${ProductId}`, JSON.stringify(persistColorToConfigurator));
  }

  previewSize = 300;

  zoomPreviewPosition = 0;

  getZoomPreviewPosition = (el) => {
    const $el = el.target;
    const swatchWidth = $el.parentElement.offsetWidth;
    const itemOffset = $el.parentElement.getBoundingClientRect().left;

    const shouldShowPreviewOnLeft = itemOffset + swatchWidth + this.previewSize > window.innerWidth;
    const previewPosition = shouldShowPreviewOnLeft ? 'left' : 'right';
    return previewPosition;
  };

  shouldShowZoom = false;

  updateSwatchSelections = () => {
    const { swatchSelections } = this.rootVm.productOptionsViewModel;
    const newSelection = {
      choiceId: this.choiceId,
      choiceName: this.choiceName,
      imageUrl: this.image,
      optionId: this.optionId,
    };

    const updateAction = this.getUpdateSwatchSelectionsAction(newSelection, swatchSelections);

    let updatedSwatchSelections = [];

    switch (updateAction) {
    case 'delete':
      updatedSwatchSelections = swatchSelections.filter((swatch) => swatch.choiceId !== this.choiceId);
      break;

    case 'replace':
      updatedSwatchSelections = swatchSelections.map((swatch) => {
        if (swatch.optionId === this.optionId) return newSelection;

        return swatch;
      });
      break;

    case 'add':
    default:
      updatedSwatchSelections = swatchSelections.concat(newSelection);
      break;
    }
    runInAction(() => {
      this.rootVm.productOptionsViewModel.setSwatchSelections(updatedSwatchSelections);
      this.setLocalStorageSwatchSelections(updatedSwatchSelections);
      this.rootVm.productPriceViewModel.getProductPrice();
    });
  };
}

export default ProductSwatchViewModel;
