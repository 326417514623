import { styled, ty } from '@gcc/autobahn-components';

const defaulter = {};
export default defaulter;

export const StyledSpecifications = styled.section`
  ${ty`mb3 ph1`}
  & dl {
    ${ty`flex flex-wrap bt b--gray mt0 mb4`}
  }
  & dt,
  & dd {
    ${ty`w-50 w-25-l pa2 bb b--gray ma0`}
  }
  & dt {
    ${ty`bg-mid-gray`}
  }
`;

export const StyledListContent = styled.section`
  ${ty`mt4 flex ph1 flex-wrap`}
  & > div {
    ${ty`w-100 w-50-l`}
  }
`;

export const StyledSpecsSubtitles = styled.h4`
  ${ty`fw7 pb2 mb1 f5`}
`;

export const StyledExtraSpecs = styled.div`
  ${ty`pr4-l`}
`;

export const StyledInfoAndGuides = styled.div`
  ${ty`ba b--mid-gray pa3`}
  & a {
    ${ty`blue`}
  }
  & ul {
    ${ty`pl3 mb3`}
    & li {
      ${ty`mb2`}
    }
  }
`;

export const StyledExtraSpecsSection = styled.div`
  ${ty`mb3 w-100 w-50-l fl-ns`}
  & ul {
    ${ty`pl3 mb3`}
    & li {
      ${ty`mb2`}
    }
  }
`;

export const StyledInfoAndGuidesAcrobat = styled.p`
  font-size: var(--font-size-7);
`;
