import React, { useContext } from 'react';
import {
  Col, Row
} from '@thd-olt-component-react/core-ui';
import { QAResultContent } from '../QAResultContent/QAResultContent';
import { Pager } from '../Pager/Pager.component';
import { useSEO } from './useSEO';
import { NoContent } from '../NoContent/NoContent.component';
import { PagerDisplayText } from '../PagerDisplayText/PagerDisplayText.component';
import QAContext from '../../context/QAContext';
import { useQAResult } from './useQAResult';
import { dataModel } from '../dataModel';

const QAResultDesktop = () => {

  let {
    qaLoading, searchText, Results, TotalResults
  } = useContext(QAContext);

  const qaResult = useQAResult();
  const {
    seoResults, seoAnswers, seoTotalResults, seoPrevPage, seoNextPage
  } = useSEO(qaResult);

  const showResults = !!(Results?.length || searchText || seoResults?.length);

  return (
    <>
      {!showResults && <NoContent />}

      {showResults && (
        <div className="qa-result">
          <QAResultContent
            seoResults={seoResults}
            seoAnswers={seoAnswers}
            seoTotalResults={seoTotalResults}
          />
          {Results?.length && !qaResult ? (
            <Row>
              <Col fallback="12" className="pager-section">
                {TotalResults > 4 && (
                  <div className="q-a__review-pager">
                    <Pager />
                  </div>
                )}
                <Row className="q-a__review-pager__summary">
                  <PagerDisplayText topPager={false} loading={qaLoading} />
                </Row>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {qaResult && (
            <Row>
              <Col>
                {seoPrevPage > -1 && <a href={`${seoPrevPage}`}>{`page ${seoPrevPage}`}</a>}{' '}
                {seoNextPage > -1 && <a href={`${seoNextPage}`}>{`page ${seoNextPage}`}</a>}
              </Col>
            </Row>
          )}
        </div>
      )}
    </>
  );
};

QAResultDesktop.displayName = 'QAResultDesktop';

QAResultDesktop.dataModel = dataModel;

export { QAResultDesktop };